.basket__fulfillment-offer-dropdown__item
  body-2()
  padding 6px 0

.basket__fulfillment-offer-dropdown__item-label
  &.is-unavailable
    color $oyster
    text-decoration line-through

.basket__fulfillment-offer-dropdown__item-status
  caption-1()
  color $oyster
