@import '../../components/social_sign_in/index.styl'

//set width
.sign-up-page
  padding 0 15px
  &.narrow-page
    margin auto
    padding 0 40px
    .sign-up-form
      max-width 300px
      margin auto

//page text css
.sign-up-page
  h1
    font-size 32px
    line-height 38px
    font-weight 800
    display flex
    justify-content center
    font-family "Editor", Helvetica, Arial, sans-serif
    margin 20px auto 34px auto
    +below(mobile-cutoff)
      font-size 24px
      line-height 30px

.sign-up-page__button-area
  text-align center
  margin $gutter*2 0

  .btn
    width calc(50% - 5px)
    box-sizing border-box

    &.active
      background $laurel
      color $eggshell

    i
      font-size 15px
      line-height 15px
      margin-right 7px

    &.sign-up
      margin-right 10px

.sign-up-page__intro
  body-1()
  margin 20px 0

.sign-up-page__greeting
  body-1()
  margin $gutter 0

.sign-up-page
  .ge-separator
    text-align center
    width 20%
    margin auto
    margin-bottom 22px

  .ge-separator__content
    flex 0.6

    +below(tablet-cutoff)
      flex 1
