.saving-banner-container
    width 100%
    display flex
    justify-content flex-end
    margin-top 1em
.saving-banner 
    padding 8px 16px
    background rgba(209, 243, 206, 0.62)
    width 100%
    +above(mobile-cutoff)
        max-width 300px
    p 
        margin 0
        text-align center
        font-family $bodyFontFamily
        font-size 16px
