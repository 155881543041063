@import './components/layout/index.styl'
@import './components/nav_list/index.styl'
@import './components/password_form/index.styl'
@import './components/toggle_input/index.styl'
@import './components/subscription_schedule/index.styl'

@import './account_page/index.styl'
@import './credit_card_page/index.styl'
@import './details_page/index.styl'
@import './membership_page/index.styl'
@import './orders/index.styl'
@import './password_page/index.styl'
@import './reset_password_page/index.styl'
@import './set_password_page/index.styl'
@import './schedule_page/index.styl'
@import './sign_in_page/index.styl'
@import './sign_in_page/sign_in/index.styl'
@import './sign_up_page/index.styl'
@import './sign_up_page/sign_up/index.styl'

a.change
  &:link, &:visited
    color $laurel

  &:hover
    color $sage

a.disabled
  pointer-events none
  opacity 0.5
  color rgba(255, 255, 255, 0.3)

.line-break:after {
  content "\000A"
  white-space pre
}
