.product-listings-view .grid-items
  +above(listing-one-column-cutoff)
    margin-left 8px
    margin-right 8px

  +above(listing-two-column-cutoff)
    margin-left 0
    margin-right 0

.product-listings-view .product-tile--compact
  +below(mobile-cutoff)
    compact-tile-large()

.product-listings-view
  width 100%
  overflow hidden

  h2.listings-section-header
    display-2()

    &.hidden // TODO: SWITCH TO USING GLOBAL STATES
      visibility hidden

    +below(tablet-cutoff)
      padding-left 8px
      padding-right 8px

  h3.listings-section-header
    title-5()
    margin 19px 0
    +below(tablet-cutoff)
      margin 19px 8px

  .section-page
    margin-bottom 40px
    margin-top 20px

  &.with-sidebar
    padding-top 24px
    +above(tablet-cutoff)
      .section-page
        margin-right 20px

    +above(tablet-cutoff)
      width 80%



.search-no-results, .no-products
  font-family $bodyFontFamily
  padding-top 100px
  line-height normal
  min-height 400px
  text-align center
  +below(tablet-cutoff)
    font-family $smallHeadingsFontFamily

  header
    margin-bottom 0.5em
    +below(tablet-cutoff)
      margin-left auto
      margin-right auto
      max-width 275px

.product-listings-view__back-to-top
  label-2()
  display block
  text-align center
  padding 20px 40px 20px 20px
  margin-top -10px

.product-listings-view__subcategory-anchor
  position relative
  top -77px // Offset to leave room for header, and the shadow of listings above

  +below(tablet-cutoff)
    top -110px

.product-listings-view__section-anchor
  position relative
  top -77px

.products-page__bottom-navigation-links
  text-align center

.products-page__bottom-navigation-links-container
  margin 0 auto
  margin-bottom 40px
  padding 0 10px

.products-page__bottom-navigation-links-link
  display inline-block
  form-1()
  color $black

.products-page__bottom-navigation-links-link:nth-child(1)
  text-align left
  width 50%

  .icon-chevron
    font-size 11px
    margin-right 1px
    margin-top -3px
    transform rotate(-90deg)

.products-page__bottom-navigation-links-anchor
  width calc(100% - 25px)
  display inline-block

.products-page__bottom-navigation-links-link:nth-child(2)
  text-align right
  width 50%
  .icon-chevron
    font-size 11px
    margin-left 2px
    margin-top -3px
    transform rotate(90deg)
