.tag-container
  background-color $tagColor
  padding 4px
  position absolute
  top 16px
  left 16px
  z-index 1
  +below(mobile-cutoff)
    pointer-events none
    top 12px
    left 12px

.tag-container__discount-amount
  font-weight 500

.tag-container__discount-amount--md
  font-size 14px
  +below(mobile-cutoff)
    font-size 12px

.tag-container__discount-amount--lg
  font-size 20px
  +below(mobile-cutoff)
    font-size 14px
