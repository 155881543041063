.product-detail__favorite-control
  text-align center
  margin 10px auto
  padding 10px 0
  max-width 125px
  cursor pointer

.product-detail__favorite-control.not-favorited
  color $sage
  .product-detail__favorite-control-heart
    fill none
    stroke $sage
    stroke-width 1

.product-detail__favorite-control.favorited
  color $tomato
  .product-detail__favorite-control-heart
    fill $tomato
    stroke none

.product-detail__favorite-control-heart
  display inline-block
  vertical-align middle
  width 16px
  height 16px
  &:hover
    cursor pointer

.product-detail__favorite-control-description
  display inline-block
  vertical-align middle
  margin-left 5px
  // Using legacy font styles here to match rest of pdp
  font-family $bodyFontFamily
  font-size 14px
  &:hover
    cursor pointer

.product-detail-page__preorder-header
  background-color $chestnut
  color $ricotta
  text-align center
  padding 25px
  margin-bottom 15px

  +below(mobile-cutoff)
    padding 15px
    margin-top 10px

.product-detail-page__preorder-header__text
  title-4()
  font-size 1.3rem

.product-detail-page__promotional-product
  font-size 32px
  padding-top 24px
  padding-bottom 32px

.price-column
   display column

.price-row 
  display flex
  justify-content space-between
  align-items normal
  gap 0.5em
 

.price-row-item
  width 50%
  
.price-with-unit-container
  display flex
  align-items flex-end
  .unit-detail.unit-price 
    margin-bottom 10px
    color $oyster

.promo-container 
  margin-top 15px
  display flex
  justify-content flex-start

.promo-container
  .high-price
    text-decoration line-through
    font-weight normal
    margin 0
    font-size 18px
.jc-space-between
  justify-content space-between
.jc-flex-end
  justify-content flex-end
  

