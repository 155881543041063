.single-order-page__cancel
  order 1
  min-width 115px
  background-color $eggshell
  padding 0 30px 24px
  +below(mobile-cutoff)
    padding 0 15px 24px

.single-order-page__cancel__controls
  padding 40px 40px 35px
  text-align center
  max-width 670px // arbitrary - to constrain the 100% width errors alert
  +below(500px)
    padding 40px 10px 35px

.single-order-page__cancel__confirm-button
  button()
  margin 7px 10px
  +below(mobile-cutoff)
    margin 7px 0

.single-order-page__cancel__cancel-button
  secondary-button()
  margin 7px 10px
  +below(mobile-cutoff)
    margin 7px 0

.single-order-page__cancel__cancel-link
  cursor pointer
  color $tomato
  display inline-block
  form-1()

  &:hover
    color $tomato
