
.how-it-works-page__delivery_info
  // reset
  p
    margin 0
  ul
    padding-left 0

.how-it-works-page__delivery_info__title
  display-2()
  text-align center
  margin-bottom 15 px

.how-it-works-page__delivery_info__caption
  body-3()
  text-align center
  margin-top 20px
  a
    link()

.how-it-works-page__delivery_info__explanations
  display flex
  align-content center
  box-sizing border-box
  margin 0 auto
  padding-top 50px
  h3
    default-title()
  +strictly-below(ipad-width)
    display block
    width 100%

.how-it-works-page__delivery_info__explanation
  box-sizing border-box
  max-width 200px
  margin 0 auto
  text-align center

  h1
    title-3()
    margin-bottom 0
    margin-top 18px
    line-height 20px
    font-size 18px

  p
    form-1()
    margin-top 15px
    margin-bottom 15px
    line-height 20px
    font-size 15px

.how-it-works-page__delivery_info__icon
  display block
  width 215px
  line-height 50px
  text-align center
  margin-left auto
  margin-right auto

.mask 
  width 180px