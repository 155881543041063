.tip-option
    padding 4px
    min-width 30px
    color black
    display flex
    flex-direction column
    flex 1
    align-items center
    justify-content center
    cursor pointer
    font-weight 500
    &:not(:last-child)
        border-right 1px solid #9C9795
    &:last-child
        font-weight 400
        border-top-right-radius 3px
        border-bottom-right-radius 3px
    &:first-child
        border-top-left-radius 3px
        border-bottom-left-radius 3px
    span
        color $oyster
        font-weight 400
    
.tip-option-selected
    color white
    background-color black
    font-weight 400
    span
        color white
.tip-option-disabled
    cursor default
    color $tippingDisabled
    span
        color $tippingDisabled
    &:not(:last-child)
        border-right 1px solid $tippingDisabled
