.credit-card-page__global-error
  margin-bottom 25px

.credit-card-page__section
  padding 30px 125px
  border-bottom 1px solid $silt
  +below(mobile-cutoff)
    padding 20px
  .credit-card-form
    padding-top 1em

.credit-card-page__section:last-child
  border-bottom none

.credit-card-page__section-title
  margin-top 0
  form-2()

.credit-card-page__section-body
  form-1()

.credit-card-page__card-details
  color $black

.credit-card-page__button
  skinny-secondary-button()
  padding-left 15px
  padding-right 15px
  float right

.credit-card-page__active
  padding-bottom 1em
