.product-detail-view__unavailable-apology
  background $silt
  clear both
  font-family $smallHeadingsFontFamily
  text-align center
  border-radius(5px)
  padding: 16px 0
  border-bottom 2px solid rgba(0,0,0,0.1)
  margin 25px 0

.btn.save
  cursor pointer
  box-shadow(none)
  font-family $semiboldFontFamily
  background-color $sage

.btn.save.in-basket, .btn.save.saving
  background-color $hoverButtonColor
  box-shadow(none)
  
.quantity
  padding-top 4px

.product-detail-view__quantity-selector
  width 100%
  display inline-block
  box-sizing border-box
  vertical-align top


  .filled-select
    box-sizing border-box
    width 100%

    .select-text
      body-3()
      padding-top 9px
      padding-left 10px

    .icon-chevron-thin
      margin-top: -2px
      font-size 20px
      color $oyster
