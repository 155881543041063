.sign-in-flow__zip-code-collection
  text-align center

  .split-input-button
    margin 0 auto

    >.split-input-button__input
      &:focus
        border-color $oyster

    >.split-input-button__button
      background-color $chestnut
      text-transform none
      border 0 none
      &:hover
        background-color lighten($chestnut, 20%)

.sign-in-flow__zip-code-collection-headline
  display-2()

.sign-in-flow__zip-code-collection-prompt
  title-2()
  margin-bottom 30px
  margin-top 10px

.sign-in-flow__links
  padding 40px $desktopMargin
  list-style-type none
  margin 0 auto
  text-align center
  background-color $eggshell
  width 100%
  box-sizing border-box
  +strictly-below(mobile-cutoff)
    padding-top 0

.sign-in-flow__link
  form-1()
  color $oyster
  +strictly-above(mobile-cutoff)
    display inline-block
.sign-in-flow__link:not(:first-child)
  padding-top 25px
  +strictly-above(mobile-cutoff)
    padding-top 0
    padding-left 20px

.sign-in-flow--split-input-form__error-container
  position relative

.sign-in-flow-split-input-form__error
  caption-1()
  top 10px
  width 100%
  color $tomato
  margin 10px auto
  position relative

.sign-in-flow__email-links
  text-align center
  margin 14px auto 0
  caption-1()
  color $oyster

.sign-in-flow__email-link-spacer
  padding 0 3px

.sign-in-flow__email-link
  caption-1()
  dark-link()
  color $oyster
  &:link, &:hover, &:active, &:visited
    color $oyster
