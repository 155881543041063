.basket__fulfillment-day-dropdown__item-heading
  body-2()
  color $black
  margin 0
  margin-top -5px /* To offset extra space in font characters */
  margin-bottom 2px

  &.is-unavailable
    color $oyster

.basket__fulfillment-day-dropdown__item-availability-description
  caption-1()
  color $oyster
  white-space nowrap
  margin-bottom -4px /* To offset extra vertical space in font */

  &.is-partially-available
    color $tangerine
