.single-order-page__address a
  link()

.single-order-page__address-body
  form-1()

.single-order-page__gift-recipient
  margin: 5px 0 15px

.single-order-page__gift-recipient-phone
  margin-bottom 15px

.single-order-page__gift-message-heading
  form-2()
  margin-bottom 10px

.single-order-page__gift-message-heading
  margin-top: 20px

.single-order-page__address-leave-at-door
  margin-top 15px

.single-order-page__green-icon
  color $avocado
  margin-right 3px
  margin-top -3px

.single-order-page__address-delivery-instructions,
.single-order-page__gift-message
  margin-top 10px
  color $oyster
  text-overflow ellipsis
  overflow hidden
  white-space nowrap

  >textarea
    box-sizing border-box
    width 100%
    height 100px

.single-order-page__gift-message
  color: $chestnut

.single-order-page__gift-recipient-column
.single-order-page__gift-recipient-phone-column
  >input
    box-sizing border-box
    width 100%

.single-order-page__address-street-address-column
.single-order-page__gift-recipient-column
.single-order-page__gift-recipient-phone-column
  display inline-block
  vertical-align top
  margin-right 10px
  margin-top 20px
  width 530px
  +below(850px)
    display block
    width 100%

.single-order-page__address-address2-column
  display inline-block
  vertical-align top
  margin-top 20px
  +below(850px)
    display block
    margin-top 10px
    >input
      box-sizing border-box
      width 100%

.single-order-page__address-edit-header
  label-1()
  color $oyster
  margin-bottom 10px

.single-order-page__address-leave-at-door-options
  padding 10px 0

.single-order-page__address-leave-at-door-option
  padding 5px 0
  &:hover
    cursor pointer

.single-order-page__address-edit-error
  margin 30px 0 0

.single-order-page__address__has-error, .single-order-page__address__has-error:focus
  border 1px solid $tomato

.single-order-page__address__error
  margin 5px 0 0
  color $tomato
  caption-1()

