@import './components/steps_header/index.styl'
@import './components/confirm_address_modal/index.styl'
@import './delivery_address_page/index.styl'
@import './delivery_options_page/index.styl'
@import './basket_page/index.styl'
@import './payment_details_page/index.styl'
@import './review_page/index.styl'

// button-row is meant when there are two buttons
.checkout__button-row
  overflow hidden
  margin-right -15px
  padding-bottom 2px

  .button.button--checkout
    margin 0
    margin-top 15px
    margin-right 15px
    float right
    width 30%
    padding 0

    +below(mobile-cutoff)
      width 40%

.button.button--checkout
  display block
  +below(mobile-cutoff)
    width 100%
    margin 15px auto 0
    margin-bottom 1px
    white-space nowrap

  +above(mobile-cutoff)
    margin 15px 0 0 auto

.checkout__title
  display-1()
  margin-bottom $gutter

.checkout__title-hr
  margin-bottom $gutter

.basket-page__back-link
  hr
    margin-top $gutter

  a
    display block
    margin-top $gutter
    label-1()

  .icon
    margin-right 3px
    margin-top -3px

a.checkout__link
  color $laurel
  &:visited
    color $laurel
  &:hover
    color $sage

  &.button-is-disabled
    background none
    color $oyster

.checkout__separator
  margin 15px 0 10px 0

.summary-details
  .promo
    font-size 14px
    display flex
    justify-content space-between

  label
    margin-bottom 2px

  p
    caption-1()
    margin-top 0
    margin-bottom $gutter

  .summary-content div:last-child p
    margin-bottom 0
