.single-order-page__edit-controls
  float right
  margin-top -3px

.single-order-page__change-button
  float right
  skinny-secondary-button()
  width 75px
  padding 0

.single-order-page__cancel-button
  float right
  skinny-secondary-button()
  width 80px
  padding 0

.single-order-page__save-button
  float right
  margin-left 10px
  skinny-button()
  width 80px
  padding 0
  &.saving
    padding 0 14px
    width initial
  +below(mobile-cutoff)
    width 80px
    padding 0
    .submit-button__label
      display none
