.favorites-page
  max-width desktop-cutoff
  margin 0 auto
  position relative
  +between(tablet-cutoff, desktop-cutoff + $desktopMargin)
    margin 0 $desktopMargin

.favorites-page__title
  display-2()
  margin-bottom 0
  margin-left 8px
  +above(tablet-cutoff)
    margin-left 0

.favorites-page.is-empty
  text-align center
  margin-top 50px

  .favorites-page__body-text
    margin-top 17px
    body-1()

.favorites-page__demo-tile
  display block
  +above(mobile-cutoff)
    margin 20px auto
    width 315px
    height 204px
    background-image url('/img/web/favorites-empty-avocado.jpg?auto=format') /* Avoid downloading for mobile */
    background-repeat no-repeat
    background-size contain

.favorites-page__category-title
  title-5()
  margin-bottom 18px
  margin-left 8px
  +above(tablet-cutoff)
    margin-left 0

.favorites-page__products
  +above(listing-one-column-cutoff)
    margin-left 8px
    margin-right 8px

  +above(listing-two-column-cutoff)
    margin-left 0
    margin-right 0

.favorites-page.is-not-empty
  .favorites-page__title
    padding-top 24px

  .favorites-page__container
    width 100%
    +above(tablet-cutoff)
      width 80%
      margin-left 20%

.favorites-page__sidebar
  display none

  +above(tablet-cutoff)
    position absolute
    left 0
    width 20%
    display block

.favorites-page__sidebar-entry
  display block
  font-size 14px
  line-height 30px

.favorites-page__category-anchor
  position relative
  top -97px // Offset to leave room for header, and the shadow of listings above
