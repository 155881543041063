@import '../raised_tile/index.styl'

$buttonPadding = 15px

@keyframes fadeIn
  0%
    opacity 0
  100%
    opacity 1

.grid-row
  display block
  width 100%
  vertical-align top
  +above(listing-one-column-cutoff)
    margin-bottom 8px
  +above(listing-two-column-cutoff)
    margin 0 $desktopMargin $desktopMargin 0

.grid-item
  display inline-block
  vertical-align top
  width 100%

  +above(listing-one-column-cutoff)
    width grid-item-width(2, 8px)
    margin 0 8px 8px 0
    &:nth-child(2n)
      margin-right 0

  +above(listing-two-column-cutoff)
    width grid-item-width(3, $desktopMargin)
    margin 0 $desktopMargin $desktopMargin 0
    &:nth-child(2n)
      margin 0 $desktopMargin $desktopMargin 0
    &:nth-child(3n)
      margin-right 0

.grid-row, .grid-item
  transition background-color .2s
  &.empty
    background-color $halibut
  &.lazyloaded
    .product-tile
      animation fadeIn 0.2s ease-in-out

.grid-item-placeholder
  padding 10px 10px 0 10px
  height 110px // fixed height
  border-bottom 1px solid $pearl

  +above(listing-one-column-cutoff)
    border-bottom none
    padding-bottom (100% * (350 / 560)) // equivelant space to the aspect ratio image
    height 129px // fixed height of non-photo contents

  +above(listing-two-column-cutoff)
    border-bottom none
    padding-bottom (100% * (350 / 560)) // equivelant space to the aspect ratio image
    height 129px // fixed height of non-photo contents

.product-tile__placeholder-title
  font-size 15px
  line-height 21px
  color $silt
  margin-bottom 5px

  &:last-child
    font-weight bold

.product-tile // .product-listing
  raised-tile()
  position relative

// Project Visuals Section
.product-tile__product-visuals // .photos
  position relative
  margin-bottom 10px

  &:before
    display block
    padding-top (100% / (560 / 350))
    content ""

.product-tile__product-photo // .photo
  position absolute
  top 0
  width 100%
  height 100%
  max-height 100%
  background-size cover
  background-color $eggshell
  border 0 none
  // alt-tag styling
  color $oyster
  text-align center

.product-tile__product-photo-tinter // .photo-tinter
  position absolute
  right 0px
  top 0px
  height 100%
  width 100%
  pointer-events none
  transition(background-color 0.2s ease-in-out)

.product-tile__message
  position absolute
  top 0px
  left 0px
  height 100%
  width 100%
  text-align center
  overflow hidden

.product-tile__message__text
  position relative
  padding 0
  margin-top calc(35% - 14px)
  height 42px
  width 100%
  form-1()
  font-size 18px
  text-shadow 0px 0px 6px rgba(0,0,0,0.9)
  color white

  -webkit-touch-callout none
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none

  &:hover
    cursor default

  &.two-line
    margin-top calc(50% - 50px)

.product-tile__quick-add // .quick-add
  pointer-events none
  opacity 0.9

// suppress quick add buttons for gift items
// 09/2020 - deprecated, remove
.product-tile__quick-add.gifted .product-tile__quick-add-buttons
  display none

.product-tile__quick-add-count // .quick-add-count
  position absolute
  top 0px
  left 0px
  height 100%
  width 100%
  text-transform uppercase
  text-align center
  overflow hidden
  transform-origin 50% 50%
  transition(opacity 0.2s ease-in-out)

.product-tile__quantity-spinner
  position absolute
  width 100%
  animation-duration 0.2s
  animation-timing-function ease-in-out

.product-tile__quantity-spinner__previous-quantity
  opacity 0

spinnerAbove()
  top -23px
  opacity 0

spinnerCenter()
  top 8px
  opacity 1

spinnerBelow()
  top 32px
  opacity 0

@keyframes centerToAbove
  0%
    spinnerCenter()
  100%
    spinnerAbove()

@keyframes aboveToCenter
  0%
    spinnerAbove()
  100%
    spinnerCenter()

@keyframes centerToBelow
  0%
    spinnerCenter()
  100%
    spinnerBelow()

@keyframes belowToCenter
  0%
    spinnerBelow()
  100%
    spinnerCenter()

.product-tile__quick-add-quantity, .product-tile__quick-add-gift // .quantity
  position relative
  margin-top calc(30% - 40px)
  padding 0
  height 42px
  width 100%
  font-family $semiboldFontFamily
  text-shadow 0px 0px 6px rgba(0,0,0,0.9)
  color white
  line-height 32px
  font-size 38px

  &.animate-scroll-up
    .product-tile__quantity-spinner__quantity
      animation-name belowToCenter
    .product-tile__quantity-spinner__previous-quantity
      animation-name centerToAbove

  &.animate-scroll-down
    .product-tile__quantity-spinner__quantity
      animation-name aboveToCenter
    .product-tile__quantity-spinner__previous-quantity
      animation-name centerToBelow

  // we need different animation names that produce the same effect so that we can reliably retrigger the same animation in succession
  &.animate-scroll-up-alt
    .product-tile__quantity-spinner
      animation-direction reverse

    .product-tile__quantity-spinner__quantity
      animation-name centerToBelow
    .product-tile__quantity-spinner__previous-quantity
      animation-name aboveToCenter

  &.animate-scroll-down-alt
    .product-tile__quantity-spinner
      animation-direction reverse

    .product-tile__quantity-spinner__quantity
      animation-name centerToAbove
    .product-tile__quantity-spinner__previous-quantity
      animation-name belowToCenter

.product-tile__quick-add-gift
  font-size 68px

.product-tile__quick-add-quantity-num // no class currently

.product-tile__quick-add-quantity-label, .product-tile__quick-add-gift-label // .label
  position relative
  top 0
  color white
  font-family $semiboldFontFamily
  text-shadow 0px 0px 6px rgba(0,0,0,0.9)
  font-size 13px
  line-height 10px
  letter-spacing 0.5px

.product-tile__quick-add-gift-label
  top 40px

.product-tile__quick-add-quantity-label-result // .result
  position absolute
  top 0
  width 100%
  transition(opacity 0.2s)
  opacity 0

.product-tile__quick-add-quantity-label-summary // .summary
  position absolute
  top 0
  width 100%
  transition(opacity 0.2s)
  opacity 1

.product-tile:hover .product-tile__quick-add-quantity-label-result
  opacity 1

.product-tile:hover .product-tile__quick-add-quantity-label-summary
  opacity 0

.product-tile__quick-add-buttons
  position absolute
  top $buttonPadding
  right $buttonPadding
  bottom $buttonPadding
  left $buttonPadding

.product-tile__quick-add-button
  position absolute
  height 40px
  box-sizing border-box
  border none
  background-color $sage
  box-shadow none
  text-align center
  font-size 36px
  pointer-events auto
  transition(background-color 0.2s)

  &:hover
    background-color $eggshell

    .product-tile__quick-add-minus, // .minus
    .product-tile__quick-add-plus::before, // .plus
    .product-tile__quick-add-plus::after // .plus
      background-color $sage

.product-tile__quick-add-button-add // .add
  right 0
  bottom 0
  min-width 65px
  width 90px
  border-radius 40px
  transition-property width, border-radius;
  transition-duration 350ms;
  transition-timing-fuction ease;

.product-tile__quick-add-button-remove // .remove
  // Initial position under the + button
  right 20px
  left calc(68% + 10px)
  bottom 0
  width 40px
  border-radius 40px 0 0 40px
  opacity 0
  transition-property width, left, opacity;
  transition-duration 350ms;
  transition-timing-fuction ease;

.product-tile__quick-add-minus, // .minus
.product-tile__quick-add-plus // .plus
  margin-left calc(50% - 9px)

.product-tile__quick-add-minus // .minus
  width 18px
  height 2px
  background $eggshell

.product-tile__quick-add-plus // .plus
  position relative
  width 18px
  height 18px

  &::before, &::after
    position absolute
    background $eggshell
    content ''
    transition(background-color 0.2s)

  &::before
    left 8px
    width 2px
    height 18px

  &::after
    top 8px
    right 0px
    height 2px
    width 18px

.product-tile__quick-add-button:hover
  .product-tile__quick-add-minus,
  .product-tile__quick-add-plus
    color $sage

// Product Information
.product-tile__product-info
  margin 0
  padding 0 10px

.product-tile__product-name-and-details
  position relative
  height 93px

.product-tile__producer-name // .producer-name
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  body-1()

.product-tile__product-description
  -webkit-line-clamp 3
  -webkit-box-orient vertical
  display -webkit-box
  font-family $bodyFontFamily
  font-size 1.25rem
  height 1.4em * 3 // line-height * num of lines to show
  letter-spacing 0.2px
  line-height 1.4em
  margin-bottom 7px
  overflow hidden
  padding-top 12px

.product-tile__availability-wrapper // .availability-container
  float right
  margin-left 5px

.product-tile__next-available-day // .next-available-day
  white-space nowrap
  cursor default
  transition(all 0.1s) // TODO: DO WE NEED THIS???
  transition-properties(background opacity) // TODO: DO WE NEED THIS???

.product-tile__availability-status // .product-availability-status
  display inline-block
  font-family $bodyFontFamily
  font-size 11px
  line-height 15px
  text-transform capitalize

.product-tile__availability-indicator // .indicator
  display inline-block
  margin-right 0.25em
  i
    vertical-align top
    color $chanterelle
    .day
      margin-left .25em
      color $oyster
      display inline-block
      font-family $bodyFontFamily
      font-size 11px
      line-height 15px

.product-tile__availability-indicator__preorder
  i
    color $black

// TODO: Turn this into a state or subclass
.next-foodhub-day .product-tile__availability-indicator
  background $availabilityGreen

// TODO: Turn this into a state or subclass
.unavailable .product-tile__availability-indicator
  background $availabilityLightGray

.product-tile__product-name // .name
  title-4()
  line-height 24px
  height 48px
  display -webkit-box
  margin 0 0 5px
  overflow hidden
  text-overflow ellipsis
  -webkit-line-clamp 2
  -webkit-box-orient vertical
  a
    color $black
    text-decoration none
    &:hover
      color $sage

.product-tile__subscription-container
  text-align right
  color $oyster
  minitext-1()
  text-transform none
  line-height 12px
  position absolute
  bottom 5px
  right 0

  i
    font-size 12px
    margin-right 5px

.product-tile__product-description
  -webkit-line-clamp 3
  -webkit-box-orient vertical
  display -webkit-box
  font-family $bodyFontFamily
  font-size 1.25rem
  height 1.4em * 3 // line-height * num of lines to show
  letter-spacing 0.2px
  line-height 1.4em
  margin-bottom 7px
  padding-top 12px

// COLLAPSING ROW

.product-tile-collapsing-row // .product-tile__price-info
  display flex
  align-items baseline

.product-tile-strikethrough
  justify-content: end
  text-decoration: line-through
  min-height 20px

.promo-discount-info
  color: $error

.product-tile-collapsing-row__secondary-info // .product-tile__comparison-price
  overflow hidden
  white-space nowrap
  text-overflow ellipsis
  order 1
  flex-grow 1
  flex-shrink 1
  -ms-flex-positive 1
  -ms-flex-negative 1
  padding-right 0.5em

.product-tile-collapsing-row__primary-info // .product-tile__purchase-unit
  order 2
  flex-grow 1
  flex-shrink 0
  -ms-flex-positive 1
  -ms-flex-negative 0
  text-align right

.product-tile-collapsing-row__fixed-right // .product-tile__purchase-price
  margin-left 4px
  text-align right
  order 3
  flex-shrink 0
  -ms-flex-negative 0

 

// PRICE INFO

.product-tile__price-info
  padding 4px 0 0 0

.product-tile__comparison-price,
.product-tile__purchase-unit
  color $black
  font-family $bodyFontFamily
  font-size 11px
  line-height 19px
  font-weight 500

.product-tile__comparison-price
  opacity 0.5 // TODO: CHANGE THIS TO AN ACTUAL COLOR

.product-tile__purchase-price
  .price
    margin-top -8px
    font-size 28px
    line-height normal
    display inline-block
  .dollars
    font-size 14px

.product-tile .icon-deal-tag
  display none


// PRODUCT TILE SUBCLASS
+above(tablet-cutoff)
  .product-tile--deal
    border-bottom 3px solid $tomato

.product-tile--deal .icon-deal-tag
  display inline-block
  margin-left 6px
  font-size 16px
  vertical-align 1px
  color $tomato

.product-tile__quick-add-gift, .product-tile__quick-add-gift-label
  display none

//DISABLED STATE
.product-tile-disabled
  .product-tile__product-photo-tinter
    background-color rgba(0,0,0,0.4)

// IN BASKET STATE
.product-tile-is-in-basket
  .product-tile__product-photo-tinter
    background-color rgba(0,0,0,0.4)

  .product-tile__quick-add
    .product-tile__quick-add-button-add
      width 50%
      right 0
      min-width 65px
      border-radius 0 40px 40px 0

    .product-tile__quick-add-button-remove
      width calc(50% - 1px)
      left 0
      right auto
      min-width 10px
      opacity 1

// GIFT STATE
.product-tile-is-product-gift
  .product-tile__product-photo-tinter
    background-color rgba(0,0,0,0.4)

  .product-tile__quick-add-quantity, .product-tile__quick-add-quantity-label
    display none

.product-tile__quick-add.gifted
  .product-tile__quick-add-gift, .product-tile__quick-add-gift-label
    display inherit

tooltip-container()
  position relative
  opacity 0
  pointer-events none
  transition(opacity 200ms 100ms)

tooltip(background, color, extraLoft = 0px, strokeColor = rgb(217,217,217))
  position absolute
  box-sizing border-box
  // Calculate extra height
  if extraLoft != 0px
    bottom "calc(1em + %s)" % extraLoft
  else
    bottom calc(1em)

  padding 10px
  background-color background
  white-space nowrap
  color color
  font-size 13px

  border 1px solid strokeColor
  border-radius(3px)
  box-shadow(0 1px 0 0 rgba(0,0,0,0.1))

  &:before // text bubble arrow
    // This makes a 20px wide and 10px high arrow pointing down.
    display block
    position absolute
    bottom -8px

    width 14px
    height 14px
    background-color background
    content ''
    box-shadow(1px 1px 0 0 rgba(0,0,0,0.1))
    transform rotate(45deg)


.product-tile__availability-info-container
  tooltip-container()

// Make tooltip-container() visible
.product-tile__availability-wrapper:hover .product-tile__availability-info-container
  opacity 1

.product-tile__availability-info
  tooltip(background: white, color: $chestnut, leftOffset: -177px)
  left -177px
  padding 7px 9px 3px 9px

  &:before
    left 173px

  .icon-truck
    font-size 20px
    margin-right 6px

body.logged-out
  .product-tile__favorite
    display none

body:not(.logged-out)
  +above(mobile-cutoff)
    .product-tile:hover
      .product-tile__favorite.not-favorited
        display block

.product-tile__favorite.favorited
  .product-tile__favorite-heart>path
    fill $tomato
    stroke none

.product-tile__favorite.not-favorited
  display none

.product-tile__favorite
  position absolute
  top 10px
  right 10px
  z-index 1
  +below(mobile-cutoff)
    pointer-events none
    top 7px
    right 7px

.product-tile__favorite-circle
  width 30px
  height 30px
  border-radius 50%
  background-color rgba(255, 255, 255, .8)
  display flex
  align-items center
  &:hover
    cursor pointer
  +below(mobile-cutoff)
    width 20px
    height 20px

.product-tile__favorite-heart
  flex 1
  width 15px
  height 16px
  +below(mobile-cutoff)
    width 10px
    height 11px
  >path
    fill none
    stroke $tomato
    stroke-width 1

.product-tile__deal-info-container
  tooltip-container()

// Make tooltip-container() visible
.icon-deal-tag:hover .product-tile__deal-info-container
  opacity 1

.product-tile__deal-info
  tooltip(background: $tomato, color: $eggshell, extraLoft: 16px, strokeColor: rgba(150,150,150,0.19))
  right -20px
  padding-top 5px
  line-height 15px
  font-family $bodyFontFamily

  &:before
    right 24px

.product-tile__deal-info-header
  font-family $semiboldFontFamily
  line-height 17px

// Larger compact
compact-tile-large()
  $buttonPadding = 10px

  display flex
  display -ms-flex
  height 120px

  .product-tile__product-visuals
    max-width 50%
    flex-basis 180px
    flex-shrink 0
    -ms-flex-preferred-size 180px
    -ms-flex-negative 0
    margin-bottom 0

    &:before
      display none

  &.product-tile--deal .product-tile__product-visuals
    margin-bottom -3px

  .product-tile__product-info
    min-width 0
    flex-basis auto
    flex-grow 1
    -ms-flex-preferred-size auto
    -ms-flex-positive 1

  .product-tile__product-name-and-details
    margin-top 6px
    height 76px

  .product-tile__product-name
    font-size 14px
    line-height 14px
    height 44px // max 3 lines + decender
    overflow hidden
    -webkit-line-clamp 3

  .product-tile__quick-add-buttons
    top $buttonPadding
    right $buttonPadding
    bottom $buttonPadding
    left $buttonPadding

  .product-tile__comparison-price
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    line-height 20px
    padding-right 0.5em

  .product-tile__purchase-unit
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    line-height 20px
    padding-right 0.5em
    text-align right

  .product-tile__purchase-price
    margin 0
    line-height 20px

  .icon-deal-tag
    order 4
    line-height 20px

  .price, .dollars, .cents
    font-size 12px
    line-height normal

  .price.usd.split
    margin 0
    line-height 20px

  .split-price__dollars
    font-size 20px
  
  .split-price__cents
    font-size 10px

  .usd.split .cents
    position relative
    line-height normal
    bottom 0.6em
    font-size 0.55em
    vertical-align baseline

  .product-tile__quick-add-quantity-label
    font-size 11px

  .product-tile__quick-add-quantity, .product-tile__quick-add-gift
    margin-top 15px
    font-size 30px
    height 38px

  +between(listing-two-column-cutoff, 1150px)
    .product-tile__comparison-price
      display none

  +below(mobile-cutoff)
    box-shadow none
    .product-tile__product-name-and-details
      height 64px
    .product-tile__product-info
      border-bottom 1px solid $silt
    .product-tile__price-info
      border-top 0 none
      padding 0
      height 20px
      padding-bottom 6px
    .product-tile__producer-name
      font-size 11px
    .product-tile__product-visuals
      overflow hidden
    .test-bookmark
    .product-tile__product-photo // .photo
      width 180px
      height 120px
      left 50%
      margin-left -90px

.product-tile--gift
  border 1px solid $eggshell
  compact-tile-large()

  .product-tile__product-info
    background-color white

  .product-tile__price-info
    padding 0

  .product-tile__purchase-unit
    font-family $bodyFontFamily
    text-align left
    order 1

  .product-tile__gift-from
    font-size 12px
    line-height 33px
    font-family $semiboldFontFamily
    order 2
    flex-grow 1
    flex-shrink 0
    -ms-flex-positive 1
    -ms-flex-negative 0
    text-align right

  +below(mobile-cutoff)
    .product-tile__gift-from
      display none

.product-tile--promo
  .product-tile__purchase-price
    display none
