.upcoming-orders-banner
  position relative
  background $sage
  border-top 1px solid #edeae6
  padding: 15px
  text-align center

.upcoming-orders-banner.preorder
  background $chestnut

.upcoming-orders-banner__copy
  display inline-block
  form-1()
  color white
  a, a:hover, a:focus
    color white
    text-decoration underline

.upcoming-orders-banner__subscription-icon
  color white
  display inline-block
  font-size 19px
  margin-right: 8px
  margin-top -10px

  .icon-alert-warning
    color $tomato
    top 11px
    position absolute
    font-size 14px
    margin-left -3px
    background-color white
    border-radius 9px
    height 10px
    width 10px
    &:before
      position absolute
      top -1px
      left -1px
