// Product Detail View
$thumbHeight = 72px
$thumbWidth = 180px
$leftColWidth = 70%
$rightColWidth = 30%


// Extractable?????
small-caps-font()
  font-family $semiboldFontFamily
  font-size 11px
  text-transform uppercase
  letter-spacing 1px

.product-detail-view
  color $black
  max-width desktop-cutoff
  margin 0 auto
  font-family $bodyFontFamily
  font-size 12px

  .google-login-button
    .google-button-text
      +between(625px, 813px)
        display none

      +between(0px, 312px)
        display none

  & > *
    box-sizing border-box
    -moz-box-sizing border-box
    -webkit-box-sizing border-box

  h1
    font-family $largeHeadingsFontFamily
    color $black

  h2
    display inline-block
    font-family $bodyFontFamily

  section, .section
    margin-top $gutter

  .section-header
    small-caps-font()
    font-size 9px
    color $oyster
    display block
    margin-top 0
    margin-bottom 5px
    line-height 3em

  // Product Photos
  .photos
    clear left
    position relative
    &:before
      content ""
      display block
      padding-top (100% / (560 / 350))

  .photo
    position absolute
    top 0
    max-height 100%
    width 100%



  // Product information section
  .product-header
    border-bottom 2px solid $silt
    padding 10px 0

  .current-producer-label
    label-1()
    color $oyster

  .product-name
    color $black
    font-size 20px

  .producer-name
    letter-spacing -0.2px
    font-size 13px
    line-height 1.4em

  .producer-link
    color $black
    &:hover
      color $sage



  // Control Panel / Purchase Form
  .basket-controls
    +below(tablet-cutoff)
      padding-left 8px
      padding-right 8px

  .choice-select
    border-radius 4px

  .form-control
    padding 8px 16px

  .form-control.select
    width 100%
    box-sizing border-box
    background-color white
    box-shadow(none)
    font-size 16px
    line-height 24px
    color $oyster

    .icon-chevron
      position absolute
      top 10px
      right 12px
      color $silt

  .price
    margin 4px 0
    color $black

  .usd.split
    .dollars
      font-size 40px
      font-weight normal
    .cents
      line-height 1.85em
      +below(tablet-cutoff)
        line-height 2.0em

  .unit-detail
    font-weight normal
    font-size 15px

    &.quantity-unit
      font-family $semiboldFontFamily
      margin-top 6px
    &.unit-price
      margin-top 4px

    .discounted-price-per
      display none

    &.price-is-discounted
      .discounted-price-per
        display inline
        color $tomato
        padding-right 0.5em

      .price-per
        padding-right 0.4em
        text-decoration line-through
        &:before
          content ' '
        &:after
          content ' '

    button.save
      display block
      margin 25px auto

  .deal-details
    margin 0.5em 0px
    line-height 2.25em

  .deal-details-content
    color $tomato
    font-size 15px

  .deal-heading
    font-size 17px
    font-family $semiboldFontFamily

  .icon-deal-tag
    float left
    margin-right 6px
    font-size 14px
    margin-top 8px
    color $tomato

  .basket-day-warning
    color $oyster
    font-size 12px


  // Share Product
  .share, .share-title
    display none

  // Product Details
  .all-details
    margin 15px
    +below(tablet-cutoff)
      margin 8px


  // Product Description
  .product-details-description
    margin-bottom 20px
    .description-body:before
      font-size 3.5em
      font-family $smallHeadingsFontFamily
      font-weight normal
      line-height 0
      position relative
      top 0.45em
      margin-right 10px
      color $sage

    .description-body a
      color $sage

    .description-body a:hover
      text-decoration underline


  // "Product Details"
  .product-details
    .previous-product-names
      font-weight normal
      font-style italic

  .quantity-details
    padding-left 10px
    margin-bottom 20px
    border-left 4px solid $sage

  // "Available For Delivery"
  .product-availability-details
    border-top 1px solid $silt
    border-bottom 1px solid $silt
    margin-bottom 6px

  //
  // TABLET STYLE OVERRIDES
  // =======================================================================
  +above(product-detail-one-column-cutoff)
    $leftColWidth = 60%
    $rightColWidth = 40%

    padding-left 15px
    padding-right 15px
    line-height 1.4em
    font-size 13px

    .product-header
      padding-top 0

    .product-name
      font-size 30px
      line-height 1.2em

    .producer-name
      font-size 20px

    .section-header
      font-size 11px
      letter-spacing 0.7px

    .basket-controls
      float right
      padding-left 15px
      width $rightColWidth
      max-width none
      margin 0
      margin-bottom -10000px

    .photos, .all-details section
      width $leftColWidth
      float left

    .product-details-description, .product-quantity
      border-top 0
      margin-top 5px

    .product-availability-details
      font-size 12px

    .all-details
      margin-top 0
      margin-left 0
      margin-right 0

    .product-details-description
      .description-body:before
        font-size 44px

    // Share Product
    .share
      display block

    .producer-detail-body
      flex-direction row

    .producer-detail-col1
      flex 0 0 $thumbWidth
      margin-right $gutter * 1.33

    .producer-detail-col2
      flex 1 1 auto

    .producer-mobile-head-left, .producer-mobile-head-right
      float none
      width auto
      padding 0

    .producer-thumbnail
      width 100%
      float none
      margin-bottom 5px

    .producer-name-desktop, .producer-location-desktop, .webstand-link-desktop
      display block !important

    .producer-name-mobile, .producer-location-mobile, .webstand-link-mobile
      display none !important

  //
  // SMALL DESKTOP STYLE OVERRIDES
  // =======================================================================
  +above(tablet-cutoff)
    $leftColWidth = 70%
    $rightColWidth = 30%

    font-size 14px

    .product-name
      font-size 34px
      line-height 1.2em

    .basket-controls
      padding-left: 20px !important
      width $rightColWidth !important

      .price
        margin 10px 0

      .usd.split
        font-size 54px

        .dollars
          font-size 48px

      .unit-detail
        font-size 14px

        &.quantity-unit
          margin-top 10px
          font-size 18px

    .photos, .all-details section
      width $leftColWidth !important
      line-height 1.6em

    .product-availability-status
      margin-right 20px

    .product-details-description
      font-size 15px
      .description-body:before
        font-size 44px

  // LARGE DESKTOP STYLE OVERRIDES
  +above(product-detail-expanded-cutoff)
    .product-details-description
      .description-body:before
        position absolute !important
        left 165px !important
        top 38px !important

    .all-details
      section, .section
        padding-top 15px
        padding-left 200px
        position relative
        box-sizing border-box
        margin-top 10px

        header
          position absolute
          left 0
          width 160px
          margin-top 0

          .section-header
            line-height 1.7em

      section.product-details-description
        border-top 0

    .producer-detail-body
      position relative
      top 3px

    .product-availability-status, .filter
      vertical-align text-top

    .product-availability-status
      margin-bottom 5px


// Product Gift styles
.product-detail-view__gift-message
  img
    display inline-block
    width 40px
    height 47px
    margin 0 10px 10px
    vertical-align middle

.product-detail-view__gift-headline
  vertical-align middle
  display inline-block
  width 125px
  height 34px

.product-detail-view  .basket-controls.gifted
  .price
    .unit-detail.unit-price
      text-decoration line-through
      color $oyster

      span
        color $silt

    .usd
      color $silt
      position relative

      &:before
        position absolute
        display block
        content " "
        border-top 1px solid $oyster
        height 1px
        width: 120%;
        transform: rotate(-35deg);
        bottom: 6px;
        transform-origin: left;

  .quantity.select
    display none

.product-detail-view__gift-body
  margin-left 20px
  font-style italic
  margin-top 5px
  border 0px solid white

section.product-detail-page__send-a-product
  border none

.product-detail-page__send-a-product
  text-align center
  border-radius 3px
  color $black

  .send-a-product__offer
    padding 20px
    background-color $ricotta

  .send-a-product__gift-icon
    margin 16px 0
    font-size 52px

  .send-a-product__headline
    display block
    font-family $largeHeadingsFontFamily
    font-size 27px
    line-height 27px

  .tablet-break
    +below(product-detail-expanded-cutoff)
      display block

  .send-a-product__details
    font-size 13px
    line-height 20px

  .send-a-product__terms
    caption-1()
    color $oyster

    a
      color $sage

  .send-a-product__open-modal
    color $sage
    margin 0 auto

    &:hover
      color $ricotta

body.logged-out
  .product-detail__favorite-control
    display none
