@import './components/add_to_order.styl'
@import './components/cancel_order/index.styl'
@import './components/delivery_address/index.styl'
@import './components/delivery_time.styl'
@import './components/edit_controls.styl'
@import './components/order_items/index.styl'
@import './components/payment_details/index.styl'

.single-order-page
  text-align center

.single-order-page__global-error
  +below(620px)
    margin 20px 10px
  margin 20px 30px
  text-align left

.single-order__main-container
  margin 0 auto
  max-width 850px

.single-order-page__items
  border-bottom 1px solid $silt

.single-order-page__cancelled-message
  padding 50px 0
  body-1()

.single-order-page__modal-title
  title-3()
  padding-bottom 20px

.single-order-page__modal-body
  form-1()
  padding-bottom 20px

.single-order__back-to-orders
  margin 44px 0 0 30px
  +below(620px)
    margin-left 10px
  text-align left

.single-order__back-to-orders-link
  label-1()
  link()

.single-order__back-to-orders-link-chevron
  vertical-align 0

.single-order-page__totals-container
  padding 24px 30px 0
  +below(mobile-cutoff)
    padding 24px 15px 0
  &:last-child
    padding-bottom 24px

.single-order-page__manage-subscriptions
  border-top 1px solid $silt
  padding 24px 30px
  +below(mobile-cutoff)
    padding 24px 15px

.single-order-page__manage-subscriptions__link
  form-1()
  link()


.single-order-page__section
  background-color $eggshell
  padding 24px 30px
  border-bottom 1px solid $silt
  form-1()
  +below(mobile-cutoff)
    padding 24px 15px


.single-order-page__section-heading
  form-2()
  margin-bottom 10px

.packaging-project-fee-message
  font-size 10px
  font-style italic
  padding-top 15px

.single-order-page__totals-footer
  display flex
  min-height 44px

.single-order-page__promocode-label
  order 2
  flex-grow 1
  text-align right
  padding-right 14px
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  font-size 12px

  +above(mobile-cutoff)
    padding-right 30px
    font-size 14px
