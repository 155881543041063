.about-page__our-standards-alternating-grid
  width 100%
  max-width 1000px
  border none
  border-collapse collapse

.about-page__our-standards-summary
  max-width 700px
  margin 0 auto

.about-page__our-standards-copy
  body-4()
  margin-left auto
  margin-right auto

.about-page__our-standards-desktop
  display block
  margin 40px auto 0
  max-width 1000px


.about-page__our-standards-grid-header
  display-1()

.about-page__our-standards-grid-copy
  form-1()
  margin-top 2em
  +strictly-below(900px)
    body-3()

.about-page__subtext
  caption-1()
  color $black
  text-align center
  margin-top 30px
  &>a
    link()

.about-page__our-standards-icon
  font-size 250%
  margin-bottom 15px
  +strictly-below(900px)
    display none
