.membership-page
  form-1()

.membership-page__section
  padding 30px
  border-bottom 1px solid $silt
  +below(mobile-cutoff)
    padding 20px

.membership-page__button
    margin-top 20px