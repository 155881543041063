.membership-modal
  max-width 320px
  padding 48px 32px 48px
  display flex
  flex-direction column

.membership-modal__logo
  text-align center
  display flex
  justify-content center
  margin-bottom 20px

.membership-modal__header
  margin-bottom 24px

.membership-modal__title
  font-size 24px
  line-height 24px
  text-align center
  font-weight 700
  font-family Georgia
  color $black

.membership-modal__logo-container
  font-size 6rem

.membership-modal__benefits-container
  display flex
  flex-direction column
  margin-bottom 12px

.membership-modal__benefit
  display flex
  align-items center
  margin-bottom 12px
  font-size 16px
  line-height 24px
  color $black
  span
    margin-left 20px

.membership-modal__button
  margin-bottom 0