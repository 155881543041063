.single-order-page__line-item
  body-1()
  display flex
  flex-direction row
  padding 16px 0

  &:not(:last-child)
    border-bottom 1px solid $silt

  &:first-child
    border-top 1px solid $silt

  &.changed
    background-color mix($aquaponic, $ricotta, 30%)
  &.empty
    background-color rgba(208, 1, 27, 0.06)

.editing .single-order-page__line-item
  border-bottom 1px solid $silt

.single-order-page__line-item-quantity
  // Needs a fixed width to handle different numbers of digits
  form-1()
  text-align center
  line-height 0
  padding 0px 2px 0px 16px
  +below(620px)
    padding-left 0


.single-order-page__line-item-quantity-value
  width 25px
  line-height 60px

.single-order-page__line-item.editing .single-order-page__line-item-quantity-value
  line-height 20px

.single-order-page__line-item-increment-quantity,
.single-order-page__line-item-decrement-quantity
  display inline-block
  user-select none
  font-size 10px
  height 10px
  line-height 10px
  color $white
  padding 8px
  margin 4px 0
  cursor pointer
  border-radius 50%
  background-color $silt
  &.disabled
    cursor auto
  &&:not(&.disabled):hover
    background-color $brownsoil

.single-order-page__line-item-decrement-quantity .icon-chevron
  transform rotate(180deg)

.single-order-page__line-item-image
  min-width 60px
  padding-left 17px
  +below(620px)
    padding-left 8px
  line-height 0

.single-order-page__line-item-image-image
  background-position center center
  background-size cover
  width 96px
  height 60px
  +below(620px)
    width 60px
    height 60px

.single-order-page__line-item-details
  flex-grow 1
  -ms-flex 1
  padding-left 17px
  line-height 1em
  +below(620px)
    padding-left 10px

  .summary-item__prices
    width auto
    height 25px
    float initial

    .summary-item__strikethrough-price
    .summary-item__price
      font-size 1.083rem
      display inline-block
      width auto
      float initial
      margin-right 5px

.single-order-page__line-item-details-vendor-name
  caption-1()
  color $oyster
  margin-bottom 2px

.single-order-page__line-item-details-name
  title-4()
  font-size 16px
  margin-top -2px
  margin-bottom 4px

.single-order-page__line-item-details-total
  body-2()
  +above(620px)
    display none

.single-order-page__line-item__info
  display flex
  flex-direction row
  align-items flex-start
  justify-content flex-start
  flex 1
  +below(620px)
    flex-direction column

.single-order-page__line-item__controls
  display flex
  flex-direction column
  justify-content space-between
  align-items center
  width 120px

  +at-and-below(620px)
    align-items flex-start
    margin-left 10px
    margin-top 10px

.single-order-page__line-item__controls-left
  display flex
  align-items flex-end
  flex-grow 0
  +above(620px)
    height 100%

.single-order-page__line-item__remove
  display flex
  justify-content center
  flex-grow 1
  min-height 16px
  +at-and-below(620px)
    align-self flex-start
    padding 0
  +above(620px)
    margin-top 16px


.single-order-page__line-item__total
  display flex
  justify-content flex-end
  title-3()
  font-size 16px
  +below(620px)
    display none

  .summary-item__prices
    width 100%

.single-order-page__line-item-x-button
  line-height 1em
  font-weight bold
  font-size 16px
  color $tomato
  visibility hidden
  cursor pointer
  +at-and-below(620px)
    display none

.single-order-page__line-item.editing:hover .single-order-page__line-item-x-button
  visibility visible

.single-order-page__line-item__remove-link
  caption-1()
  color $laurel
  cursor pointer
  margin-top 10px
  +above(620px)
    display none

.single-order-page__line-item__subscription
  display flex
  margin-top 10px
  flex-direction row
  align-self flex-end
  +at-and-below(620px)
    align-self flex-start
    margin-top 0

.single-order-page__line-item__subscription-controls
  padding 6px 0 6px 6px
  height 18px
  width 110px
  background $halibut
  border 1px solid $pearl
  border-radius 2px
  cursor pointer
  color $laurel
  line-height 16px
  .icon-subscriptions
    font-size 14px
    margin 1px 5px 0 10px
  .subscribe-label
    font-size 11px
    margin-top 2px
  .ge-checkbox, .subscribe-label, .icon-subscriptions
    display inline-block
    vertical-align middle
    line-height 16px
  &:hover
    color $sage
    .checkbox.active
      background-color $sage

.single-order-page__line-item__subscribed
  .icon
    margin-right 8px
    margin-top -1px
  color $oyster
