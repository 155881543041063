.flow-social-sign-in__subtitle
  title-2()
  font-size 14px
  margin-bottom 15px
  padding 0 10px

.flow-social-sign-in__link
  caption-1()
  dark-link()
  color $oyster
  &:link, &:hover, &:active, &:visited
    color $oyster

.flow-social-sign-in__errors
  width 100%
  margin-bottom 15px
