/* Producer Carousel */
// Any !important flags here are used to override react-slick css
$carousel-height--desktop = 230px
$carousel-height--mobile = 188px
$carousel-multipage-height = 16px

.other-producer-carousel
  background-color $ricotta
  box-shadow 0 1px 0 0 rgba(0,0,0,0.1)
  height $carousel-height--desktop
  +below(mobile-cutoff)
    height $carousel-height--mobile
    margin 0 -17px 0 -17px

.other-producer-carousel--multipage
  height $carousel-height--desktop + $carousel-multipage-height
  +below(mobile-cutoff)
    height $carousel-height--mobile + $carousel-multipage-height

.other-producer-carousel--multipage .slick-list
  height $carousel-height--desktop + $carousel-multipage-height
  +below(mobile-cutoff)
    height $carousel-height--mobile + $carousel-multipage-height

.slick-dots--pdp li button:before
  color $oyster
.slick-dots--pdp li.slick-active button:before
  color $laurel

// Ideally, modify the other instance of slick-dots styling override
.slick-dots--pdp
  height 20px

.slick-prev--pdp
  left 32% !important
  top 95% !important
  +below(mobile-cutoff)
    left 25% !important
    top 92% !important

.slick-next--pdp
  left 65% !important
  top 95% !important
  +below(mobile-cutoff)
    left 70% !important
    top 92% !important

.slick-prev--pdp:before, .slick-next--pdp:before
  color $laurel
  font-family icons

.other-producer-carousel__slide
  margin 17px 0 17px 0
  display flex !important
  flex-flow row nowrap
  justify-content space-evenly
  +below(mobile-cutoff)
    margin-top 0

.producer-carousel-card
  display flex
  flex-flow column nowrap
  width 209px
  +below(mobile-cutoff)
    width calc(100% / 3)

.producer-carousel__thumbnail
  box-sizing border-box
  width 100%
  margin-right $gutter
  border 1px solid $silt
  border-radius 3px
  +below(mobile-cutoff)
    border-radius 0px

.producer-carousel__name
  form-2()
  margin-top 4px
  +below(tablet-cutoff)
    margin 4px
  +below(mobile-cutoff)
    caption-1()
    margin 4px

.producer-carousel__location
  caption-1()
  margin-top 4px
  +below(tablet-cutoff)
    margin 4px
  +below(mobile-cutoff)
    small-caps-font()
    margin 4px

/* Producer Card */
.producer-detail-body
  display flex
  flex-direction column
  position relative
  top 2px

.producer-detail-col1
  flex 0 0 auto
  margin-bottom $gutter
  +above(mobile-cutoff)
    flex-basis 209px !important

.producer-detail-col2
  flex 1 1 auto

.producer-mobile-head-left
  width 50%
  padding-right $gutter
  box-sizing border-box
  float left

.producer-mobile-head-right
  width 50%
  float right

.producer-thumbnail
  box-sizing border-box
  width 100%
  margin-right $gutter
  border 1px solid $silt
  border-radius 3px

.producer-detail-body .producer-link
  font-family $largeHeadingsFontFamily
  font-size 20px
  line-height 1em
  &:not(:hover)
    color $black

.producer-name-desktop
  display none
  form-2()

.producer-location-desktop, .producer-location-mobile
  margin 4px 0 4px 0
  small-caps-font()
  line-height 1em
  .icon-pin
    font-size 1.5em
    margin-right 4px
    position relative
    bottom 1px

.producer-location-mobile
  display flex
  flex-flow row nowrap
  line-height 1em

.producer-location-desktop
  display none

.producer-description
  line-height 1.6em
  .producer-description__text
    padding-right 4px

.webstand-link-desktop, .webstand-link-mobile
  text-align center
  margin 0

.webstand-link-desktop
  display none !important

.webstand-link-mobile
  margin-top 8px

.read-more-link
  small-caps-font()
  padding-left 10px
  white-space nowrap
  .icon-chevron
    margin 0 0.25em
    transform rotate(90deg)
    font-size 120%
    position relative
    bottom 1px

.other-producer-detail
  border-top 0px !important
  +above(mobile-cutoff)
    margin-top 0px !important

.product-details-page__restriction-copy
  font-weight bold

.product-details-page__nutritional-information-copy
  a
    link()
    cursor pointer
    color $dice

.product-availability__restricted-notice
  background-color alpha($availabilityGreen, 0.7)
  padding 4px
  width 240px
  border-radius 5px
  margin-bottom 12px
  text-align center
  position relative
  left 42px

  +below(tablet-cutoff)
    left 21px

.product-prop65
  margin-bottom 30px
  .icon-outlined-warning
    font-size 1.6rem

.producer-products
  display flex
  flex-direction column
  padding-top 20px
  width 100%

  header
    position inherit
    width auto

  .producer-products__scrollbox
    display flex
    margin-top 20px
    flex-wrap nowrap
    overflow-x auto

    .grid-item
      min-width 240px
      max-width 240px
      margin 16px 16px 16px 0