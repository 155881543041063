$splitButtonHeight = 39px

.split-input-button
  display flex

  +strictly-below(galaxyS5-width)
    display block

    .split-input-button__input,
    .split-input-button__button
      width 100%
      display block

    .split-input-button__input
      margin-bottom 10px

.split-input-button__input
  flex 1 1 auto
  display inline-block
  border-radius 3px 0 0 3px
  height $splitButtonHeight
  box-sizing border-box
  vertical-align top
  padding-left 13px
  &.has-error
    border-color $tomato
  font-size 16px
  min-width 0 /* input field has an implicit "size" attribute of 20
    * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-size)
    * which means there's an implicit min-width on the text field of
    * whatever width 20 characters take up, which breaks the flexbox layout
    * when the screen size is below this width plus whatever width the button is.
    */

.split-input-button__button
  flex 0 0 auto
  display inline-block
  label-2()
  white-space nowrap
  text-transform uppercase
  height $splitButtonHeight
  box-sizing border-box
  padding 0 15px 3px
  margin 0
  border-radius 0 3px 3px 0
  background-color $sage
  border 1px solid $sage
  color $ricotta
  &:hover
    cursor pointer
