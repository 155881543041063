/* FIXME rename class */

.is-not-interactive
  .summary-item__photo
    width 80px

  .summary-item__details
    width calc(80% - 105px)

    +below(mobile-cutoff)
      width calc(80% - 110px)

  .summary-item__details,
  .summary-item__price
    height initial

  .summary-item__price
    width 50%

  /* These two styles are marked important so they can override the mobile cutoffs */
  .summary-item__price
    width 15% !important

  .summary-item__prices
    width 15% !important

    .summary-item__price
      width 100% !important

  .summary-item__info-control-spacer
    display none !important


  .summary-item__subscription
    .icon
      margin-right 8px
      margin-top -1px
    color $oyster
