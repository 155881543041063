.sign-up-success-modal__container
  padding 40px
  font-family $outfitFontFamily

.sign-up-success-modal__content
  position relative
  max-width 300px
  display flex
  flex-flow column
  align-items center

.sign-up-success-modal__close
  position absolute
  left 0
  cursor pointer

.sign-up-success-modal__logo
  margin-bottom 26px

.sign-up-success-modal__header
  max-width 225px
  text-align center
  font-size 2.08333rem
  font-weight 700
  line-height 30px
  margin-bottom 12px

.sign-up-success-modal__message
  text-align center
  font-size 1.08333rem
  font-weight 300
  line-height 125%
  margin-top 0
  margin-bottom 27px

.sign-up-success-modal__start_shopping
  width 100%
  height 46px
  padding 13.492px
  background-color $black
  color $white
  font-size 1rem
  letter-spacing 3px
  text-transform uppercase
  border 0
  cursor pointer
  line-height 100%
