.trending-items
  font-size 16px
  font-weight 300
  font-family 'Graphik'
  padding-bottom 16px
  .trending-item
    display flex
    align-items center
    height 40px
    column-gap 16px
    padding 0 1rem
    &:hover
      background-color lighten($pearl, 30%)
      cursor pointer
      box-sizing border-box
    .trending-icon
      color $black
    .trending-label
      @media screen and (min-width: 1023px)
        width 150px
      text-overflow ellipsis
      overflow hidden
      white-space nowrap