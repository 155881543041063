.unsubscribe-confirmation-page
  padding 65px 20px 0
  text-align center

.unsubscribe-confirmation-page__title
  display-1()
  margin-bottom 25px

.unsubscribe-confirmation-page__body
  form-1()
  margin-bottom 30px

.unsubscribe-confirmation-page__link
  link()

.unsubscribe-confirmation-page__cta
  button()
