.home-page__featured-content-carousel .personalized-home-page__module
  padding 0

.home-page__featured-content-module-container
  display flex
  flex-flow column nowrap
  align-items center

  +above(tablet-cutoff)
    flex-flow row nowrap

.home-page__featured-content-module-image
  max-height 450px
  min-height 300px
  width 100%
  object-fit cover
  cursor pointer

  +above(tablet-cutoff)
    width 67%

.home-page__featured-content-module-description
  display flex
  flex-flow column nowrap
  justify-content center
  align-items center
  margin 15px

  +above(tablet-cutoff)
    width 33%
    padding 0px

h2.home-page__featured-content-module-description-header
  font-size 2rem
  font-weight 700
  line-height 114%
  margin-bottom 0
  text-align center

.home-page__featured-content-module-description-text
  font-size 1.25rem
  font-weight 300
  line-height 120%
  text-align center

.home-page__featured-content-module-description-cta
  margin 0
