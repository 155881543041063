$modalZIndex = 3000
$modalZIndexClose = 3011
$modalZIndexBackground = 2500

.modal-open // Placed on body to disable scrolling
  overflow hidden

.modal-container
  display none

  z-index $modalZIndex
  position fixed
  top 0
  right 0
  left 0
  opacity 0
  transition all .15s ease-in-out
  height 100%

  &.in
    opacity: 1

  .modal-background
    z-index $modalZIndexBackground
    background-color rgba(0, 0, 0, 0.5)
    position fixed
    top 0
    right 0
    left 0
    height 1000%

  .modal-close
    z-index $modalZIndexClose
    position: absolute
    top: 19px
    right: 19px
    cursor: pointer
    font-size: 24px
    i
      font-weight bold
      color $oyster

  .modal
    background-color $eggshell
    border-radius 3px
    box-shadow 0 1px 2px 0 rgba(0,0,0,0.4)
    position relative
    z-index $modalZIndex
    width 440px
    margin 30px auto

  +below(mobile-cutoff)
    .modal
      width auto
      height 100%

.modal-frame
  width 100%

.modal-container.slim .modal
  width 300px
  padding 20px
  text-align center

.modal-container.slim .modal h1
  display-1()
