$rowHeight = 60px
$subRowHeight = 40px
$borderWidth = 1px
$gutterWidth = 16px

.card-main.active
  .content
    max-width desktop-cutoff
    margin 0 auto

    +between(tablet-cutoff, desktop-cutoff + $desktopMargin)
      margin 0 $desktopMargin

    +below(tablet-cutoff)
      //margin 0 $mobileMargin

.card-menu.active
  .mobile-menu.nav-menu
    transition all 0.7s cubic-bezier(0, 1, .22, 1)
    top 0

//  USER CONTROLS
.mobile-user-controls
  display none

.mobile-menu
  width 100%
  background $pearl
  margin-top $navHeight

  &.nav-menu
    bottom 0

    z-index $topNavZIndex
    position fixed
    will-change transform // move to separate layer to avoid interaction with page scrolling
    top 100%
    overflow-y scroll

    transition(all 0.3s linear)

  //Header with name and signout
  .mobile-user-controls
    height $navHeight
    display flex
    align-items center
    box-sizing border-box
    padding 0 15px
    background $pearl
    text-align left

    .login
      title-2()
      margin-top 5px
      color $sage

    .greetings
      title-2()
      margin-top 5px

      +below(mobile-cutoff)
        max-width 250px
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
        margin-top 6px

      &.masquerading
        color $tomato
      &.member 
        display flex
        align-items center
        & img
          height 28px
          width 28px
          margin-right 16px

    .available-credit
      body-1()
      margin-left 5px
      margin-top 5px
      +below(mobile-cutoff)
        line-height 1.3em
        margin-top 6px

  .section-header
    box-sizing border-box
    padding-left $gutter
    height $rowHeight
    padding-top 33px
    border-top $borderWidth solid $silt

    h3
      body-2()
      margin 0

  // Shopping Context
  .context
    background $ricotta

    .row
      title-2()
      display: block
      position relative
      height $rowHeight
      box-sizing border-box
      border-top $borderWidth solid $silt
      padding-top 16px
      padding-left 15px
      color $black
      text-align left
      &.link-weighted
        font-weight bold
      +below(mobile-cutoff)
        line-height 1.625em

  // Department
  .department
    background $ricotta

    .row
      form-2()
      display: block
      position relative
      height $rowHeight
      box-sizing border-box
      border-top $borderWidth solid $silt
      padding-top 22px
      padding-left 15px
      color $black
      text-align left

      .reveal
        display block
        position absolute
        right 0
        top 0
        width $rowHeight
        height $rowHeight
        box-sizing border-box
        border none
        background none
        margin 0
        outline none
        text-align center

        .icon
          font-size 12px
          transform rotate(180deg)

      button
        color $oyster

    .category-list
      overflow hidden
      max-height 0

    &.expanded
      .row
        border-bottom $borderWidth solid $silt

        .reveal .icon
          transform none

      .category-list
        max-height none

    // Subcategory
    .subcategory-row
      form-1()
      display block
      padding-top 15px
      height 44px
      margin 0 $gutterWidth 0 $gutterWidth
      box-sizing border-box
      border-top $borderWidth solid $pearl
      &:first-child
        border-top none
