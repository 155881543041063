.category-expansion
  top 100%
  width 274px

.category-expansion__content
  border-bottom-right-radius 3px
  border-bottom-left-radius 3px
  border-top 1px solid $pearl
  box-shadow 0 1px 2px 0 rgba(0,0,0,0.3)
  background-color white
  text-align left
  form-1()

.category-expansion__item
  display block
  padding 15px 31px 13px 31px

.category-expansion__item:hover
  text-decoration underline
  cursor: pointer

// Special styling for More

.desktop-header__category-more a.desktop-header__category-link
.sticky-desktop-header__category-more a.sticky-desktop-header__category-link
  pointer-events none

.remote.desktop-header__category-link
  cursor: pointer

.desktop-header__category-more .category-expansion__item
.sticky-desktop-header__category-more .category-expansion__item
  font-weight bold


// More Flyout responsive behavior

.responsive #nav
  +above(1101px)
    .desktop-header__category-more,
    .sticky-desktop-header__category-more
      display none

  +below(1100px)
    .desktop-header__include-in-more,
    .sticky-desktop-header__include-in-more
      display none
 
.sticky-desktop-header__inline-search
.desktop-header__inline-search
  position relative
  .search-suggestions
    margin-top: 2rem
    position absolute