
$webWideContentMaxWidth = 1280px

$webPageSideGutter = 20px

web-wide-content-column()
  box-sizing border-box
  max-width $webWideContentMaxWidth + 2 * $webPageSideGutter
  margin 0 auto
  padding-left $webPageSideGutter
  padding-right $webPageSideGutter
