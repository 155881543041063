.minimal-nav
  height 60px
  line-height 60px
  background $chestnut

  .logo
    margin-top 1px
    font-size 46px
    text-decoration none
    padding 0 15px 4px 15px
    color $silt
    position absolute

    i
      height 60px
    
    img
      height 51px
      width 68px
      margin-left -6px

  .masquerading
    margin-right $gutter

.minimal-nav__title
  title-4()
  margin-left 110px
  color $pearl
  font-size 2.25em
  display inline-block
  margin-top 13px

.b-corp-footer
  background $silt
  padding 40px
  text-align center

.b-corp-footer__content
  display inline-block

.b-corp-footer__seal
  width 73px
  height 116px
  margin-right 25px
  display inline-block
  vertical-align middle

.b-corp-footer__crow
  width 27rem
  text-align left
  display inline-block
  vertical-align middle

  &, p
    body-1()

+below(500px)
  .b-corp-footer__crow
    body-1()
    width 100%
    text-align center
    margin-top 1em

    p
      margin-bottom 0

  .b-corp-footer__seal
    margin-right 0

.minimal-footer
  text-align center
  position absolute
  left 0
  bottom 0
  width 100%

.minimal-footer__container
  line-height 2em
  background $chestnut
  color $ricotta
  padding 15px

.minimal-footer__note
  text-transform uppercase
  +above(600px)
    padding-right 20px

.minimal-footer__line-break
  display none
  +below(600px)
    display block

.minimal-footer__email
.minimal-footer__phone
  display inline-block
  padding-left 28px
  background-size 20px
  background-repeat no-repeat
  background-position left center

.minimal-footer__email
  background-image url('/img/nettle/icons/chanterelle/envelope.png?auto=format')
  +above(600px)
    padding-right 20px

.minimal-footer__phone
  background-image url('/img/nettle/icons/chanterelle/telephone.png?auto=format')
