$after-checkout-modal-padding = 20px

.send-referrals-page
  background-image url('/img/web/send_referrals/background.jpg?auto=format')
  background-size cover
  height 961px
  width 100%
  padding-top 95px

  +below(mobile-cutoff)
    padding-top 0

.after-checkout-recommended-products
  padding: $after-checkout-modal-padding