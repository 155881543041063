$navBorderColor = $oyster
$topNavZIndex = 4 // Unfortunately tied to global menu view
$navWidth = 30px

.top-nav-small
  top 0
  left 0
  right 0
  z-index $topNavZIndex
  background-color $oyster
  height $navHeight
  line-height 1
  display flex
  padding 0 $gutter
  justify-content space-between
  .nav-bar
    position relative
    height $navHeight
    box-sizing border-box
    background-color $oyster
    border-bottom 1px solid $navBorderColor
    -webkit-tap-highlight-color rgba(0,0,0,0) //hide iOS touch state

  &.menu-open
    background-color transparent
    border-color transparent

    .basket, .menu
      border-color transparent

  .logo
    font-size 78px
    color $chestnut
    i
      height $navHeight

  .info
    display table
    height $navHeight

  .items-count
    position absolute
    left 0
    right 0

  #search .icon, .basket i.icon, .items-count
    text-align center
    text-decoration none
    line-height 1em

  #search .icon, .basket i.icon, .menu
    width $navWidth
    position relative
    color $chestnut

  .top-nav__basket-image
    position relative
    width 32px
    top 12px
    left 14px
    position relative

  #search .icon
    top 20px
    font-size 21px

  .basket
    text-align center

    .items-count
      top 5px
      color white
      body-2()

    .icon
      top 17px
      width 30px
      border none
      margin 0 auto
      font-size 26px
      color $dice

  .menu
    cursor pointer
    font-size 22px
    line-height 1em
    display flex
    align-items center
    justify-content center
    text-align center

.mobile-search-dropdown
  top $navHeight
  z-index 1
  width 100%

.mobile-nav
  top 0
  position sticky
  z-index 2
  max-height 100vh
  background-color white
  &.search-open
    overflow-y scroll
  .mobile-nav__header
    top 0
    position sticky
    z-index 2
  .search-box
    box-sizing border-box
    box-shadow 0px 1px 1px 0px rgba(0,0,0,0.30)
    display flex
    .mobile-search__cancel-button
      color $soil
      font-size 1.334rem
      margin-left 8px
      border none
      background none
    .search-form-mobile
      width 100%
  .mobile-nav__buttons
    display flex


// stylings for search

placeholder()
  &::-webkit-input-placeholder
    {block}
  &:-moz-placeholder
    {block}
  &::-moz-placeholder
    {block}
  &:-ms-input-placeholder
    {block}

.mobile-search-box
  form input[type=text]
    font-family $bodyFontFamily

    +placeholder()
      font-family $bodyFontFamily

.search-box.mobile-search-box
  label
    display none

  +above(tablet-cutoff)
    display none

  background white
  padding 10px $gutter
  $fontSize = 14px
  $inputPadding = 12px

  form input[type=text], .search-action, .search-cancel
    padding $inputPadding
    line-height $fontSize
    font-size $fontSize
    margin 0

  form .search-action, .search-cancel
    $xOffset = 45px
    $width = 16px
    display inline-block
    width $width
    margin-left - $xOffset
    margin-right $xOffset - 2*$inputPadding - $width
    margin-bottom - $fontSize - 2*$inputPadding
    text-align center
    text-decoration none

    i
      vertical-align -2px
      color $oyster
      line-height $fontSize

  form input[type=text]
    border 1px solid $pearl
    box-shadow none
    box-sizing border-box
    width 100%
    border-radius(4px)

    +placeholder()
      font-style normal
      font-size 1em
      color $oyster

.top-nav-small
  background-color white
  &, #search .icon
    color $soil
  .logo
    margin-top 8px
    font-size 40px
    color $soil

  .basket i.icon
    color $dice
