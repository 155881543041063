@import '../jujube/styleguide/responsive_rules.styl'

+below(mobile-cutoff)
  .show-for-tablet-only,
  .show-for-tablet-up
    display: none

+above(mobile-cutoff + 1px)
  .show-for-mobile-only
    display: none

+below(tablet-cutoff)
  .show-for-desktop-only
    display: none

+above(tablet-cutoff + 1px)
  .show-for-tablet-only
    display: none
