.promo-code-redeemed-banner__main
  position relative
  background $black
  padding: 16px
  text-align center
  color white

.promo-code-redeemed-banner__title
  padding-bottom 4px
  form-2()
  +above(mobile-cutoff)
    display inline-block

.promo-code-redeemed-banner__description
  form-1()
  +above(mobile-cutoff)
    display inline-block
    padding-left 5px

.promo-code-redeemed-banner__close_button
  position absolute
  top 15px
  right 15px
  font-size 18px
  color $oyster
  cursor pointer
