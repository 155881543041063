$MobileLocalNavigationTitleHeight = 49px
$MobileLocalNavigationZIndex = $topNavZIndex - 2 // tied to top nav & global menu view

.mobile-local-navigation__title
  background white
  overflow hidden
  form-1()
  height $MobileLocalNavigationTitleHeight
  line-height $MobileLocalNavigationTitleHeight
  padding 0 15px
  border-bottom solid 1px $silt
  border-top solid 1px $silt

.mobile-local-navigation__category-title
.mobile-local-navigation__subcategory-title
  .icon
    padding 0 5px 0 0
    margin -5px 0 0 0
    font-size 10px
    transform rotate(180deg)

  &.is-open .icon
    transform none
    padding 0 0 0 5px
    margin 0

.mobile-local-navigation__subcategory-title
  margin-left 25px

.mobile-local-navigation__category-title
  position relative
  padding-right 5px

  &:before
  &:after
    left 100%
    top 50%
    border solid transparent
    content " "
    height 0
    width 0
    position absolute
    pointer-events none

  &:before
    border-color transparent
    border-left-color $silt
    border-width 25px
    border-left-width 15px
    margin-top -25px

  &:after
    border-color rgba(136, 183, 213, 0)
    border-left-color white
    border-width 24px
    border-left-width 14px
    margin-top  -24px


.mobile-local-navigation
  width 100%
  z-index $MobileLocalNavigationZIndex
  position sticky
  +above(tablet-cutoff)
    display none

.mobile-local-navigation__subcategory-flyout
.mobile-local-navigation__category-flyout
  overlay-box()
  margin 0 12px
  margin-top 16px
  position absolute
  width calc(100vw - 24px)

  &:before
    overlay-box-triangle()

.mobile-local-navigation__subcategory-flyout:before
  left 50%

.mobile-local-navigation__category-flyout:before
  left 50px

.mobile-local-navigation__flyout-overlay
  position fixed
  top 109px
  bottom 0
  left 0
  right 0
  z-index -1
  background black
  opacity 0.5

.mobile-local-navigation__flyout-content
  max-height calc(100vh - 200px)
  overflow-y auto
  position relative
  z-index 1

.mobile-local-navigation__flyout-row
  form-1()
  margin 0 15px
  line-height 43px
  display block

  &:last-child
    border-bottom none
