
.credit-card-form__credit-card-info
  color $oyster
  form-1()

.credit-card-form__credit-card-change
  link()

.credit-card-form__secure
  padding-top 1em
  color $oyster
  body-1()

.credit-card-form__icon-lock
  position relative
  bottom 2px

.credit-card-form__placeholder
  color $oyster
  font-size 1.2em
  font-family sans-serif
