.how-it-works-page__content
  web-wide-content-column()
  padding-top 30px
  padding-bottom 30px
  +strictly-below(800px)
    padding-top 30px

.how-it-works-page__content-inner
  position relative

$howItWorksPageNavWidth = 170px // includes padding

.how-it-works-page__column-content
  box-sizing border-box
  width 100%
  // Leave fixed space for nav
  padding-left $aboutPageNavWidth
  +strictly-below(tablet-cutoff)
    padding-left 0

.how-it-works-page__column-nav
  position absolute
  top 0
  left 0
  display inline-block
  vertical-align top
  box-sizing border-box
  +at-and-above(tablet-cutoff)
    width $aboutPageNavWidth
    padding-right 50px
  +strictly-below(tablet-cutoff)
    width 100%
    display block
    margin-bottom 40px

.how-it-works-page__nav-list
  list-style none
  margin 0
  padding 0

.how-it-works-page__nav-element
  form-1()
  margin-bottom 10px

.how-it-works-page__nav-element-current
  form-2()
  margin-top -2px // compensate for bold font offset vertical centering
  margin-bottom 12px

.how-it-works__sidebar
  width 170px
  float left
  min-height 1px

.how-it-works-page__section-anchor
  position relative
  top -80px
