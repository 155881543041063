@import '../css_helpers.styl'
@import './_states.styl'
@import './_visibility.styl'
@import '../jujube/styleguide/responsive_rules.styl'
@import './_base.styl'

// styleguide
@import '../jujube/styleguide/font_families.styl'
@import '../jujube/styleguide/nettle/index.styl'

@import './_layout.styl'
@import './_icons.styl'

@import './overlay_box.styl'
@import './popover.styl'
@import './dropdown_menu.styl'

// web pages first to get components
@import '../../index.styl'

// nettle pages
@import '../market/index.styl'

// Page bootstrap lifecycle
.requires-page-js
  visibility hidden

html.page-js .requires-page-js
  visibility visible

.after-page-js
  display none

html.page-js .after-page-js
  display block

.requires-all-js
  visibility hidden

html.all-js .requires-all-js
  visibility visible

.after-all-js
  display none

html.all-js .after-all-js
  display block

.btn
  cursor pointer

.btn[disabled]
  opacity 0.5
  color rgba(255, 255, 255, 0.3)
