.search-sidebar__subcategory-link
  cursor pointer

.search-sidebar__subcategory-text
  body-1()
  line-height 1.1em
  padding-top 14px

.search-sidebar__subcategory-text-category
  display inline-block

.search-sidebar__subcategory-text-quantity
  color $oyster
  display inline-block
  margin-left 7px

