
/* Variables */

// from jujube
$zindexNavbar = 2010

// If you change any of these values, also update mobile header_viewaffee:
$miniNavHeight = 60px
$logoHeight = 100px
$headerPadding = 20px
$fullHeaderHeight = 109px

/* Independent */

@keyframes wiggle
  0% {
    transform rotate(0deg)
  }
  100% {
    transform rotate(20deg)
  }

@keyframes circle {
  0% { transform: translateX(-10px) translateY(-20px)   scaleX( 0.5) scaleY( 0.5)}
  20% { transform: translateX(40px) translateY(-15px)    scaleX( 0.5) scaleY( 0.5) }
  40% { transform: translateX(100px) translateY(-10px)  scaleX( 0.5) scaleY( 0.5) }
  60% { transform: translateX(80px) translateY(-30px)   scaleX( 0.5) scaleY( 0.5) }
  60.1% { transform: translateX(80px) translateY(-31px)   scaleX(-0.5) scaleY( 0.5) }
  80% { transform: translateX(5px) translateY(-35px)    scaleX(-0.5) scaleY( 0.5) }
  99.9% { transform: translateX(-10px) translateY(-10px) scaleX(-0.5) scaleY( 0.5) }
  100% { transform: translateX(-10px) translateY(0px) scaleX(0.5) scaleY( 0.5) }
}

header-link()
  color $oyster
  text-decoration none
  &.active
    color $sage
  &:hover, &.active:hover
    color $laurel
/* Desktop Header */

$betweenTopRowItems = 26px
$betweenItems = 40px
$aboveFirstRow = 6px
$aboveSecondRow = 20px
$iconRightMargin = 6px
$categoryUnderlineThickness = 4px
$desktopHeaderLogoWidth = 76px
$desktopHeaderLogoHeight = 50px
$desktopHeaderLogoRightMargin = 24px
$desktopHeaderLogoLeftMargin = 20px - 5px // because the roundness makes it look shifted a little to the right

.desktop-header
  body-copy-small-semibold()
  box-shadow(0px 1px 2px 0px rgba(156, 151, 149,0.3))

.desktop-header__logo_container
  margin 0 auto
  max-width desktop-cutoff
  position relative
  z-index $zindexNavbar + 2

  +below(desktop-cutoff + $desktopMargin)
    margin-left $desktopHeaderLogoLeftMargin

.desktop-header__logo
  float left
  margin-top 9px
  margin-right $desktopHeaderLogoRightMargin
  width $desktopHeaderLogoWidth
  height $desktopHeaderLogoHeight
  color transparent

  background-image url('/img/svg/icons/logo_black.svg?auto=format')
  background-repeat no-repeat
  background-position center

  &:hover
    animation wiggle 1s linear infinite alternate

.desktop-header__bottom-row
  box-shadow(0px 1px 2px 0px rgba(156, 151, 149,0.3))

.desktop-header__top-row
  position relative
  z-index $zindexNavbar + 1

.desktop-header__bottom-row
  position relative
  z-index $zindexNavbar

.desktop-header, .sticky-desktop-header
  // This is mostly to override stuff jujube does with these tags
  li
    display inline-block
    line-height inherit

.desktop-header,.sticky-desktop-header
  ul
    padding 0
    display inline
    white-space nowrap

.desktop-header__top-row
  background white

.desktop-header__bottom-row
  background white

.desktop-header__search-container
  flex-grow 1
  -ms-flex 1
  font-size 20px
  margin-top 24px
  vertical-align top
  text-align center
  cursor pointer

.desktop-header__standalone-text-container
  flex-grow 1
  -ms-flex 1
  margin-top 28px
  vertical-align top
  text-align center
  body-copy-small-semibold()

.desktop-header__icon-link-icon
  font-size 18px
  margin -5px $iconRightMargin 0 0
  color $black
  position relative

.desktop-header__subscriptions-icon-checkmark
  top 7px

.desktop-header__icon-link:hover .icon
  color $sage

  &.icon__inner-content
    color white

.desktop-header__icon-link .icon.icon-heart
  margin-top -1px

.desktop-header__account-menu .dropdown-menu__content
  &:before
    left 50%

.desktop-header__shop-for-day-menu .dropdown-menu__content
  &:before
    left 50%

.desktop-header__shop-for-day-menu .button_sold_out
  color $dice

.desktop-header__inline-search
  padding 14px 0 15px 0
  margin-left -6px
  margin-right 4%

.desktop-header__inline-search-input
  body-3()
  border-radius 3px 0 0 3px
  border-right none
  &:focus
    border-right none
  display inline-block
  padding 0 15px
  height 42px
  width 75%
  width calc(100% - 185px)

.desktop-header__inline-search-right-pill
  background-color $sage
  cursor pointer
  border-radius 0 3px 3px 0
  display inline-block
  padding 10px 30px 0 30px
  vertical-align top
  height 34px

.desktop-header__inline-search-icon
  color #fff
  font-size 20px

.desktop-header__inline-search-cancel
  form-1()
  display inline-block
  margin-left 16px
  margin-top 14px
  cursor pointer
  vertical-align top

.desktop-header__inline-transition-appear {
  opacity 0.01
}

.desktop-header__inline-transition-appear-active {
  opacity 1
  transition opacity .15s ease-in
}

/* Sticky Desktop Header */

.sticky-desktop-header
  position fixed
  will-change transform // set position fixed into separate layer to maintain good scrolling of the long category pages underneath
  top (0 - $miniNavHeight)
  height $miniNavHeight
  width 100%
  background-color white
  z-index $zindexNavbar - 1
  box-shadow(0px 1px 2px 0px rgba(156, 151, 149,0.3))

.sticky-desktop-header__logo
  position absolute
  top 13px
  img
    display inline-block
    width 48px
    height 38px

.sticky-desktop-header__content
  height 100%

  +below(desktop-cutoff)
    background-position 14px -19px

.sticky-desktop-header ul
  display inline
  padding-left 20px

.sticky-desktop-header__category-link-interior
  body-copy-small-semibold()

  span
    letter-spacing 0

.sticky-desktop-header li
  display inline-block

.mini-nav .sticky-desktop-header
  transform-transition(100ms)
  transform(translateY($miniNavHeight))

.desktop-header__basket-container.sticky-desktop-header__basket_container
  margin-top 11px

.sticky-desktop-header__basket
  box-sizing content-box
  width 24px
  padding 8px 8px 7px 9px
  margin 0 0 0 auto

.desktop-header__basket
  box-sizing content-box
  width 24px
  padding 8px 8px 7px 9px
  margin 0 0 0 auto

.sticky-desktop-header__inline-search
  padding 11px 0 15px 0
  margin-left 57px

.sticky-desktop-header__inline-search-input
  body-3()
  border-radius 3px 0 0 3px
  border-right none
  &:focus
    border-right none
  display inline-block
  padding 0 15px
  height 36px
  width 75%
  width calc(100% - 180px)

.sticky-desktop-header__inline-search-right-pill
  background-color $sage
  cursor pointer
  border-radius 0 3px 3px 0
  display inline-block
  vertical-align top
  padding 10px 30px 0px 30px
  height 28px

.sticky-desktop-header__inline-search-icon
  color #fff
  font-size 20px

.sticky-desktop-header__inline-search-cancel
  form-1()
  display inline-block
  margin-left 16px
  margin-top 12px
  cursor pointer
  vertical-align top


.sticky-desktop-header__inline-transition-appear {
  opacity 0.01
}

.sticky-desktop-header__inline-transition-appear-active {
  opacity 1
  transition opacity .15s ease-in
}


/* Links */

.desktop-header__link
  color $black
  display inline-block
  padding-top $aboveFirstRow + 1px
  padding-right $betweenTopRowItems * 0.5
  padding-left 5px
  @media only screen and (max-width: 1100px)
    padding-right: 8px

.js-shop-for-day-menu-button
  font-family Graphik

.desktop-header__get-ios
  padding-left ($betweenTopRowItems * 0.5) + 23
  background url('/img/nettle/promotions/ios/header-tease.png?auto=format') no-repeat 0 bottom
  margin-left 15px

.desktop-header__thanksgiving-preorder
  padding-left ($betweenTopRowItems * 0.5) + 70
  background-image url('/img/web/thanksgiving-preorder-turkey.gif?auto=format')
  background-repeat no-repeat
  background-size auto ($bannerHeight - 4px)
  margin-left 35px

.desktop-header__refer-a-friend
  padding-left 4px

.desktop-header__gift-card-icon
  padding-left 32px
  font-size 2.1rem
  color $dice
  margin-bottom 5px
  @media only screen and (max-width: 1100px)
    padding-left 8px

.desktop-header__gift-card
  padding-left 4px

.desktop-header__leftmost-link
  /* The first one needs to line up with the department links: */
  padding-left $betweenItems * 0.5

  +between(800px, 900px)
    padding-left $betweenItems * 0.35

.desktop-header__category, .sticky-desktop-header__category
  display inline-block
  min-height $miniNavHeight
  box-sizing border-box
  flex-grow 1
  -ms-flex 1
  text-align center
  padding-top $aboveSecondRow + 2px
  padding-bottom $aboveSecondRow + 3px

  &.right-border
    position relative
    &:after
      content ""
      background $silt
      position absolute
      bottom 0
      right 50%
      height 50%
      width 1px

.desktop-header__category--short
  padding-top $aboveSecondRow + 8px
  padding-bottom $aboveSecondRow + 9px

.desktop-header__category:after
  top 18px

.sticky-desktop-header__category:after
  top 15px

.desktop-header__category-seasonal-recipes
  margin-left -6px

.desktop-header__row-content
  display flex
  justify-content space-between

.desktop-header__bottom-row .desktop-header__row-content
  display flex
  display -ms-flexbox
  width: 100%

.desktop-header__row-content .js-how-it-works-links
  flex-basis 60%
  display flex

.desktop-header__help-center
  @media only screen and (max-width: 1100px)
    padding-right 8px

.desktop-header__favorite
  padding-top 7px
  padding-bottom 5px
  padding-left 5px
  padding-right 5px

.desktop-header__favorite-heart
    margin-right 3px
    margin-top -3px
    width 14px
    height 12px
    fill $tomato

.desktop-header__categories-container
  flex-grow 9
  -ms-flex 9

.desktop-header-row__categories
  display -ms-flexbox
  display flex

.desktop-header__category-divider
  margin 5px 0

/* Non-sticky only */

.desktop-header__category-link-interior
  text-align center

.desktop-header__standalone-text-container.current,
.desktop-header__category.current
  padding-bottom 0
  border-bottom $categoryUnderlineThickness solid $dice

.desktop-header__category-link-interior__primary-heading
  body-copy-small-semibold()

.desktop-header__category-link-interior__sub-heading
  body-1()
  line-height 1em

/* Sticky only */

$aboveItems = 7px

.sticky-desktop-header__category.current
  border-bottom $categoryUnderlineThickness solid $dice
  padding-bottom $aboveSecondRow - 2px

.sticky-desktop-header__category
  padding-top $headerPadding
  padding-bottom $headerPadding - 1px

.sticky-desktop-header__nav
  display flex
  display -ms-flexbox
  padding-left 55px

.sticky-desktop-header__categories-container
  flex-grow 9
  -ms-flex 9

.sticky-desktop-header__departments
  display -ms-flexbox
  display flex

.sticky-desktop-header__search-container
  display inline-block
  flex-grow 1
  -ms-flex 1
  font-size 20px
  margin-top 21px
  font-size 20px
  text-align center
  line-height 1
  cursor pointer

/* Basket Button */

$basketIconWidth = 23px

.desktop-header__basket-container
  display inline-block
  margin-top 17px

.basket-button
  background $dice
  color white
  border-radius 50%
  label-2()
  display block

  &:hover
    background $mint
    color $black
    text-decoration none
    .basket-button__item-count
      color $mint

  &:visited
    color white

  i, div
    display inline-block
    &:hover
      text-decoration none
      .basket-button__item-count
        color $mint

.basket-button__icon
  transition(background 100ms linear)
  margin-top -3px
  width $basketIconWidth
  color white
  font-size 22px
  position relative

.basket-button__item-count
  top 5px
  font-family $semiboldFontFamily
  -webkit-font-smoothing antialiased
  letter-spacing 0.3px
  color $dice

  &.triple-digits
    font-size 40%
    top 7px

.basket-button__label
  margin-left 6px
  color white

.basket-button__expand
  padding-left 6px
  color white
  font-size 18px
  &:visited
    color white

#nav
  position relative
  font-family $bodyFontFamily
  font-size 12px
  color $black

.groceries-expansion
  a, a:link, a:visited
    color $black
    text-decoration none

  a:hover, a:active
    color $sage
    text-decoration none

#nav .col
  display inline-block
  vertical-align top
  margin-right 30px
  width 200px
  max-width 17%
  white-space normal

#nav h2
  label-2()
  font-family $bodyFontFamily
  font-weight bold

#nav .spacer
  height $fullHeaderHeight




/* Layout */

#nav .expansion .col ul
  li
    display block

#nav .expansion .col
  ul li a, h2 a
    display block   // make link fill whole row

.expansion
  position absolute
  width 100%
  z-index $zindexNavbar + 1
  top $fullHeaderHeight
  display none
  // transform translateY(-120%)

  &.open
    display block
    // transform translateY(0%)

.expansion-content
  overflow hidden
  max-width desktop-cutoff
  margin 0 auto


/* Style */

.expansion.open .expansion-content
  box-shadow(0px 1px 1px 0px rgba(0,0,0,0.30))


/* User Controls */

.account-menu
  padding 0 4px 0 0px

  .flyout-control .icon-chevron
    color $oyster
    transform rotate(180deg)
    margin-top -4px
    margin-left 2px

  &.active .flyout-control .icon-chevron
    transform none
    margin-top 0px

li.desktop-header__shop-for-day-menu-static
  cursor default

/* Custom hiders */
  .marketplace-hider
    position fixed
    left 0
    background rgba(0, 0, 0, .5)
    width 100%
    height 100vh

  .marketplace-hider-lg
    top 130px

  .marketplace-hider-sm
    top 59px

.dropdown-menu__membership-badge
  height 28px
  margin-right 8px
  margin-bottom -10px

.account_logo_container
  display flex
  flex-direction column
  padding-left 12px

.account_logo
  height 22px
  width 40px

.account_member_label
  font-size 8px
  font-family Graphik
  padding-left 4px
