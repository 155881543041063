@import '../separator/index.styl'

.social-sign-content
  margin-bottom 30px
  display flex
  flex-direction column
  width 100%

.social-sign-in__buttons
  margin 0 auto
  width 300px

  +below(mobile-cutoff)
    width 100%

  button.button
    height 46px
    width 100%
    display grid
    grid-template-columns 20px 1fr
    align-items center
    justify-content center
    border-radius 26px
    padding 0 2rem
    margin 0 0 0.7rem 0
    background #F6F6F6
    font-family $Graphik
    font-size 16px
    font-weight 600
    line-height 2.5
    letter-spacing 0.05px
    text-transform none
    cursor pointer
    color $black

    +below(mobile-cutoff)
      font-size 14px

    &:hover, &:active
      background #EDEDED
    
    span
      text-align center

    &.is-working, &.is-not-ready
      cursor default

    &.disabled
      cursor not-allowed
