.product-detail-view__unavailable-apology
  .button
    +below(tablet-cutoff)
      font-size 0.86rem
    
  .button.dark
    background-color $chestnut
    color $ricotta

    &:hover
      background-color $oyster

    &:active
      background-color $chestnut
