.attended-delivery-warning
  text-align center
  padding 25px
  width 300px
  +below(mobile-cutoff)
    padding 15px
    width 240px

.attended-delivery-warning .icon-alert-warning
  color $tangerine
  font-size 40px
  margin-bottom 15px

.attended-delivery-warning__header
  display-1()

.attended-delivery-warning hr
  border-bottom-color $laurel

.attended-delivery-warning__content
  body-1()
  margin-bottom 7px
  margin-top -5px

.attended-delivery-warning__actions button
  width calc(50% - 10px)

.attended-delivery-warning__actions button:first-child
  margin-right 15px

