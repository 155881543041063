totals-form-1()
  form-1()
  +below(mobile-cutoff)
    font-size 1rem

totals-form-2()
  form-2()
  +below(mobile-cutoff)
    font-size 1rem

.totals
  margin-bottom 20px
  width 100%

.totals__total-row
  display table-row
  width 100%
  title-2()
  line-height 1.8em
  +below(mobile-cutoff)
    line-height 1.8em

.totals__total-row__totalDue
  title-3()

  .totals__total-label
    totals-form-2()

  +below(mobile-cutoff)
    line-height 1.8em

.totals__total-label
  totals-form-1()
  width 8rem
  position relative

.totals__total-label,
.totals__total-formatted-amount,
.totals__total-secondary-label
  display table-cell

.totals__total-label
  width 100%
  text-align right
  padding-right 34px
  white-space nowrap

.totals__total-label-strikethrough
   text-decoration: line-through
   
.totals__total-formatted-amount
  width 1rem
  text-align right
  white-space nowrap
  
.totals__delivery-fee
  color #3F9E99
  font-weight 500

.totals__membership-fee
  color #3858F8

.totals__delivery-fee-amount
  width 1rem
  text-decoration: line-through
  text-align right
  margin-right: 8px
  white-space nowrap

.totals__total-secondary-label
  totals-form-1()
  text-transform uppercase
  text-align right
  padding-right 2rem
  width 100%

.totals__total-info
  display inline
  position: absolute

.totals__total-info-link
  link()
  text-align center

  img
    margin-left 5px
    margin-top -3px

.totals__total-info-modal-button
  margin 23px auto 0 auto
  width 117px
  height 39px

.totals__total-info-modal-content
  padding 55px 55px 29px 55px
  white-space pre-wrap
  line-height 21px

  +above(mobile-cutoff)
    line-height 34px

  +above(mobile-cutoff)
    max-width: 650px

  a
    link()

.totals__total-info-modal-header
  display-2()
  font-size 2.375rem
  margin-bottom 23px

.totals__total-info-modal-body
  margin 25px 0 25px 0
  .totals__total-body-text
    font-size 1.25rem
    
.totals__total-info-modal-button-container
  text-align center

.totals__total-alert
  font-size 1rem
  color orange
  margin-right 10px

  +below(mobile-cutoff)
    font-size 0.79rem
    margin-right 5px
