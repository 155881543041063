.set-password-page.narrow-page
  margin-bottom 70px

.set-password-page hr
  margin-bottom $gutter

.set-password-page .form-submit
.set-password-page__confirm
  display inline-block
  float right
  text-align center
