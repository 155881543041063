@import './components/product_description/index.styl'

$webContentMaxWidth = 1024px
	
.recipe 
	display flex
	justify-content center

.recipe__content
	display flex
	flex-direction column
	align-items center
	text-align center
	max-width $webContentMaxWidth
	padding 32px 0
	body-recipe()
	color #000

.recipe__logo
	width 102px

.recipe__title
	display-recipe()
	margin 40px 0 20px

.recipe__subtitle
	margin-bottom 40px

.recipe__media
	margin-bottom 32px
	border-radius 8px
	width 70%
	background-position center center
	background-size cover
	img
		border-radius 8px

.recipe__preparation-time
	body-recipe()

.recipe__action
	width 100%
	display flex
	justify-content: center
	align-items: center
	flex-direction: column
	margin 20px 0 24px
	+above(mobile-cutoff)
		flex-direction: row
	button
		refresh-button()
		max-width 340px
		width 100%
		margin 8px
		display block
		background $brownsoil
		text-transform initial
		font-weight 700
	a:hover
		text-decoration none

.recipe__help-center
	body-recipe()
	text-align center

.recipe__help-center__message
	body-recipe()
	a
		form-link()
		font-size 1.33rem
		font-weight 500

.recipe__banner
	display flex
	justify-content center
	flex-direction column
	margin 20px 0
	text-align center

.recipe__banner__title
	body-recipe()
	font-weight 700

.recipe__banner__mobile-app
	margin 8px 0 24px
	display flex
	justify-content center
	img 
		width 140px
		height 40px

.recipe__banner__mobile-app__ios
	margin-right 8px

.recipe__footer
	display flex
	flex-direction column
	align-items center
	.recipe__footer__text
		body-copy-small()
		margin-bottom 20px
	.recipe__footer__logo
		width 40px

.recipe-gutter
	+below(desktop-cutoff)
		padding 0 12px