.reorder-page
  display flex
  flex-direction row
  justify-content space-between
  padding 0 40px
  +below(desktop-cutoff)
    padding 0 20px
  +below(mobile-cutoff)
    flex-wrap wrap
    padding 0 8px

.reorder-page__container
  display flex
  flex-direction column
  flex-basis 50%
  padding 0 16px
  +below(mobile-cutoff)
    flex-basis 100%

.reorder-page__header
  text-align center
  h3
    display-2()
    margin-top 32px

.reorder-page__grid__header
  text-align center
  p
    title-2()
    margin-bottom 18px

.reorder-page__grid
  margin-bottom 50px
  display flex
  flex-wrap wrap
  justify-content center

.reorder-page .grid-item
  margin 0 20px 20px 0
  display inline-block
  width 100%
  line-height 0

  &:nth-child(3n)
    margin-right 20px

  +at-and-up-to(mobile-cutoff, tablet-cutoff)
    margin 0 8px 8px 0
    &:nth-child(3n)
      margin-right 8px

  +above(desktop-cutoff)
    max-width 260px

  +at-and-up-to(tablet-cutoff, desktop-cutoff)
    max-width 220px

  +below(mobile-cutoff)
    display block
    margin 0
    &:last-child .product-tile__product-info
      border-bottom 0 none

.reorder-page__empty
  body-1()
  margin-top 40px
  min-height 300px
  text-align center
  color $black

.reorder-page__empty_text
  margin-top 20px
