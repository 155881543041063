
//form style
.sign-up-form
  margin auto

//input box style css
.sign-up-form
  .sign-up-form__first-name-column,
  .sign-up-form__last-name-column
  .sign-up-form__email-row,
  .sign-up-form__phone-row
    margin-bottom 22px
  .sign-up-form__password-row
    margin-bottom 32px
  .sign-up-form__first-name-column,
  .sign-up-form__last-name-column
  .sign-up-form__email-row,
  .sign-up-form__phone-row
  .sign-up-form__password-row
    overflow hidden
    .form-control
      height 40px
      border-width 0 0 1px 0
      border-radius 0px
      font-family "Graphik", Helvetica, Arial, sans-serif
      font-style normal
      font-weight normal
      font-size 16px
      line-height 40px
      padding 8px 0
      width 100%
      box-sizing border-box
      border-bottom-color $silt
      &:hover,
      &:focus
        border-bottom-color $silt
      &.valid
        border-bottom-color $cornflowerBlue
        &:hover,
        &:focus
          border-bottom-color $cornflowerBlue
      &.invalid
        border-bottom-color $error
        color: $error
        &:hover,
        &:focus
          border-bottom-color $error


//style for sign-up button
.sign-up-form
  .sign-up-page__submit
    refresh-button()
    display block
    margin auto 0 22px 0
    width 100%
    background $brownsoil
    text-transform initial
    color $white
    &:hover,
    &:focus
      background $cornflowerBlue
    &:disabled
      background $oyster
      cursor not-allowed

//icon related css for password form field
.sign-up-form
  .sign-up-form__password-row
    position relative
    .icon
      color $black
      position absolute
      right 13px
      top calc(50% - 0.625rem)
      line-height normal
      font-size 16px
  .icon-eye-open,
  .icon-eye-open-slash
    cursor pointer

.sign-up-page__errors .link
  link()

.sign-up-form__phone-disclaimer
  a
    color $laurel 
