.printable-gift-card
  raised-tile()
  max-width 650px
  margin 0 auto
  padding 45px 45px 20px 45px
  text-align left

.printable-gift-card__to-and-from-row
  color black
  padding-bottom 20px
  border-bottom 1px solid $silt
  h3
    margin 0 0 10px

.printable-gift-card__to-name
.printable-gift-card__from-name
  body-3()
  margin-right 40px

.printable-gift-card__message
  body-3()
  margin 0
  color $oyster
  font-style italic

.printable-gift-card__card-row
  border-bottom 1px solid $silt
  margin-bottom 20px
  padding 30px 0 0
  text-align center

.printable-gift-card__card-left-col
  margin-bottom: 30px
  vertical-align top
  display inline-block
  margin-right: 20px
  img
    width 280px

.printable-gift-card__card-right-col
  margin-bottom: 30px
  vertical-align top
  display inline-block
  text-align left
  max-width 350px
  @media print
    max-width 300px


.printable-gift-card__amount
  title-5()
  font-size 40px
  margin 5px 0 10px

.printable-gift-card__redeem-instructions
  form-1()

.printable-gift-card__code
  title-3()
  background $pearl
  text-align center
  padding 6px
  margin 10px 0

.printable-gift-card__redeem-later
  form-1()
  color $oyster


.printable-gift-card__call-us
  form-1()
