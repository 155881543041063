//
// NOTE: be smart when you add vendor-prefixed rules here -- consult http://caniuse.com/
//
appearance()
  -khtml-appearance arguments
  -webkit-appearance arguments
  -moz-appearance arguments

border-radius()
  -webkit-border-radius arguments
  border-radius arguments

box-shadow()
  -webkit-box-shadow arguments
  box-shadow arguments

box-sizing()
  -webkit-box-sizing arguments
  -moz-box-sizing arguments
  box-sizing arguments

transform() // Caution: performs VERY poorly on iOS.
  -moz-transform arguments
  -webkit-transform arguments
  -ms-transform arguments
  -o-transform arguments
  transform arguments

transform-origin() // Caution: performs VERY poorly on iOS.
  -moz-transform-origin arguments
  -webkit-transform-origin arguments
  -ms-transform-origin arguments
  -o-transform-origin arguments
  transform-origin arguments

transition()
  -moz-transition arguments
  -webkit-transition arguments
  -o-transition arguments
  transition arguments

transform-transition()
  -moz-transition -moz-transform arguments
  -webkit-transition -webkit-transform arguments
  -o-transition -o-transform arguments
  transition transform arguments

opacity(n)
  opacity n
  filter unquote('alpha(opacity=' + round(n * 100) + ')')

clearfix()
  *zoom: 1
  &:before, &:after
    display table
    content ""
  &:after
    clear both
