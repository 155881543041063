// Product in the PDP
.product-availability-status
  display inline-block
  font-family $bodyFontFamily
  font-size 11px
  line-height 15px
  text-transform capitalize

  &.active .indicator
    background $availabilityGray

  &.next-foodhub-day .indicator,
  &.available .indicator
    background $availabilityGreen

  &.unavailable .indicator,
  &.soldout .indicator
    background $availabilityGray

  // Draw the dot
  .indicator
    margin-right 0.25em
    display inline-block
    width 9px
    height 9px
    border-radius 50%
    background $availabilityBrown

.product-listings-view .next-available-day
  white-space nowrap
  cursor default
  transition(all 0.1s)
  transition-properties(background opacity)

.product-availability-status.preorder
  &.available .indicator
    background $chestnut
