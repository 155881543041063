.how-it-works-page__how-it-works__header
  display-2()
  text-align center
  margin-bottom 20px

.how-it-works-page__subtitle
  margin-bottom 30px

.how-it-works-page__how-it-works__description
  title-5()
  font-size 2rem
  line-height 1.2em
  letter-spacing 0
  text-align center
  padding 0 60px 0 60px

  +below(ipad-width)
    padding 0 20px 0 20px

.how-it-works-page__how-it-works__steps
  padding-top 50px
  display flex
  align-content center

  +below(ipad-width)
    display block

.how-it-works-page__how-it-works__step
  box-sizing border-box
  margin 0 auto
  text-align center

.how-it-works-page__how-it-works__step__description
  max-width 215px
  margin-top 25px
  +below(ipad-width)
    margin 0 auto

.how-it-works-page__how-it-works__step__description__header
  title-3()
  margin-bottom 0
  line-height 20px
  font-size 18px

.how-it-works-page__how-it-works__step__description__text
  form-1()
  margin-top 15px
  line-height 20px
  font-size 15px

img.how-it-works-page__how-it-works__step__image-shop-the-market
    width 215px

img.how-it-works-page__how-it-works__step__image-pick-delivery-time
    width 215px

img.how-it-works-page__how-it-works__step__image-get-it-tomorrow
    width 215px
