@import '~slick-carousel/slick/slick.css'
@import '~slick-carousel/slick/slick-theme.css'

$personalizedHomePageModuleWidth = 1250px
$homepageMobileMargin = 8px

inverted-personalized-home-page-module()
  background-color white
  margin-top 30px
  margin-bottom 20px
  padding 20px $desktopMargin 55px

@import './components/home_screen/index.styl'

@import './components/interests_modal/index.styl'
@import './components/featured_categories_module/index.styl'
@import './components/featured_content_module/index.styl'
@import './components/featured_content_carousel/index.styl'
@import './components/featured_producers_module/index.styl'
@import './components/product_collection_module/index.styl'
