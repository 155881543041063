.basket-view__add-to-order-row
  clear both

  +below(single-column-cutoff)
    .checkout-buttons__button
      margin-top 15px

.basket-view__add-to-order-container
  margin-top -12px   /* So top of ADD NOW has same space between it and
                        the totals, even though the "ADD TO YOUR..." text
                        sticks out above */
  margin-left auto
  clear both
  overflow hidden
  text-align right
  +below(mobile-cutoff)
    text-align center

.basket-view__add-to-order-container .checkout-buttons__button.button
  display inline-block
  vertical-align bottom

.basket-view__add-to-order-container .error
  display none

.basket-view__add-to-order-container.has-error .error
  display block

.basket-view__upcoming-orders
  display inline-block
  text-align left
  margin-right 25px
  min-height 47px
  +below(single-column-cutoff)
    margin-right 0
    padding-right 6px
    display block

.basket-view__upcoming-orders
  select, .choice-select .filled-select
    width 100%

  +below(mobile-cutoff)
    select, .choice-select .filled-select
      width 90%
    .choice-select
      text-align center
      .filled-select
        text-align left
    label
      width 90%
      margin-left auto
      margin-right auto
