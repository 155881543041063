.basket-view__masquerade-overrides
  margin 20px 0

  .form-row
    margin 0
    padding 0

  label
    display inline-block
    float no

  .button-area
    float right
    height 24px
