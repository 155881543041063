@import './components/popular_items/index.styl'
@import './components/recent_searches/index.styl'
@import './components/search_as_you_type/index.styl'
@import './components/trending_items/index.styl'

.search-suggestions
  padding 2.5rem
  column-gap 1.334rem
  display grid
  background-color white
  grid-template-columns 1fr 3fr
  grid-template-areas "recent popular"\
                      "trending popular"
  h3
    font-family "Editor"
    font-weight 800
    font-size 1.334rem
    margin 0
  @media screen and (min-width: 1022px)
    border-radius 8px
    margin-top 1rem
  @media screen and (max-width: 1022px)
    width 100%
    min-height calc(100vh - 120px)
    display flex
    z-index 1
    padding $gutter
    position relative
    box-sizing border-box
    flex-direction column  
  .search-suggestions__trending-searches
    grid-area trending
  .search-suggestions__recent-searches
    grid-area recent
  .search-suggestions__popular-items
    grid-area popular
