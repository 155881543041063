.ge-separator
  margin 20px 0
  width 100%
  display flex
  flex-direction row
  align-items center

.ge-separator__line
  display flex
  border-top 1px solid $oyster
  flex 1

.ge-separator__content
  color $oyster
  padding 0 10px
  flex 1.2
