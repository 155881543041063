.ui-modal__personalized-home-page-interests
  background-color rgba(237,234,230, 0.8)
  padding-top $fullHeaderHeight
  height "calc(100vh - %s)" % $fullHeaderHeight
  z-index $topNavZIndex - 1 // display beneath desktop-header, mobile-nav & mobile-menu
  +below(tablet-cutoff)
    padding-top $navHeight
    height "calc(100vh - %s)" % $navHeight

.personalized-home-page-interests
  max-width 930px
  margin 0 9px
  padding 40px
  text-align center
  +below(mobile-cutoff)
    padding 15px 0

.personalized-home-page-interests__heading
  display-1()
  margin-bottom 30px
  +below(mobile-cutoff)
    font-size 1.4rem
    margin-bottom 15px

.personalized-home-page-interests__list
  max-width 650px
  margin 0 auto 30px
  padding 0
  +below(mobile-cutoff)
    margin-bottom 15px

.personalized-home-page-interests__list-item
  display inline-block
  border 1px solid $silt
  border-radius 100px
  padding 10px 15px 8px
  margin 7px
  font-size 1.25em
  cursor pointer
  color $black
  &.selected
    background-color $aquaponic
    border-color $aquaponic
  +below(mobile-cutoff)
    margin 5px

.personalized-home-page-interests__continue,
.personalized-home-page-interests__save,
.personalized-home-page-interests__cancel
  width 120px
  rigid-button()
  +below(mobile-cutoff)
    width 88px

.personalized-home-page-interests__continue
  margin 0 auto 30px
  +below(mobile-cutoff)
    margin-bottom 15px

.personalized-home-page-interests__dismiss
  form-1()
  color $oyster
  cursor pointer

.personalized-home-page-interests__save
  display inline-block
  margin-right 15px

.personalized-home-page-interests__cancel
  border 1px solid $sage
  color $sage
  background transparent
  display inline-block
  &:hover
    color $mint
    background transparent
    border-color $mint


