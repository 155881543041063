.tip-container-disabled
    display flex
    border 1px solid $tippingDisabled
    border-radius 4px
.tip-container-enabled
    display flex
    border 1px solid #9C9795
    border-radius 4px
.tip-section 
    margin 1em 0
    display flex
    flex-direction column
    gap 1em
    div
        display flex
    input 
        flex 1
        width 100%
        font-weight bold
        padding-left 18px
.input-wrapper
    position relative
.input-container 
    display flex
    flex-direction column
    p 
        color red
        margin-bottom 0
    
.input-wrapper::before
    content "$"
    position absolute
    left 7px
    top 52%
    font-size 1.25rem
    font-weight: bold
    transform translateY(-50%)

.tip-body 
    margin-bottom 20px

.tipping_section__header
    display inline
    position: absolute

.tipping_section__info-link
    link()
    text-align center

    img
        margin-left 5px
        filter brightness(0)