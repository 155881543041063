
.sign-in-form
  .sign-in-form__error
    color $error
  input
    width 100%
    box-sizing border-box
    border-bottom-color $silt
    &:hover,
    &:focus
      border-width 0 0 1px 0
      border-bottom-color $silt
    &.valid
      border-width 0 0 1px 0
      border-bottom-color $cornflowerBlue
      &:hover,
      &:focus
        border-bottom-color $cornflowerBlue
    &.invalid
      border-width 0 0 1px 0
      border-bottom-color $error
      color: $error
      &:hover,
      &:focus
        border-bottom-color $error


.sign-in-form
  .sign-in-form__email-row
    margin-bottom 22px
  .sign-in-form__password-row
    margin-bottom 32px
    input.form-control
      padding 8px calc(8px + 13px + 16px) 8px 0
  .sign-in-form__email-row,
  .sign-in-form__password-row
    overflow hidden
    input
      height 40px
      border-width 0 0 1px 0
      border-radius 0px
      font-style normal
      font-weight normal
      font-size 16px
      line-height 40px
      padding 8px 0


.sign-in-form
  .sign-in-form__input-container
    position relative
    .icon
      position absolute
      right 13px
      top calc(50% - 0.625rem)
      line-height normal
      font-size 16px
  .icon-eye-open,
  .icon-eye-open-slash
    cursor pointer

.sign-in-form
  .sign-in-form__submit
    refresh-button()
    display block
    margin auto 0 16px 0
    width 100%
    background $brownsoil
    text-transform initial
    &:hover,
    &:focus
      background $cornflowerBlue
    &:disabled
      background $oyster
      cursor not-allowed
