summaryItemBottomBorder()
  border-bottom 1px solid $silt

  // +below(mobile-cutoff)
  //   border-bottom 1px solid $oyster

/* This has to be it's own style above the other one, AND it needs
 * the clearfix class */
.summary-item
  clear both

.summary-item
  summaryItemBottomBorder()
  padding-bottom 20px
  margin-bottom 20px

.summary-item__name
  title-4()
  color $black

.summary-item__producer
  caption-1()
  color $oyster
  display block

.summary-item__unit
  body-1()

.summary-item__photo
  margin-right 20px

.summary-item__actions
  body-1()
  width 50%
  .inline-select
    color $oyster

.summary-item__remove
  body-1()
  color $laurel
  display inline-block
  line-height 1em

.summary-item__remove, .summary-item__more-packaging-info-link
  padding 10px 0
  cursor pointer

  &:link
    color $oyster
  &:hover
    color $sage

.summary-item__more-packaging-info-link
  color $oyster

.summary-item__prices
  text-align right
  width 10%
  display flex
  align-self center

  +below(mobile-cutoff)
    width 30%

  .summary-item__price
    width 100%

.summary-item__price
  title-3()
  text-align right

.summary-item__strikethrough-price
  title-2()
  text-align right
  color $oyster
  text-decoration line-through

.summary-item__discounted-price
  display none
  color $tomato

.summary-item__quantity-summary
  white-space nowrap

.summary-item__availability
  caption-1()
  color $tomato
  font-size inherit
  line-height 12px
  margin -4px 0px 4px 0px;

.summary-item .has-discount
  .summary-item__base-price
    text-decoration line-through

  .summary-item__discounted-price
    display block

.summary-item__photo,
.summary-item__details,
.summary-item__controls,
.summary-item__price,
.summary-item__quantity-summary.right
  float left
  box-sizing border-box

.summary-item__details,
.summary-item__controls,
.single-order-page__line-item__controls
.summary-item__quantity-summary.right
  +above(single-column-cutoff)
    height 6.3rem

.summary-item__info-control-spacer
  display none

.summary-item__photo
  width 135px
  line-height 0

  img
    width 100%

.summary-item__details
  width calc(74% - 175px)
.summary-item__controls,
.summary-item__quantity-summary.right
  width 16%
.summary-item__price
  display flex
  flex-direction column
  margin-left 0
  @media screen and (min-width: 701px) 
    margin-left 1em

.basket-view .summary-item:last-child
  border-bottom none
  margin-bottom 0
  padding-bottom 0

.summary-item__subscription__add-more
  white-space: nowrap;

.summary-item__bold
  font-weight bold

.summary-item__subscription-controls
  padding 6px 0 6px 6px
  width 100%
  background $halibut
  border 1px solid $pearl
  border-radius 2px
  cursor pointer
  color: $laurel
  line-height 16px
  .icon-subscriptions
    font-size 14px
    margin: 1px 5px 0 10px
  .subscribe-label
    font-size 11px
    margin-top 2px
  .ge-checkbox, .subscribe-label, .icon-subscriptions
    display inline-block
    vertical-align middle
    line-height 16px
  &:hover
    color: $sage
    .checkbox.active
      background-color: $sage

+below(single-column-cutoff)
  .summary-item__info-control-spacer
    display block

  .summary-item__photo
    margin-bottom 20px
    width 80px

  .summary-item__details
    width calc(100% - 120px)

  .summary-item__controls,
  .summary-item__quantity-summary.right
    width 50%

  .summary-item__prices,
  .summary-item__price
    width 50%

  .summary-item__remove
    box-sizing border-box

  .summary-item__actions
    display block
    width 100%

+below(mobile-cutoff)
  .summary-item__controls,
  .summary-item__quantity-summary.right
    width 70%

  .summary-item__prices,
  .summary-item__price
    width 30%
  .summary-item__subscription-controls
    margin 15px 0 5px

.summary-item__quantity-controls
  .filled-select, .empty-select
    width 100%
    display block
    margin-bottom 10px

.summary-item__quantity
  body-1()

.summary-item__quantity-controls,
.summary-item__subscription-controls
  max-width 200px

.summary-item__subscription__learn-more
  margin-top 5px
  minitext-1()
  text-transform none
  font-size 11px
  color $oyster
  cursor pointer
  white-space nowrap
  +below(mobile-cutoff)
    margin-top 10px

.summary-item__subscription__savings
  color $black
  text-transform none
