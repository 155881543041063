.sign-in-flow__content
  background-color white
  margin 0 auto
  width 300px
  box-sizing border-box
  position relative
  text-align center
  min-width 261px

  .submit-button
    white-space nowrap

  .landing-page__zip-code-collection-headline
    font-size 20px

  .ge-separator__content
    flex 2

  .social-sign-in__buttons
    max-width initial

  .google-login-button
    height 100%

  .ge-separator
    margin 11px 0

.sign-in-flow__logo
  width 41px
  height 33px
  margin-bottom 13px

.sign-in-flow__title
  display-2()
  font-size 20px
  line-height 24px
  margin-bottom 8px

  +below(tablet-cutoff)
    font-size 19px
    line-height 23px
    margin-bottom 11px

.sign-in-flow__input
  margin 0 auto
  width 100%
  height 40px
  body-1()
  padding 12px 20px
  box-sizing border-box

  &:focus
    border-color $oyster

  &:hover
    border-color $oyster

.sign-in-flow__button
  rigid-button()
  width 100%
  background-color $chestnut
  height 40px
  padding 8px 20px
  margin 0 auto
  body-1()
  &:hover
    background-color lighten($chestnut, 20%)

.sign-in-flow__sign-in
  caption-1()
  font-size 16px
  text-align center
  width 100%
  left 0

  +below(mobile-cutoff)
    padding-top 16px

  a.text-link
    dark-link()
    text-decoration underline

.sign-in-flow__disclaimer
  max-width 248px
  margin 8px auto 28px
  minitext-1()
  text-transform none
  color $oyster

  +below(mobile-cutoff)
    margin 8px auto 20px

  a
    dark-link()
    &:link, &:visited, &:hover
      color $oyster

.pdp-sign-in-flow__logo
  margin-left auto
  margin-right auto

.pdp-sign-in-flow-refresh__sing-in-link
    cursor pointer
    color $black
    text-decoration underline 
    font-weight 800
    margin-left 5px
    margin-top -2px
    
.pdp-sign-in-flow__auth
  display flex
  text-align center
  width 100%
  justify-content center
  
.pdp-sign-in-flow-refresh__title
  height 90px
  font-family Editor
  font-style normal
  font-weight 800
  font-size 24px
  line-height 30px
  display flex
  align-items center
  text-align center
  color #333333

.pdp-sign-in-flow-zip-entry-refresh__subtitle
  height 48px
  font-family Graphik
  font-style normal
  font-weight normal
  font-size 16px
  line-height 22px
  text-align center
  color #666666
  margin-bottom 20px

.pdp-center-flow__title
  margin-left 75px
  margin-top -15px
  
@import './components/sign_up_screen/index.styl'
@import './components/social_sign_in_screen/index.styl'
@import './components/zip_entry_screen/index.styl'
@import './components/sign_in_screen/index.styl'
