.delivery-address-page
  .checkout__title
    margin $gutter 0

  a.signin
    link ()

  .checkout__error-faq-link
    color $laurel

  .checkout__title-returning
    float right
    margin 20px 0
    +below(440px)
      margin 18px 0

  .checkout__title
    display inline-block

  .checkout__header
    border-bottom 1px solid $silt
    margin-bottom 30px

  .delivery-address-form
    padding-top 20px
    .canLeaveAtDoor, .isGift
      label
        margin-top 3px
        margin-bottom 0

  .sign-up-page__phone-disclaimer
    text-align left
    font-size 13px
    line-height 19px

  .checkout__button-row .button.button--checkout
    padding 0 20px
    width initial

  .deliver-address--error
    color $tomato
