.sign-up-page__errors
  margin-bottom 15px

.sign-up-page__sign-in,
.sign-up-page__phone-disclaimer,
.sign-up-page__terms-and-conditions
  text-align center
  color $black
.sign-up-page__sign-in
  font-size 16px
  margin-bottom 24px
  font-family "Graphik", Helvetica, Arial, sans-serif
  a
    form-link()
.sign-up-page__phone-disclaimer,
.sign-up-page__terms-and-conditions
  font-size 12px
  margin-bottom 12px
  a
    dark-link()
    text-decoration underline

.sign-up-modal
  max-width 320px
  padding 0 40px

.sign-up-modal
  .close-modal__icon
    &:hover
      cursor pointer

.goodeggs-workmark-logo
  background-image url('/img/svg/icons/logo_black.svg?auto=format')
  background-repeat no-repeat
  background-size 100%
  width 64px
  height 52px

.sign-up-modal__logo_container
  display flex
  justify-content center
  width 100%
  margin-top 32px

