.gift-cards-thank-you-page
  padding-top: 50px
  text-align center
  margin-bottom: 40px

.gift-cards-thank-you-page__title
  display-2()

.gift-cards-thank-you-page__image
  text-align center
  margin-bottom 35px
  max-width 100%

.gift-cards-thank-you-page__copy
  title-2()
  margin 10px 10px 25px

.printable-gift-card
  margin 30px auto 40px
