.market-sidebar
  margin-top 3px
  bottom 10px
  overflow-y auto
  position static
  will-change transform // pre-set in separate layer so that there's no scroll jank once it becomes fixed position

  &.stuck
    position fixed // consider changing to position sticky once IE supports it

  +at-and-below(tablet-cutoff)
    display none

.market-sidebar__content
  padding-top 20px
  padding-right 20px
  box-sizing border-box
  max-width 18vw

.market-sidebar__menu-group
  transition max-height 0.1s ease-in-out
  overflow hidden
  max-height 0

  &.expanded
    transition max-height 0.3s ease-in-out
    max-height 500px
    margin-bottom 3px

.market-sidebar__menu-group-title
  display block
  font-size 14px
  line-height 30px

  &.selected
    font-family $semiboldFontFamily

.market-sidebar__menu-item-spacer
  height 6px
  margin-left 2px
  padding-left 14px
  border-left 4px solid $silt
  &:first-child, &:last-child
    border-left none

.market-sidebar__menu-item
  display block
  font-size 14px
  padding-top 1px
  line-height 16px
  margin-left 2px
  padding-left 14px
  border-left 4px solid $silt

  &.selected
    border-left-color $sage
