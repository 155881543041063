.personalized-home-page__module.personalized-home-page__module-featured-producers
  inverted-personalized-home-page-module()

.personalized-home-page__featured-producers-module__grid
  text-align center
  +below(mobile-cutoff)
    padding 0 8px

.personalized-home-page__featured-producers-module__brand-tile
  display inline-block
  box-sizing border-box
  width grid-item-width(5, 20px)
  margin 0 20px 20px 0
  border 1px solid $pearl
  background center no-repeat
  background-color $ricotta
  border-radius 8px
  box-shadow(0 2px 8px 0 rgba(0, 0, 0, 0.1))
  position relative

  &:nth-child(5n+5)
    margin-right 0

  &:hover
    transition box-shadow .1s
    box-shadow(0 4px 20px 0 rgba(0,0,0,0.2))

  +below(mobile-cutoff)
    width grid-item-width(2, 8px)
    margin-right 8px
    margin-bottom 8px

    &:nth-child(5n+5)
      margin-right 8px

    &:nth-child(2n)
      margin-right 0

  &.brand-image
    background-size 260px auto

  &.brand-cloversfbay
    background-image url('/img/web/home/brand_clover.png?auto=format')
  &.brand-foglinefarmsfbay
    background-image url('/img/web/home/brand_fogline.png?auto=format')
  &.brand-froghollow
    background-image url('/img/web/home/brand_froghollow.png?auto=format')
  &.brand-fullbellymarketplace
    background-image url('/img/web/home/brand_fullbelly.png?auto=format')
  &.brand-momofuku
    background-image url('/img/web/home/brand_mfuah.png?auto=format')
  &.brand-pizzeriadelfina
    background-image url('/img/web/home/brand_delfina.png?auto=format')
  &.brand-roliroti
    background-image url('/img/web/home/brand_roliroti.png?auto=format')
  &.brand-sonomacountymeatco
    background-image url('/img/web/home/brand_sonomameat.png?auto=format')
  &.brand-stepladdercreamery
    background-image url('/img/web/home/brand_stepladder.png?auto=format')
  &.brand-theslanteddoor
    background-image url('/img/web/home/brand_tsd.png?auto=format')

.personalized-home-page__featured-producers-module__brand-tile-spacer
  height auto
  padding-bottom (180/215 * 100)% // maintain aspect ratio for image
