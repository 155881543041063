.about-page__content
  web-wide-content-column()
  padding-top 60px
  padding-bottom 70px
  +strictly-below(800px)
    padding-top 30px

.about-page__content-inner
  position relative

$aboutPageNavWidth = 170px // includes padding

.about-page__column-content
  box-sizing border-box
  width 100%
  // Leave fixed space for nav
  padding-left $aboutPageNavWidth
  +strictly-below(800px)
    padding-left 0
    padding-top 110px // space for nav

.about-page__column-nav
  position absolute
  top 0
  left 0
  display inline-block
  vertical-align top
  box-sizing border-box
  +at-and-above(800px)
    width $aboutPageNavWidth
    padding-right 50px
  +strictly-below(800px)
    width 100%
    display block
    margin-bottom 40px

.about-page__nav-list
  list-style none
  margin 0
  padding 0

.about-page__nav-element
  form-1()
  margin-bottom 10px

.about-page__nav-element-current
  form-2()
  margin-top -2px // compensate for bold font offset vertical centering
  margin-bottom 12px

.about-page__header
  display-2()
  text-align center

.about-page__copy
  body-4()
  max-width 680px
  margin-left auto
  margin-right auto

.about-page__link
  link()
