.send-referrals-page__referral-form
  background-color white
  margin 0 auto
  width 35%
  min-height 306px
  min-width 540px
  text-align center
  padding 35px 55px 35px 55px

  +below(mobile-cutoff)
    min-width initial
    width initial
    min-height 420px

  .ge-separator__content
    flex 0.25

.send-referrals-page__referral-form-title
  display-1()
  margin-bottom 10px

.send-referrals-page__referral-form-subtitle
  title-5()
  margin-bottom 15px

.send-referrals-page__referral-form-body
  title-2()
  margin-bottom 3px

.send-referrals-page__email-input
  width 70%

  +below(mobile-cutoff)
    width 100%
    margin-bottom 10px

.send-referrals-page__email-submit
  width 30%
  background-color $chestnut
  border 1px solid $chestnut

  +below(mobile-cutoff)
    display block
    width 100%

.send-referrals-page__email-form
  margin 20px 0

.send-referrals-page__share
  margin-top 35px

  +below(mobile-cutoff)
    margin-top 30px

.send-referrals-page__share-text
  display inline-block
  text-align left
  width 20%

  +below(mobile-cutoff)
    display block
    margin-bottom 5px
    text-align center
    width initial

.send-referrals-page__share-link
  width 50%
  font-size 0.8rem

  +below(mobile-cutoff)
    width 100%
    margin-bottom 10px

.send-referrals-page__copy-button
  width 30%
  background-color $oyster
  color $white
  border 1px solid $oyster

  +below(mobile-cutoff)
    display block
    width 100%

.send-referrals-page__send-button-sage
  background-color $sage
  border-color $sage
  color white

.send-referrals-page__terms
  color $oyster

  a
    link()

.send-referrals-page__sign_in
  margin-top 30px
  title-2()
  a
    link()

$orderPlacedModalVerticalPadding = 40px
$orderPlacedModalHorizontalPadding = 150px

.order-placed-modal__contents
  padding-top $orderPlacedModalVerticalPadding
  padding-left $orderPlacedModalHorizontalPadding
  padding-right $orderPlacedModalHorizontalPadding
  text-align center

  +below(mobile-cutoff)
    padding-left 0px
    padding-right 0px

.order-placed-modal__headline
  display-2()

.order-placed-modal__body
  body-1()

  a
    color $sage
    font-weight bold
#post-checkout-buy-gift-card
  i 
    font-size 3.1rem
    margin-bottom 12px
  a
    font-size 2rem
    margin-top 10px

.order-placed-modal__download-app__container
  margin 20px
  display flex
  justify-content center
  box-shadow 0px 8px 20px rgba(10, 22, 70, 0.15)
  border-radius 8px
  padding-top 16px

.order-placed-modal__download-app__content
  display flex
  align-items center

  +below(mobile-cutoff)
    flex-direction column

.order-placed-modal__download-app__app_image
  width 221px
  margin-right 45px

  +below(mobile-cutoff)
    width 248px
    margin-right 0px


.order-placed-modal__download-app__body
  +below(mobile-cutoff)
    display flex
    flex-direction column
    align-items center
    padding-bottom 26px

.order-placed-modal__download-app__text
  font-style normal
  font-weight 900
  font-size 18px
  line-height 20px
  width 306px

  +below(mobile-cutoff)
    font-size: 15px;
    width: 247px;
    text-align: center;

  span
    color #3784d9

.order-placed-modal__download-app__app-store-image
  width 132px
  height 39.78px

.order-placed-modal__download-app__app-store-buttons-container
  display flex
  flex-wrap wrap
  gap 8px
  
  +below(mobile-cutoff)
    justify-content center


