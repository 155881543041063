$main-body-width = 342px

.how-it-works-page__shop_with_us__tagline
  text-align center
  margin-top 20px
  margin-bottom 20px

  h1
    display-2()
    text-align center
    margin-bottom 20px

  p.subtext
    title-5()
    font-size 2rem
    line-height 1.2em
    letter-spacing 0
    text-align center
    padding 0 60px 0 60px

    +below(ipad-width)
      padding 0 20px 0 20px auto

.how-it-works-page__shop_with_us-text
  max-width $main-body-width
  margin 0 auto
  form-1()

.how-it-works-page__shop_with_us__footer
  border-top 1px solid #edeae6
  display flex
  align-content center

  +below(ipad-width)
    display block

.how-it-works-page__shop_with_us__footer__reason
  width 250px
  box-sizing border-box
  text-align center
  vertical-align top
  padding 10px
  margin 0 auto


  h3
    title-3()
    margin-bottom 0
    margin-top 18px
    line-height 20px
    font-size 18px

  p
    form-1()
    margin-top 15px
    line-height 20px
    font-size 15px

.how-it-works-page__shop_with_us_icon_container
  min-height 50px

.how-it-works-page__shop_with_us_icon
  display block
  margin 0 auto
  width 215px
