@import './components/line_item.styl'

.single-order-page__error
  margin 10px 30px 30px

.single-order-page__items
  background-color $eggshell

.single-order-page__items-grid
  display block
  line-height 1em
  margin 0 30px
  +below(mobile-cutoff)
    margin 0 15px

.single-order-page__items-header,
.single-order-page__items-footer
  overflow hidden
  padding 20px 30px
  form-2()
  +below(mobile-cutoff)
    padding 24px 15px
