$bannerHeight = 60px

.banner-logo 
   padding-left 12px
   padding-right 12px

.mobile-banner__content__description
   margin-block 3px 
   
.mobile-banner
	top 0
	left 0
	right 0
	background-color $white
	height $bannerHeight
	line-height 1
	padding 0 16px 0 10px
	display flex
	align-items center
	justify-content space-between
	box-shadow 0 2px 10px 0 rgba(10, 22, 70, 0.15)
	margin-bottom 2px

	.mobile-banner__container
		display flex
		justify-content space-between
		align-items center
		padding-right 12px

		.icon-thin-x
			font-size 16px

		.icon-icon-black
			font-size 42px
			padding-right 20px
			padding-left 5px

		.mobile-banner__content
			display flex
			flex-direction column

			.mobile-banner__content__title
				font-size 14px

			.mobile-banner__content__description
				font-size 11px
				margin-block 3px

	.button-outline
		button-outline-redesign()
		display flex
		align-items center
		font-size 12px
		height 36px

	@media only screen and (min-width 768px) {
		display none
	}