.masked__overlay
  position fixed
  top 0
  left 0
  width 100%
  height 100vh
  z-index $zindexModalBackground
  background-color rgba(0, 0, 0, .5)

.masked__hero
  z-index $zindexModal - 1 // so that how it works modal can overlay this
  position relative
