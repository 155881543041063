.webstand
  padding-top 20px
  margin-bottom -20px
  font-size 14px
  font-family $bodyFontFamily
  line-height normal

  .content
    display flex
    flex-direction row
    flex-wrap wrap

  .vendor-name
    margin-top 0
    padding 0
    font-family $largeHeadingsFontFamily
    font-size 40px
    line-height normal
    color $black

  .icon-pin
    font-size 18px
    margin-top -4px
    margin-right 10px

  .vendor-locations-container
    margin 18px 0px

  .vendor-address
    display inline-block
    padding 0 12px 0 0
    border-right 1px solid $silt
    margin-right 12px
    font-family $semiboldFontFamily
    text-transform uppercase
    color $black

  .vendor-image-container
    max-width 480px
    margin-bottom 15px
    flex-shrink 0
    +between(mobile-cutoff, tablet-cutoff)
      margin-left 20px
    +at(tablet-cutoff)
      margin-left 0
    +below(mobile-cutoff)
      width 100%

  .vendor-image
    width 100%

  .vendor-website
    display inline-block
    text-decoration none
    color $black

    &:hover
      color $sage

  .details
    flex 1 0 250px
    margin 0 20px 15px

  .description-container
    width 100%
    border-top 1px solid $silt

  .description
    margin-top 12px
    line-height 20px
