.orders-page__order-summary
  padding 30px 24px

.orders-page label
  min-height 13px

a.orders-page__summary-content
  form-1()
  display block

.orders-page__address,
.orders-page__gift-recipient
  width 46%

.orders-page__items
  width 29%

.orders-page__total
  width 25%

.orders-page__total,
.orders-page__items,
.orders-page__address,
.orders-page__gift-recipient
  display inline-block

  +below(620px)
    display block
    width 100%
    padding-bottom 8px


.orders-page__details
  form-1()
  display inline-block
  min-width 110px

.orders-page__order-details-link
  link()

.orders-page__order-details-link-chevron
  vertical-align 0
