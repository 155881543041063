.panel
  raised-tile()
  background-color $eggshell
  text-align left

.panel__header
  raised-tile__header()
  title-3()

.panel__body
  raised-tile__body()
  padding 0
  +below(mobile-cutoff)
    padding 0

