.flow-zip-entry__subtitle
  title-2()
  font-size 14px
  margin-bottom 15px


.flow-zip-entry_refresh_modal__subtitle
  height 44px
  margin-left 5px
  margin-top 2px
  margin-bottom 15px
  font-family Graphik
  font-style normal
  font-weight normal
  font-size 16px
  line-height 22px
  text-align center
  color #666666

.flow-zip-entry__input
  text-align center
  margin-bottom 8px

.flow-zip-entry__errors
  margin-bottom 15px

.flow-zip-entry__split-input
  width 75%

.flow-zip-entry-form
  margin-left auto
  margin-right auto
  +below(tablet-cutoff)
    width 100%

.pdp-flow-zip-entry
  margin-left auto
  margin-right auto

.pdp-sign-in-flow-refresh__email-link
  caption-1()
  link()
  cursor pointer
  color $black
  text-decoration underline 
  font-weight 800
  margin-bottom 25px
  display block

@import './components/email_entry/index.styl'
