.product-detail-page__subscription-info
  text-align center
  margin-top 30px

.product-detail-page__subscription-info__price
  body-3()

.product-detail-page__subscription-info__details
  margin-top 15px
  padding 20px
  background-color $ricotta

.product-detail-page__subscription_info__icon-subscriptions
  display inline-block
  position relative
  top -2px
  padding-right 8px
  font-size 20px

.product-detail-page__subscription-info__title
  position relative
  margin-bottom 10px
  title-3()

.product-detail-page__subscription-info__learn-more
  display block
  color $sage
  cursor pointer
