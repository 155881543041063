$accountPageNavWidth = 170px // includes padding

.account-layout
  max-width 960px
  margin 0 auto
  padding-top 60px
  padding-bottom 70px
  +strictly-below(1024px)
    padding-top 30px
    margin 0 8px

.account-layout__columns
  position relative

.account-layout__column-nav
  position absolute
  top 0
  left 0
  display inline-block
  vertical-align top
  box-sizing border-box
  +at-and-above(1024px)
    width $accountPageNavWidth
    padding-right 50px
  +strictly-below(1024px)
    width 100%
    display block
    margin-bottom 40px

.account-layout__column-content
  box-sizing border-box
  width 100%
  // Leave fixed space for nav
  padding-left $accountPageNavWidth
  +strictly-below(1024px)
    padding-left 0
    padding-top calc(30px * 6) // space for nav

.account-layout__membership__error__nav
	margin-bottom 30px