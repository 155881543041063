.primary-secondary-column-layout
  clearfix()

.primary-secondary-column-layout__primary
  +above(tablet-cutoff)
    width 60%
    box-sizing border-box
    float left
    vertical-align top

  +above(desktop-cutoff)
    width 70%

.primary-secondary-column-layout__secondary
  +above(tablet-cutoff)
    width 40%
    padding-left 15px
    box-sizing border-box
    float right
    vertical-align top

  +above(desktop-cutoff)
    padding-left 20px
    width 30%
