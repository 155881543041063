.acs--form
  display flex
  flex-direction column
  align-items center
  height 300px
  @media screen and (min-width: 625px)
    height 500px

.acs--title
  font-family Editor
  font-style normal
  font-weight 800
  color #333333
  display flex
  text-align center
  max-width 345px
  font-size 16px
  line-height 22px
  margin-top 32px
  margin-bottom 16px
  @media screen and (min-width: 625px)
    margin-top 80px
    max-width 500px
    font-size 24px
    line-height 30px

.acs--message
  font-family $bodyFontFamily
  font-style normal
  font-size 14px
  line-height 22px
  text-align center
  color #666
  margin-bottom 16px
  margin-top 0

.acs--email-input
  width 300px
  margin-right 0

.acs--button
  font-family $bodyFontFamily
  padding 0px
  border-radius 26px
  width 300px
  font-size 14px
  height 46px
  font-weight 400
  line-height 20px
  letter-spacing 0.2px
  border 0

.acs--disabled-button
  color white
  background-color #9C9795

.acs--active-button
  background-color $brownsoil
  color $eggshell
  &:hover
    background-color lighten($brownsoil, 20%)
    color $ricotta
    cursor pointer
