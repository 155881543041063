.applied-filters
  border-top 1px solid $silt
  border-bottom 1px solid $silt
  padding 15px
  margin-top 13px
  margin-bottom 20px
  +below(tablet-cutoff)
    margin-left 15px
    margin-right 15px

.count
  font-size 13px
  display inline-block
  text-transform uppercase
  font-family $semiboldFontFamily
  color $oyster
  padding-right 10px
  letter-spacing .7px
