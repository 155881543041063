.empty-basket
  form-1()
  text-align center

  strong
    font-family $semiboldFontFamily
    font-weight normal

.empty-basket__title
  display-1()

.empty-basket__body
  raised-tile__body()
  padding-top 45px
  padding-bottom 45px
  margin-bottom 50px
  +below(mobile-cutoff)
    padding-top 30px
    padding-bottom 30px

.empty-basket__continue-shopping-button.button
  margin 20px 0

.empty-basket__footer
  background $eggshell
  border-top 1px solid $silt
  padding 30px

  +below(mobile-cutoff)
    padding 25px 15px

.empty-basket__footer img, .empty-basket__footer-text
  display inline
  vertical-align middle

.empty-basket__footer-text
  margin-left 10px
  display inline-block
  line-height 1.6em

.empty-basket__delivery-options-link
  color $sage
  white-space nowrap
  &:hover
    color $laurel

+below(tablet-cutoff)
  .empty-basket__footer-text
    display block
    text-align center
    margin-top 15px

  .empty-basket__delivery-options-link
    display block
    margin-top 10px
