#fulfillment-offer-chooser-view
  .shopping-day

    .icon
      padding-right 8px
      vertical-align top
      font-size 16px
      line-height 18px

  .fulfillment-offer-list
    raised-tile()
    margin-bottom $gutter

    .title
      raised-tile__header()

    $circleRadius = 8px
    .fulfillment-offer
      background-color $ricotta
      border-bottom 1px solid $silt
      padding 8px 15px
      position relative

      .details
        display: table-cell
        width: 100%

      .name
        body-2()
        margin-right 50px

      .cutoff
        caption-1()
        color: $oyster

      .address
        caption-1()
        color $oyster
        padding-bottom 4px

      .time
        caption-1()
        line-height 0.83em
        padding-top 8px
        text-transform capitalize

      .select
        color $sage
        display table-cell
        min-width 100px
        white-space nowrap
        text-align right
        vertical-align middle
        .link-button
          padding 0
          i
            font-size 1.2rem
            margin-left -2px
            margin-top -1px

      .form-row
        margin-bottom 0

      &:hover
        cursor pointer
        background $pearl
        .select
          color $laurel

      &.selected
        background $pearl

      &.unavailable
        color $oyster
        cursor default

        &:hover
          background-color $ricotta

        .name
          body-1()

        .time-range
          body-2()
          text-decoration line-through

  .btn.save
    margin-top 0
    // margin-bottom 2*$gutter

  .giftMessage textarea
    width 100%
    box-sizing border-box

#deliveries-minimum
  text-align center

  h2
    margin-top $gutter

  .button, .button:visited
    margin-top $gutter*2

  p
    body-1()
    margin $gutter*2 0 0

    a
      text-decoration none
      color $laurel
      text-transform uppercase

  .minimum-msg
    title-3()

  .min-why
    caption-1()
    color $oyster

.delivery-tab-view__warning
  margin-bottom 20px

.delivery-fees__continue-shopping-prompt
  body-1()

.delivery-fees__continue-shopping-link
  color $laurel
