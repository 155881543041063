h2.for-you__section-header
  display-2()
  color: $forest
  +below(tablet-cutoff)
    padding-left 16px
    padding-right 16px

h3.for-you__section-header
  title-5()
  color: $forest
  margin 19px 0
  +below(tablet-cutoff)
    padding-left 16px
    padding-right 16px

