.desktop-header__shop-for-day-menu
  .dropdown-menu__content
    padding: 24px
    position: absolute
    top: 36px
    &.short-left
      left: calc(100% - 195px)
    &.mid-left
      left: calc(100% - 205px)
    &.long-left
      left: calc(100% - 215px)
    h1
      font-size: 16px
      margin: 0 0 16px 0
      font-weight: 600
    .button-outline
      button-outline-redesign()
      margin-bottom 8px
      &:disabled
        text-decoration: line-through
      &.selected
        font-weight: 600
      

.dropdown-menu__item.dropdown-menu__item-day-chooser
  padding-left 13px
  padding-right 13px
  body-copy-small()

.masquerading .desktop-header__account-menu
  .dropdown-menu__button, .dropdown-menu__item
    color $tomato

.dropdown-menu__item.dropdown-menu-item__selected
  body-copy-small-semibold()

.dropdown-menu-item__icon
  font-size 13px
  margin-right 8px
  margin-top -3px
  text-decoration none

.dropdown-menu__item-checkmark
  i.checkmark-selected:before
    content $icon-sidebar-checkmark
    color $dice

  i.checkmark-unselected:before
    content $icon-sidebar-blank-circle
    color $dice

  &:hover
    i.checkmark-unselected:before
      content $icon-sidebar-circle-hover

.dropdown-menu__item-unavailable
  color $oyster
  &:link, &:visited, &:active, &:hover
    color $oyster

  .dropdown-menu-item__icon
    visibility hidden

.dropdown-menu__button
  strong
    &:active, &:hover
      text-decoration-line: underline

.soldout-text
  color: $roast
  span
    text-decoration: line-through