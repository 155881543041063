.account-nav-list__list
  list-style none
  margin 0
  padding 0

.account-nav-list__list-item
  form-1()
  margin-bottom 10px

.account-nav-list__list-item-current
  form-2()
  margin-top -2px // compensate for bold font offset vertical centering
  margin-bottom 12px
