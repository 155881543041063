.single-order-panel__header
  position relative

.single-order-panel__masquerading-warning
  background-color $tomato
  padding 5px 0
  title-4()

.single-order-panel__header-title
  display inline-block
  padding-right 16px
  line-height 35px

  &.subscription
    padding-left 34px

.single-order-panel__header-subtitle
  body-1()
  display inline-block

  &.is-upcoming
    margin-top 6px
    display block

.single-order-panel
  margin 30px
  +below(620px)
    margin 20px 10px

  .panel__body
    position relative
    padding 0
    +below(mobile-cutoff)
      padding 0

.single-order-panel__subscription-icon
  position absolute
  top 5px
  display inline-block
  font-size 20px

  .icon-alert-warning
    font-size 19px
    color $tomato
    position absolute
    top 13px
    left 11px
    background-color white
    border-radius 55px
