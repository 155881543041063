.change-password-page__container
  max-width 500px
  margin 0 auto
  padding 50px 0
  +below(mobile-cutoff)
    margin 0 10px
    padding 20px 0

.change-password-page .form-row
  box-sizing border-box
  width 100%
  margin-bottom 20px

.change-password-page .form-row-input
  box-sizing border-box
  width 100%
