@import './components/order_summary.styl'

.orders-page
  max-width 850px
  margin 0 auto

.orders-page__section-title
  display-1()
  margin 50px 30px 20px 30px
  +below(620px)
    margin 20px 10px

.orders-page__message
  +below(620px)
    margin 20px 10px
  margin 20px 30px
  text-align left

.orders-page__empty-section
  text-align center

.orders-page__empty-content
  form-1()

.orders-page__shop-button
  button()
  margin 25px 0 100px 0
