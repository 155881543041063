.search-no-results.search-no-terms
  min-height 200px

h3.search-summary
  color $oyster
  font-size 12px
  line-height 12px
  text-transform uppercase
  font-family $semiboldFontFamily
  letter-spacing 1px
  border-top solid $silt 1px
  border-bottom solid $silt 1px
  padding 16px 0

  +below(tablet-cutoff)
    margin 15px 15px 10px 15px

  +between(tablet-cutoff, desktop-cutoff)
    margin 15px $desktopMargin $desktopMargin 0

  +above(desktop-cutoff)
    margin 15px 0 $desktopMargin 0

.search-header
  width tablet-cutoff
  margin 25px auto 30px auto
  label
    display none

  +below(tablet-cutoff - 1px)
    display none

  display block
  $fontSize = 14px
  $inputPadding = 12px

  form input[type=text], .search-action, .search-cancel
    padding $inputPadding
    line-height $fontSize
    font-size $fontSize
    margin 0

  form .search-action, .search-cancel
    $xOffset = 45px
    $width = 16px
    display inline-block
    width $width
    margin-left - $xOffset
    margin-right $xOffset - 2*$inputPadding - $width
    margin-bottom - $fontSize - 2*$inputPadding
    text-align center
    text-decoration none

    :hover
      cursor pointer

    i
      vertical-align -2px
      color $oyster
      line-height $fontSize

  form input[type=text]
    border 1px solid $pearl
    box-shadow none
    box-sizing border-box
    width 100%
    border-radius(4px)

    +placeholder()
      font-style normal
      font-size 1em
      color $oyster

.search-products
  margin 0 15px


.search-page__sidebar-container
  display inline-block
  float left
  width 20%
  //padding 0 10px 0 24px
  box-sizing border-box
  +below(tablet-cutoff)
    display none

.search-page__results-container
  display inline-block
  width 80%
  box-sizing border-box
  +below(tablet-cutoff)
    width 100%

.search-page__results-subcategory-title
  display-1()
  margin-bottom 12px

  &:not(:first-child)
    margin-top 10px

  +below(tablet-cutoff)
    margin 10px 8px

.card-main.active .content.search-page
  +above(1023px)
    +below(1300px)
      margin 0 auto

@import './components/sidebar.styl'
