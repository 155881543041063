$sendAProductModalMobileMargin = 10px

send-gift-modal-row()
  padding-left 20px
  padding-right 20px

.modal-container.send-gift .modal,
.send-gift-modal
  width 410px
  min-height 575px

  +below(mobile-cutoff)
    width "calc(100% - 2 * %s)" % $sendAProductModalMobileMargin
    height 100%
    margin $sendAProductModalMobileMargin

.send-gift-modal .send-a-product-tile
  box-shadow none

.modal-container.send-gift,
.send-gift-modal
  color $black
  padding-bottom 20px

  p
    text-align center
    body-1()
    margin 0 10px 20px

  .headline
    padding 0
    margin 20px 0 15px 0
    display-1()
    text-align center

  .explanation
    body-1()
    margin 0 0 18px 0

    +below(mobile-cutoff)
      line-height 16px

  +below(mobile-cutoff)
    .span6
      width 100%
      margin-left 0

  .send-gift-modal__message
    height 100px
    resize vertical

  .sent-gift
    send-gift-modal-row()
    margin-top 150px

  .send-gift-form
    send-gift-modal-row()

    input, textarea
      body-1()

    textarea
      line-height 18px

  .button
    margin 0 auto
    display inherit
    width 240px
    text-align center

.modal-container.send-gift
  .product-tile
    background inherit // override product tile background, use modal background
    box-shadow none    // shadows don't make sense for single tile in modal

  .btn
    width 50%
    display block
    margin 0 auto
    font-weight normal

  div.error
    display none

  div.error.form-error
    display block
    overflow hidden
    font-size 14px
    margin 8px 0 5px
    padding 0
    text-align center

  .rv-send-gift-form-message
    &.error
      margin-bottom 0

