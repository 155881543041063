.banner__min__order-banner
  width 100%
  height 48px
  background $black
  color #ffff
  display flex
  justify-content center
  align-items center
  font-size 13px
  line-height 16px

.banner__min__order-icon
  margin-right 9px
  font-size: 15px;
    