.flow-sign-in
  >.sign-in-flow__title
    margin-bottom 35px

.flow-sign-in__forgot
  margin 24px
  text-align center

  a
    dark-link()
    &:link, &:visited, &:hover
      color $oyster

  +below(mobile-cutoff)
    margin 15px auto

.flow-sign-in__sign-up
  caption-1()
  padding-top 20px
  text-align center
  width 100%
  left 0

  +below(mobile-cutoff)
    padding-top 10px

  a
    dark-link()

.flow-sign-in__errors
  width 100%
  margin-bottom 15px
