$basket-expansion-top-margin = 15px
$basket-gutter = 15px

.basket-expansion__spacer
  width 100%
  height $basket-expansion-top-margin

.basket-expansion
  text-align center
  will-change transform // pre-render in a separate layer since it will become fixed positioning for scroll performance

.mini-nav .basket-expansion
  position fixed
  top $miniNavHeight

.basket-expansion__content
  width 397px
  float right

.basket-expansion__content
  background-color $ricotta

.basket-expansion__header
  text-align center
  background $toast
  padding 15px 20px
  caption-1()
  padding 2px 0
  .icon-truck
    font-size 18px

.basket-expansion-header__free-delivery
  body-2()
  text-transform uppercase
  padding-left 5px

.basket-expansion-header__on-first-order
  body-1()

.basket-expansion__basket-empty
  title-1()
  text-align center
  margin 0 auto
  margin-top 24px

.basket-expansion__header-message
  display inline-block
  margin-left 5px
  line-height 35px
  text-align center

.basket-expansion__header-message__time
  font-weight bold

.basket-expansion__sections
  text-align left
  body-1()
  margin-top $basket-expansion-top-margin
  padding 0 60px $basket-gutter 80px

.basket-expansion-sections__column
  display inline-block
  width 50%
  vertical-align top

.basket-expansion__basket-view
  text-align left
  max-height 500px

.basket-expansion-basket-view__spacer
  margin 20px $basket-gutter 0 $basket-gutter
  border-top 1px solid $oyster

.basket-expansion-basket-view__items
  max-height 300px
  overflow auto

.basket-expansion-item
  padding $basket-gutter $basket-gutter $basket-gutter - 5px
  display grid
  grid-template 2fr / 1fr 3fr 1fr
  grid-gap $basket-gutter
  height 60px
  border-bottom 1px solid $silt
  &:hover
    background-color $pearl
  &:last-child
    border none
  img
    width 95px
    height 60px

.basket-expansion__unavailable-items
  background-color $eggshell
  img
    opacity 0.3

.basket-expansion__unavailable-items-header
  border-bottom 1px solid $silt
  padding 5px 0
  margin 0 10px

.basket-expansion-item__summary
  grid-row span 2
  margin-left $basket-gutter
  vertical-align top
  width 180px
  display inline-block

.basket-expansion-item-summary__title
  title-1()
  font-size 13px
  overflow hidden
  -webkit-line-clamp 2
  -webkit-box-orient vertical
  max-height 37px
  display -webkit-box
  line-height 16px

.basket-expansion-item-summary__units
  padding-top 3px
  caption-1()

.basket-expansion-item__available-quantity
  color $tomato
  padding-top 3px

.basket-expansion-item__photo
  grid-row span 2

.basket-expansion-item__price
  grid-row span 1
  text-align right
  body-1()
  line-height 1.3em

.basket-expansion-continue
  flat-button()
  title-4()
  text-transform uppercase
  font-size 16px
  width 100%
  padding 12px $basket-gutter 14px
  margin 0
  &.disabled
    background-color $silt
    cursor default
    &:hover
      cursor default

.basket-expansion-continue__progress
  text-align center
  background-color $eggshell
  padding 12px 15px 10px 15px
  border-top 1px solid $silt

.basket-expansion-continue__progress-header
  caption-1()
  padding-bottom 5px

.basket-expansion-continue__progress-bar
  height 5px
  border-radius 5
  background-color $silt

.basket-expansion-continue__progress-bar-progress
  height 5px
  border-radius 5
  background-color $chanterelle

.basket-expansion-inline-totals
  padding 10px $basket-gutter
  display block

.basket-expansion-inline-totals__row
  clear both
  body-1()
  line-height 1.5em

.basket-expansion-inline-totals__description
  padding-top 2px

.basket-expansion-inline-totals__amount
  float right

.basket-expansion-inline-totals__row-subtotal
  body-2()
  line-height 1.5em

.basket-expansion-continue__under-minimum-message
  body-2()
  padding-top 15px


.basket-expansion-item-remove
  display none
  grid-row span 1
  grid-column span 1
  justify-content end
  & .summary-item__remove
    color $tomato
    font-size 14px
    &:hover
      opacity 0.6

.basket-expansion-item:hover .basket-expansion-item-remove
  display flex

.basket-expansion__content
  position relative

  .overlay
    position absolute
  .egg-spinner
    position absolute