raised-tile()
  background-color white
  box-shadow(0 1px 0 0 rgba(0,0,0,0.1))

raised-tile__header()
  label-1()
  background $aquaponic
  padding 15px 20px

  +below(mobile-cutoff)
    padding 15px

raised-tile__header-link()
  float right
  text-button()
  font-weight normal
  &:hover
    color $sage

raised-tile__body()
  padding 20px
  +below(mobile-cutoff)
    padding 15px

.raised-tile
  raised-tile()

.raised-tile__header
  raised-tile__header()

.raised-tile__body
  raised-tile__body()

.raised-tile__header-link, a.raised-tile__header-link
  raised-tile__header-link()

.raised-tile-admin__header
  background $tomato
  color white
