$bannerHeight = 40px
$navHeight = 60px

$desktopMargin = 20px
$gutter = 15px

$zindexModalBackground = 3000
$zindexModal = 3010
$zindexFlashMessages = 4000

animated-saving-button()
  .saving-state
    display none

  &.saving
    background-color $sage
    cursor default
    position relative
    box-shadow 0px 1px 0px 0px rgba(255,255,255,0.50), inset 0px 0px 0px 1px rgba(0,0,0,0.10), inset 0px 2px 0px 0px rgba(0,0,0,0.15)
    > .label
      display none
    > .saving-state
      display inline
      > .eggs
        font-size 11px
        line-height 1
        letter-spacing 7px
        margin-left 0.8em
        > .icon
          vertical-align baseline
          animation pulse 2s linear infinite
          opacity 0.1
        > .first
          animation-delay 0s
        > .second
          animation-delay 0.3s
        > .third
          animation-delay 0.6s

.container-fluid
  min-width 0

gutter(amount = $gutter)
  padding-left amount
  padding-right amount

ungutter()
  padding-left 0
  padding-right 0

@media only screen and (max-width: 900px)
  .gutter
    gutter()

.link
  link()

.btn
  background-color $eggshell
  box-shadow 0px 1px 0px 0px rgba(0,0,0,0.40), inset 0px 0px 0px 1px rgba(0,0,0,0.15), inset 0px 1px 0px 1px $eggshell
  border-radius 4px
  border 0
  display inline-block
  text-align center
  text-decoration none
  padding 7px 20px
  label-2()
  line-height 1.25em
  color $sage
  text-transform uppercase

  &:active, &.active
    color $eggshell
    background $laurel
    box-shadow 0px 1px 0px 0px rgba(255,255,255,0.50), inset 0px 0px 0px 1px rgba(0,0,0,0.10), inset 0px 2px 0px 0px rgba(0,0,0,0.15)

  &:focus,
  &.focus
    outline none

  &.small
    font-size 10px
    line-height 10px
    padding 10px 21px

  &.primary
    font-weight bold
    box-sizing border-box
    color $eggshell
    background-color $mint
    width 100%
    line-height 14px
    padding-top 13px
    padding-bottom 13px
    box-shadow 0px 1px 0px 0px rgba(0,0,0,0.40), inset 0px 0px 0px 1px rgba(0,0,0,0.20), inset 0px 1px 0px 1px rgba(255,255,255,0.20)

    &[disabled]
      opacity 1
      color $eggshell
      background-color $oyster
      cursor default

      &:hover
        background-color $oyster
        box-shadow none

    animated-saving-button()

.btn.primary
  border-radius 40px
  background-blend-mode overlay
  background-color $inactiveButtonColor
  cursor pointer

  &:hover
    background-color $hoverButtonColor
    box-shadow 0px 1px 2px 0px $shadowColor

  &:active
    background-color $depressedButtonColor
    background-image linear-gradient(-1deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%)
    box-shadow 0px 1px 0px 0px rgba(255,255,255,0.30), inset 0px 1px 3px 0px rgba(0,0,0,0.50);

.clearfix
  clearfix()

.hidden
  display none

h1
h2
  margin 0

.usd
  font-family $bodyFontFamily
  font-weight bold
  font-size 20px
  color $black
  line-height 1.15
  letter-spacing -0.5px

  &.split
    font-weight normal
    font-size 48px

    .cents
      margin-left: 0.1em
      vertical-align top
      line-height 2.1em
      letter-spacing 0.9px
      font-size 0.4em
      font-family $semiboldFontFamily

  &.discounted-price
    color $tomato

@keyframes pulse
  0%
    opacity 0.1
  50%
    opacity 1
  100%
    opacity 0.1

@keyframes rotate
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

.overlay
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background white
  opacity .7
  z-index 1

.egg-spinner
  position fixed
  left 50%
  margin-left -21px
  top 50%
  margin-top -21px
  width 42px
  height 42px
  z-index 2
  animation rotate 1s infinite steps(8)

.egg-spinner div:before,
.egg-spinner div:after
  content $icon-egg
  font-family "icons"
  color $black
  position absolute
  font-size 11px
  line-height 9.5px
  left 16.5px
  top 31px
  transform-origin 50% -10px

.egg-spinner
  div:first-child:before
    transform rotate(0deg)
    opacity 0.55

  div:first-child:after
    transform rotate(45deg)
    opacity 0.45

  div:nth-child(2):before
    transform rotate(90deg)
    opacity 0.35

  div:nth-child(2):after
    transform rotate(135deg)
    opacity 0.25

  div:nth-child(3):before
    transform rotate(180deg)
    opacity 1

  div:nth-child(3):after
    transform rotate(225deg)
    opacity 0.85

  div:nth-child(4):before
    transform rotate(270deg)
    opacity 0.75

  div:nth-child(4):after
    transform rotate(315deg)
    opacity 0.65
