.account-details-page__alert
  margin-bottom 20px

.account-details-page__form
  max-width 500px
  margin 0px auto
  padding 50px 0
  +below(mobile-cutoff)
    margin 0 10px
    padding 20px 0

.account-details-page__row
  width 100%
  margin-bottom 20px

.account-details-page__row input
  width 100%
  box-sizing border-box

.account-details-page__split-column
  width 50%
  box-sizing border-box
  margin-bottom 20px
  display inline-block
  vertical-align top
  &:first-child
    padding-right 10px

.account-details-page__split-column input
  width 100%
  box-sizing border-box


.account-details__phone-disclaimer
  padding-bottom 10px
  margin-top -10px
  a
    color $laurel
