single-column-cutoff = 700px
$basketMaxWidth = 850px

@import './components/add_to_order_controls/index.styl'
@import './components/attended_delivery_warning'
@import './components/basket_item/index.styl'
@import './components/checkout_buttons/index.styl'
@import './components/dropdown_selector/index.styl'
@import './components/empty_basket/index.styl'
@import './components/fulfillment_day_dropdown/index.styl'
@import './components/fulfillment_offer_dropdown/index.styl'
@import './components/masquerade_overrides/index.styl'
@import './components/banner_min_order/index.styl'

.basket-page__content
  position relative

.basket-page__modal-info
  margin-bottom 15px

.basket-view, .review-order-view .order
  .receipt
    padding-top 22px
    padding-bottom 23px

.basket-view__warning
  margin-top 48px
  margin-bottom 42px

.basket-page, .subscriptions-page, .review_order_page, .upcoming-orders-view

  .basket-view__fulfillment-header
    raised-tile__header()

    padding-top 10px
    padding-bottom 10px

    +below(mobile-cutoff)
      padding 10px

    label-2()
    .header-cell
      display inline-block

  .basket-view__delivery-header-icon
    font-size 22px
    margin-right 12px

    +below(tablet-cutoff)
      display none

  .basket-view, .review-order-view .order
    .subtotal, .receipt
      label
        padding-top 4px
        padding-right 12px

    .receipt
      display inline-block

      float right
      label
        float left
      .usd
        float right
      .delivery
      .discounts
      .credit
        margin-top 11px
      .total
        margin-top 13px
        .usd
          font-size 24px


  .basket-view, .review-order-view .order
    .usd.negative
      margin-right 5px

      &:before
        content ' '

    .subtotal
      label, .usd
        float right

    .fulfillment
      raised-tile()
      margin-bottom 2em

      &:last-child
        margin-bottom none

    .payment-details
      .icon-card
        margin-right 9px

      .link
        display block
        margin-top $gutter
        font-size 12px
        letter-spacing 0.5px

  .basket-view
    max-width $basketMaxWidth
    margin 0 auto

    +below($basketMaxWidth + 16px)
      margin 0 8px


  .basket-view__fulfillment
    margin-bottom 50px

    +below(tablet-cutoff)
      margin-bottom 30px


  .summary-items__totals
    float right
    margin-bottom 15px
    text-align right

  .summary-items__total-row
    title-3()
    margin-bottom 10px

  .summary-items__total-label
    label-1()
    width 8rem

  .summary-items__total-number
    display inline-block
    width 6rem

  .basket-view__page-title
    display-1()
    display inline-block

  .basket-view__page-subtitle
    body-1()
    line-height 1.5em /* FIXME The stylguide needs to make up its mind about this */


  .basket-view__header
    margin 20px 0
    & p
      font-size 14px
      font-family Futura
      color $white
      flex 1
      & a
        text-decoration underline
        cursor pointer
      & a:hover
        color $white



  .basket-changes
    margin 0 0 $gutter
    .change
      color $tomato
      body-1()

  .basket-changes__list
    margin 0
    padding 0 0 0 12px

  .basket-changes__list.single
    padding 0
    list-style-type none

  .basket-changes__list-item
    padding-bottom 8px

  .basket-changes__list-item:last-child
    padding-bottom 0

  .basket-view__fulfillment-footer
    raised-tile__body()
    padding-bottom 35px

  .basket-view__checkout
    position relative
    clearfix()

  .basket-view__security-promise
    position absolute
    left 0
    bottom -6px /* So that the bottom of the text lines up with the
                   bottom of the container, even with the CHECK OUT
                   FOR MONDAY button */
    margin-top 20px

    +below(mobile-cutoff)
      position static
      text-align center

  .basket-view__secure-lock
    display inline-block
    margin-right 0.5em
    font-size 20px
    vertical-align -1px
    color $oyster

  .basket-view__secure-label
    text-transform uppercase
    font-size 10px
    color $oyster
    letter-spacing 0.1em
    display inline-block





  .sanitize-changes__icon
    +below(mobile-cutoff)
      display none

  .basket-view__everything-available-separator
    margin 0 20px

    +below(tablet-cutoff)
      margin 0 15px

  /* Unavailable items */

  .unavailable-basket-items
    border 1px solid $silt
    border-width 1px 0
    background $eggshell
    padding 0 20px 20px 20px

    +below(mobile-cutoff)
      padding 0 15px 15px 15px

    .summary-item__photo
      background white
      img
        opacity 0.3

    .summary-item__name
      title-1()
      line-height 1.25em

    .summary-item__base-price, .summary-item__quantity-summary
      visibility hidden

    +below(mobile-cutoff)
      .summary-item__quantity-controls, .summary-item__price
        display none

  .unavailable-basket-items__header
    caption-1()

    border-bottom 1px solid $silt
    $paddingFromLineHeight = 6px
    padding 20px - $paddingFromLineHeight 0px
    margin 0 0 20px 0

    +below(tablet-cutoff)
      margin-bottom 15px
      padding 15px - $paddingFromLineHeight 0px
      margin 0 0 15px 0

  .unavailable-basket-items__heading
    label-2()
    margin-right 1em

    +below(tablet-cutoff)
      display block

.basket-view .availability-popover__container
  margin-left 20px
  display inline

.basket-view, .subscription-view.editing

  .summary-item__name

    +above(single-column-cutoff)
      overflow hidden
      text-overflow ellipsis
      white-space nowrap

.basket-view__header-delivery-options
  color $oyster
  form-1()
  float right
  padding 12px 0 8px 0

.basket__fulfillment-offer-dropdown-selector
  margin-left 14px
  +below(mobile-cutoff)
    margin-left 8px

.basket-view__promocode-section
  margin-top 25px
  text-align right
  font-size 16px

  +below(mobile-cutoff)
    font-size 12px

.basket-view__promocode-label
  padding-top 25px
  display inline-block

  +below(mobile-cutoff)
    padding-top 16px
