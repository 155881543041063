.account-page__subscription-schedule__error
  margin-bottom 30px

.account-page__subscription-schedule__deliveries
  display table
  border-collapse collapse
  width 100%

  +below(mobile-cutoff)
    display block

.account-page__subscription-schedule__delivery
  display table-row
  border-bottom 15px solid transparent

  &.last-in-week
    border-bottom 30px solid transparent

    &:last-child
      border-bottom 0

  +below(mobile-cutoff)
    display block
    position relative
    border-bottom 0
    margin-bottom 15px

    &.last-in-week
      border-bottom 0
      margin-bottom 30px

.account-page__subscription-schedule__details
  display table-cell
  vertical-align top
  padding-right 85px

  +below(mobile-cutoff)
    display block
    padding 0

.account-page__subscription-schedule__title
  display block

.account-page__subscription-schedule__description
  display block
  color $oyster

.account-page__subscription-schedule__window
  white-space nowrap

.account-page__subscription-schedule__status
  display table-cell
  vertical-align top
  padding-right 85px

  +below(mobile-cutoff)
    display block
    padding 0

.account-page__subscription-schedule__order-link
  link()

.account-page__subscription-schedule__status-text
  display block

.account-page__subscription-schedule__unavailable-customer-reason
  display block
  color $tomato

.account-page__subscription-schedule__controls
  display table-cell
  vertical-align top
  min-width 100px

  +below(mobile-cutoff)
    display block
    position absolute
    top 0
    right 0
    min-width 0

    &.edit-button
      position static
      width 100%
      text-align center
      margin-top 15px
      padding-bottom 15px

      .button
        width 100%

  .button
    margin-top -11px
    +below(mobile-cutoff)
      margin-top 0

  .toggle-switch-container
    margin-top -4px
    +below(mobile-cutoff)
      margin-top 0

  .toggle-switch:checked + label
    background-color $avocado

  .toggle-switch:checked + label:hover
    background-color #CBE3B0

  .toggle-switch + label
    background-color $chanterelle

  .toggle-switch + label:hover
    background-color #F6CB92

.account-page__subscription-schedule__cancel-options
  margin-top 30px

  hr
    margin-bottom 18px

.account-page__subscription-schedule__cancel-option-link
  link()
  font-size 1.25rem
  color $tomato

.account-page__subscription-cancel-controls
  padding 40px 40px 35px
  text-align center
  max-width 670px
  +below(500px)
    padding 40px 10px 35px

.account-page__modal-title
  title-3()
  padding-bottom 20px

.account-page__cancel__cancel-button
  secondary-button()
  margin 7px 10px
  +below(mobile-cutoff)
    margin 7px 0

.account-page__cancel__confirm-button
  button()
  margin 7px 10px
  +below(mobile-cutoff)
    margin 7px 0
