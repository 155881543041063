.static-tag-header
  background-color $toast
  text-align center
  color $black

  padding 22px 60px 28px 60px

  .hr-centered
    border-color white

  .text-area
    max-width 600px
    margin 0 auto

    a
      color white

  h2
    display-2()
    -webkit-font-smoothing antialiased

  .description
    title-2()
    margin-top 10px
    display block

  +below(tablet-cutoff)
    .text-area
      max-width 500px

  // height: 160px with the line
  +below(450px)
    .description
      display none

// tags that have decsription and special background
.static-tag-header.improved-tag
  background-blend-mode multiply
  background-size cover
  background-repeat no-repeat
  background-position 50%
  +below(mobile-cutoff)
    &.certified-organic
      background-image url('/img/tags/tagmobile_certified_organic.jpg?auto=format')
    &.dairy-free
      background-image url('/img/tags/tagmobile_dairy_free.jpg?auto=format')
    &.kosher-certified
      background-image url('/img/tags/tagmobile_kosher_certified.jpg?auto=format')
    &.gluten-free
      background-image url('/img/tags/tagmobile_gluten_free.jpg?auto=format')
    &.grass-fed
      background-image url('/img/tags/tagmobile_grass_fed.jpg?auto=format')
    &.non-gmo
      background-image url('/img/tags/tagmobile_non_gmo.jpg?auto=format')
    &.paleo
      background-image url('/img/tags/tagmobile_paleo.jpg?auto=format')
    &.pasture-raised
      background-image url('/img/tags/tagmobile_pasture_raised.jpg?auto=format')
    &.tree-nut-free
      background-image url('/img/tags/tagmobile_tree_nut_free.jpg?auto=format')
    &.vegan
      background-image url('/img/tags/tagmobile_vegan.jpg?auto=format')
    &.vegeterian
      background-image url('/img/tags/tagmobile_vegetarian.jpg?auto=format')
    &.local
      background-image url('/img/tags/tagmobile_prepared_meals.jpg?auto=format')
    &.values
      background-image url('/img/tags/tagmobile_value_picks.jpg?auto=format')
    &.halloween
      background-image url('/img/tags/tagmobile_halloween.jpg?auto=format')
    &.thanksgiving
      background-image url('/img/tags/tagmobile_thanksgiving.jpg?auto=format')
    &.bundles
      background-image url('/img/tags/tagmobile_bundles.jpg?auto=format')
    &.holidays
      background-image url('/img/tags/tagmobile_holidays.jpg?auto=format')
    &.springholidays
      background-image url('/img/tags/tagmobile_springholidays.jpg?auto=format')
    &.mothersday
      background-image url('/img/tags/tagmobile_mothersday.jpg?auto=format')
    &.memorialday
      background-image url('/img/tags/tagmobile_memorialday.jpg?auto=format')
    &.fathersday
      background-image url('/img/tags/tagmobile_memorialday.jpg?auto=format')
    &.july4th
      background-image url('/img/tags/tagmobile_july4th.jpg?auto=format')
    &.celebrate-summer
      background-image url('/img/tags/tagmobile_celebrate-summer.jpg?auto=format')


  +above(mobile-cutoff)
    &.certified-organic
      background-image url('/img/tags/tag_certified_organic.jpg?auto=format')
    &.dairy-free
      background-image url('/img/tags/tag_dairy_free.jpg?auto=format')
    &.kosher-certified
      background-image url('/img/tags/tag_kosher_certified.jpg?auto=format')
    &.gluten-free
      background-image url('/img/tags/tag_gluten_free.jpg?auto=format')
    &.grass-fed
      background-image url('/img/tags/tag_grass_fed.jpg?auto=format')
    &.non-gmo
      background-image url('/img/tags/tag_non_gmo.jpg?auto=format')
    &.paleo
      background-image url('/img/tags/tag_paleo.jpg?auto=format')
    &.pasture-raised
      background-image url('/img/tags/tag_pasture_raised.jpg?auto=format')
    &.tree-nut-free
      background-image url('/img/tags/tag_tree_nut_free.jpg?auto=format')
    &.vegan
      background-image url('/img/tags/tag_vegan.jpg?auto=format')
    &.vegeterian
      background-image url('/img/tags/tag_vegetarian.jpg?auto=format')
    &.local
      background-image url('/img/tags/tag_prepared_meals.jpg?auto=format')
    &.values
      background-image url('/img/tags/tag_value_picks.jpg?auto=format')
    &.halloween
      background-image url('/img/tags/tag_halloween.jpg?auto=format')
    &.thanksgiving
      background-image url('/img/tags/tag_thanksgiving.jpg?auto=format')
    &.bundles
      background-image url('/img/tags/tag_bundles.jpg?auto=format')
    &.holidays
      background-image url('/img/tags/tag_holidays.jpg?auto=format')
    &.springholidays
      background-image url('/img/tags/tag_springholidays.jpg?auto=format')
    &.mothersday
      background-image url('/img/tags/tag_mothersday.jpg?auto=format')
    &.memorialday
      background-image url('/img/tags/tag_memorialday.jpg?auto=format')
    &.fathersday
      background-image url('/img/tags/tag_memorialday.jpg?auto=format')
    &.july4th
      background-image url('/img/tags/tag_july4th.jpg?auto=format')
    &.celebrate-summer
      background-image url('/img/tags/tag_celebrate-summer.jpg?auto=format')
    &.team-favorites
      background-image url('/img/tags/tag_team_favorites.jpg?auto=format')
