.sign-in-flow__modal-wrapper
  .ui-modal__modal
    background-color white

    +below(mobile-cutoff)
      width 100%
      height 100%
      margin 0

.sign-in-flow__modal
  padding-top 20px
  background-color white

  +above(mobile-cutoff)
    padding-top 25px
    width 366px
    height 535px

  .welcome-layout__back
    margin-top -16px
    margin-left 0

  .sign-in-flow__title
    display-1()
    margin-bottom 28px

    +below(mobile-cutoff)
      display-2()
      margin-bottom 28px

  .flow-social-sign-in__subtitle
  .flow-zip-entry__subtitle
    title-2()
    margin-bottom 26px
    padding 0
    +below(mobile-cutoff)
      title-5()
      line-height 27px
      padding 0

  .flow-zip-entry__subtitle
    +below(mobile-cutoff)
      margin-bottom 55px
