.account-toggle-input
  margin-bottom 20px

.account-toggle-input:last-child
  margin-bottom 0

.account-toggle-input__checkbox
  margin-top 2px
  vertical-align top

.account-toggle-input__title
  form-1()
  margin-left 5px
  display inline-block
  vertical-align top
  color $black

.account-toggle-input__changed-icon
  color $avocado
  margin-left 5px
  vertical-align top

.account-toggle-input__just-changed
  transition opacity 0.1s ease-in-out
  opacity 1

.account-toggle-input__post-changed
  transition opacity 2s ease-in-out
  opacity 0

.account-toggle-input__body
  caption-1()
  color $oyster

  emph
    display block
    font-weight bold
