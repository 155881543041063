.flow-sign-up
  >.sign-in-flow__title
    margin-bottom 35px

  >.sign-in-flow__disclaimer
    margin-bottom 11px

    +below(mobile-cutoff)
      margin-bottom 11px

.flow-sign-up__errors
  width 100%
  margin-bottom 15px

.ui-alert__body
  .link
    text-decoration underline
    cursor pointer
