.payment-details-view

  .payment-details
    .icon-circle-checkmark
      color $mint
      vertical-align middle
      margin-right 5px

  .promo-or-gift-code
    width 100%
    box-sizing border-box

  div.promo-or-gift
    margin-top $gutter
    position relative
    ::-webkit-input-placeholder
      font-style italic

    :-moz-placeholder
      font-style italic

    :-ms-input-placeholder
      font-style italic

    .icon
      position absolute
      right 8px
      top 29px
      color $tomato

  .button.button--checkout
    margin-top 5px
    margin-bottom $gutter

  .card-type-row
    margin-bottom $gutter
    margin-top $gutter
    margin-left 2px

    .card-icons
      float right

.payment-details-view__secure-lock
  display inline-block
  margin-right 0.5em
  font-size 20px
  vertical-align 3px

.payment-details-view__secure-label
  color $oyster
  font-size 12px
  font-family $semiboldFontFamily
  line-height 2em

.payment-details-page
  .credit-card-form
    padding-top 1em
