$padding = 15px

.modal-title-bar
  display flex
  align-items center
  text-align center
  padding-top 8px

  .modal-title-bar__title
    default-title()
    font-family $largeHeadingsFontFamily
    font-size 1.6rem
    color $black
    white-space nowrap
    padding $padding - 2px $padding $padding - 6px $padding // padding tweaked for glyph metrics
    margin-right $padding + 28px // padding + width of the close x
    flex-grow 1

  .modal__close-button
    padding: $padding - 7px $padding $padding - 6px $padding
    font-size 16px
