.sign-up-modal__header
  font-size 24px !important
  line-height 24px !important
  text-align center
  font-weight 800
  font-family "Editor", Helvetica, Arial, sans-serif
  margin-top 30px

.close-modal__icon
  &:hover
    cursor pointer
