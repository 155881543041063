.gift-card-purchase-page
  margin 0 auto
  max-width 748px
  +below(748px)
    margin 0 10px

.gift-card-purchase-page__sign-in-container
  min-height 67px
  text-align right
  +below(mobile-cutoff)
    min-height 35px

.gift-card-purchase-page__global-error
  text-align left
  margin-bottom 25px

.gift-card-purchase-page__sign-in
  body-1()
  color $oyster
  top 37px
  position relative
  +below(mobile-cutoff)
    top 5px

.gift-card-purchase-page__sign-in a,
.gift-card-purchase-page__global-error a
  link()

.gift-card-purchase-page__card
  raised-tile()
  text-align center
  margin 0 auto
  max-width 748px
  padding 40px 60px
  margin-bottom 100px
  +below(700px)
    padding 40px 10px

.gift-card-purchase-page__card-image
  text-align center
  margin-bottom 35px
  max-width 100%

.gift-card-purchase-page__header
  display-2()
  margin-bottom 12px

.gift-card-purchase-page__subtext
  body-3()

.gift-card-purchase-page__divider
  hr()
  margin 35px 0

.gift-card-purchase-page__row
  text-align left
  margin-bottom 20px
  +strictly-above(748px)
    margin-right 40px
  +above(700px)
    layout-row()

.gift-card-purchase-page__row:last-child
  margin-bottom 0

.gift-card-purchase-page__label-column,
.gift-card-purchase-page__centered-label-column
  form-1()
  width 125px
  margin-bottom 5px
  +above(700px)
    text-align right
    margin-bottom 0
    layout-cell()

.gift-card-purchase-page__centered-label-column
  +above(700px)
    top 8px
    position relative

.gift-card-purchase-page__control-column
  text-align left
  +above(700px)
    margin-left 30px
    layout-cell-expanding()

.gift-card-purchase-page__split-column
  box-sizing border-box
  width 100%
  vertical-align top
  margin-bottom 20px
  +strictly-above(748px)
    width 50%
    display inline-block
    &:first-child
      padding-right 10px

.gift-card-purchase-page__input-column
  width 100%

.gift-card-purchase-page__input
  width 100%
  box-sizing border-box

textarea.gift-card-purchase-page__input
  height 100px

.gift-card-purchase-page__delivery-method:first-child
  margin-bottom 10px

.gift-card-purchase-page__delivery-method label,
.gift-card-purchase-page__delivery-method input
  display inline-block
  vertical-align top

.gift-card-purchase-page__delivery-method label
  margin-left 5px
  form-1()
  color $black
  +below(700px)
    width 90%

@import 'web/components/stripe_credit_card_form/index.styl'

