.product-collection-module__products
  margin-bottom 0
  +below(tablet-cutoff)
    margin-bottom 12px

.product-collection-module__products .grid-item
  .product-tile--compact
    +below(mobile-cutoff)
      compact-tile-large()

  +above(tablet-cutoff)
    width calc(25% - 15px)
    display inline-block
    &:nth-child(3n)
      margin-right 20px
    &:nth-child(4n)
      margin-right 0

  +at-and-up-to(mobile-cutoff, tablet-cutoff)
    margin-right 1%
    margin-bottom 8px
    display inline-block
    width grid-item-width(2, 1%)

    &:nth-child(2n)
      margin-right 0


.product-collection-module__footer
  text-align center

.product-collection-module__footer__see-more
  link()

.product-collection-module__footer__see-more__icon
  display inline-block
  vertical-align middle
  margin-right 6px
  border-radius 9px
  width 19px
  height 19px
  text-align center
  font-size 10px
  background-color $aquaponic

.product-collection-module__footer__see-more__text
  body-1()
  line-height 1.65em
  vertical-align top

.product-collection-module__footer__see-all
  display block
  margin-top 10px
  margin-bottom 40px

.product-collection-module__footer__see-all__text
  title-2()
  color: $laurel
  margin 10px 0 50px 0
