.preorder-announcement
  .market-flash-banner
    border-top 1px solid $ricotta
    border-bottom 1px solid $ricotta
    background $brownsoil
    color $ricotta

  .market-flash-banner__body
    color $ricotta

  .button
  .button-is-secondary
    &:not(.disabled)
      color $black
      border 1px solid $ricotta
      background-color $ricotta

      &:hover
        color $black
        background-color $ricotta

      &:active
        background-color $ricotta
        color $black
        border 1px solid $ricotta

      &:focus
        background-color $ricotta
