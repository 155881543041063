.for-you__banner
  display flex
  align-items center
  border-radius 8px
  padding 12px
  background-color $forest
  margin 24px 16px 32px
  @media only screen and (min-width: tablet-cutoff)
    margin 0 0 8px
    padding 16px

.for-you-banner__image
  margin-right 8px

.for-you-banner__description
  body-copy-small()
  font-family $bodyFontFamily
  font-size 12px
  color $white
  flex 1
  margin-left 8px
  font-weight 500
  margin-block 0
  a
    text-decoration underline
    cursor pointer
    &:hover
      color $white
