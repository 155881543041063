.step-header
  background $aquaponic
  clear left
  white-space nowrap
  text-align center

.step-header__content
  max-width 520px
  margin 0 auto
  padding 18px 0
  overflow hidden

.step-header__step
  label-1()
  padding 5px
  width 70px
  text-align center
  color $oyster
  display inline-block

.step-header__step-number
  border-radius 30px
  border 1px solid $oyster
  color $oyster
  text-align center
  margin 0 auto 8px
  background $aquaponic
  box-sizing border-box
  width 30px
  height 30px
  padding-top 8px
  line-height 1em

  +below(mobile-cutoff)
    width 20px
    height 20px
    padding-top 3px
    line-height 1.2em

.step-header__spacer
  border-top 1px solid $oyster
  display inline-block
  max-width 114px
  width 20%
  margin 0 -25px 0 -25px
  height 34px

  +below(mobile-cutoff)
    margin 0 -31px 0 -31px
    height 29px

.step-header__spacer.is-visited
  border-color $laurel

.step-header__step.is-selected-step
  color $black
  .step-header__step-number
    background-color $laurel
    color $ricotta
    border-color $laurel

.step-header__step.is-completed-step, .step-header__step.is-visited-step
  color $black
  .step-header__step-number
    background $aquaponic
    border-color $laurel
    color $laurel
