.subscription-info-modal
  max-width 550px
  form-1()
  padding 60px 60px 20px
  position relative
  +below(mobile-cutoff)
    padding 25px 0 20px
    font-size 13px


.subscription-info-modal__close-button
  font-size 30px
  color $oyster
  position absolute
  right 25px
  top 25px
  cursor pointer
  +below(mobile-cutoff)
    display none

.subscription-info-modal__title
  display-1()
  padding 0 0 15px
  +below(mobile-cutoff)
    title-4()
    padding 0 0 20px 25px

.subscription-info-modal__content
  border-top 1px solid $silt
  border-bottom 1px solid $silt
  padding 26px 0 38px 0
  a
    link()
  +below(mobile-cutoff)
    padding 0 25px 0
    border-top 0 none
    border-bottom 0 none

.subscription-info-modal__item
  list-style none
  padding 0
  display flex
  flex-direction row
  align-content flex-start
  margin-bottom 25px

.subscription-info-modal__item__icon
  display flex
  color $black
  font-size 12px
  justify-content center
  line-height 26px
  width 26px
  height 26px
  border-radius 13px
  background-color $aquaponic
  flex 0 0 26px

.subscription-info-modal__item__text
  display flex
  flex-direction column
  vertical-align top
  margin-left 15px
  flex 1

  h3
    title-1()
    margin-top 0
    margin-bottom 5px
    line-height 25px
    +below(mobile-cutoff)
      form-1()
      font-size 13px
      margin-top 5px
  p
    margin 0
    line-height 25px
    +below(mobile-cutoff)
      color $oyster
      line-height 18px

.subscription-info-modal__faq-blurb
  margin 35px 0 0
  +below(mobile-cutoff)
    margin 25px 0 0

.subscription-info-modal__footer
  padding-top 20px
  text-align center

.subscription-info-modal__ok-button
  button()
  width 120px

