@import '../../css/site/overlay_box.styl';

/* dimmed background layer */

.ui-modal__layer
  display none
  position fixed
  left 0
  top 0
  right 0
  bottom 0
  width 100%
  height 100vh
  background-color rgba(0,0,0,0.5)
  z-index $zindexModal
  overflow-y scroll

  &.ui-modal__layer-is-shown
    display block


/* containers that uses table display so a box can be centered vertically */

.ui-modal__vertical-align-container,
.ui-modal-full-screen-mobile__vertical-align-container
  display table
  width 100%
  height 100%

.ui-modal-full-screen-mobile__vertical-align-container
  +below(mobile-cutoff)
    display block


.ui-modal__vertical-align-cell,
.ui-modal-full-screen-mobile__vertical-align-cell
  display table-cell
  vertical-align middle
  text-align center

.ui-modal-full-screen-mobile__vertical-align-cell
  +below(mobile-cutoff)
    display block
    height 100%


/* the modal itself */

.ui-modal__modal,
.ui-modal-full-screen-mobile__modal
  overlay-box()
  margin 20px
  display inline-block
  text-align left
  position relative

.ui-modal-full-screen-mobile__modal
  +below(mobile-cutoff)
    margin 0
    box-sizing border-box
    display block
    min-height 100%
    border-radius 0
    box-shadow none

.ui-modal__x
  font-size 23px
  padding 20px
  color $oyster
  cursor pointer
  position absolute
  top -8px
  right 0
  z-index $zindexModal + 1

  &:hover
    color $sage

  &:active
    color $laurel

.ui-modal__refresh
  .modal-header
    padding-top 32px
  .modal__title
    font-family Editor
    font-style normal
    font-weight 800
    font-size 24px
    line-height 30px
    display flex
    flex-direction column
    align-items center
    justify-content center
    color #333333
    margin-top 25px
  .modal-content
    padding-bottom 32px
    box-sizing border-box
    position relative
    text-align center
    min-width 261px
    display flex
    flex-direction column
    flex-grow 1
    .modal-content__message
      font-family $bodyFontFamily
      font-style normal
      font-weight normal
      font-size 16px
      line-height 22px
      text-align center
      color #666
      margin-bottom 32px
      + .modal-content__cta
        margin-top 0
    h2
      font-family $largeHeadingsFontFamily
      font-size 1.6rem
      text-align center
      line-height 1.4
      margin-bottom 1rem
      &.align-left
        text-align left
      &.thin
        font-family $bodyFontFamily
    p
      body-1()
      body-1-paragraph()
      font-size 16px
    .text-link
      font-weight 800
      line-height 1.5
      color $black
      text-decoration underline
      margin-top 1rem
      letter-spacing initial
    .modal-content__cta
      margin-top 20px
      button
        margin-bottom 32px
        min-width 261px
        +below(mobile-cutoff)
          width 100%
      p
        font-size 16px
        margin-top 0
        margin-bottom 32px
        &:last-child
          margin-bottom 0
    margin-bottom 0
    .warning
      color $error
  .modal-footer
    padding 0 32px 32px
    text-align center
  .button
    refresh-button()
  .button-outline
    refresh-button-outline()
    margin-bottom 10px

  .link-weighted
    font-weight 700
