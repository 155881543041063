
// page elements relative spacings below
.reset-password-page
  .reset-password-page__title
    margin-top: 36px
    margin-bottom 36px
  .reset-password-page__message
    margin-bottom 24px
  .reset-password-page__form-row
    margin-bottom 32px
  .reset-password-page__form-submit
    margin-bottom 22px

// individual element styles below
.reset-password-page__form
  max-width 300px
  margin auto

.reset-password-page__alert
  margin-bottom 15px

.reset-password-page__title
  display-1()
  font-size 32px
  line-height 38px
  font-weight 800
  display flex
  justify-content center
  font-family "Editor", Helvetica, Arial, sans-serif
  margin 36px auto
  +below(mobile-cutoff)
    font-size 24px
    line-height 30px

.reset-password-page__message
  text-align center
  font-size 16px

.reset-password-page__form-row-input
  height 40px
  border-radius 0px
  font-style normal
  font-weight normal
  font-size 16px
  line-height 40px
  padding 8px 0
  width 100%
  box-sizing border-box
  &,
  &:hover,
  &:focus
    border-bottom-color $silt
    border-width 0 0 1px 0
  &.valid
    border-bottom-color $cornflowerBlue
  &.invalid
    border-bottom-color $error
    color: $error

.reset-password-page__form-submit
  refresh-button()
  display block
  width 100%
  background $brownsoil
  text-transform initial
  &:hover,
  &:focus
    background $cornflowerBlue
  &:disabled
    background $oyster
    cursor not-allowed
