.welcome-page__container
  display flex
  flex-direction column
  align-items center
  background-color $black
  font-family $essonnessDisplayFontFamily
  font-size 1.4rem
  font-weight 300
  color $white

.welcome-page__button
  background-color $white
  color: $black
  border none
  cursor: pointer
  font-family $outfitFontFamily
  font-size 0.875rem
  font-weight 500
  line-height 130%
  letter-spacing 0.22rem
  text-transform uppercase

.welcome-page__button--small
  width 12%
  max-width 138px
  min-width 75px
  padding 12px 1px

.welcome-page__button--large
  width 22%
  max-width 248px
  min-width 148px
  padding 21px 1px

.welcome-page__button--shop-local
  +at-and-below(mobile-cutoff)
    margin-bottom 10px

  +above(mobile-cutoff)
    margin-right 25px

.welcome-page__button--shop-nationwide
  +at-and-below(mobile-cutoff)
    margin-top 10px

  +above(mobile-cutoff)
    margin-left 25px

.welcome-page__hero-container
  display flex
  flex-direction column
  align-items center
  width 100%
  background-image url('/img/web/welcome/hero-background.jpg?auto=format&height=1000')
  background-position center
  background-repeat no-repeat
  background-size cover

.welcome-page__hero-content-container
  display flex
  flex-direction column
  width 80%
  max-width 1140px
  margin 30px 0

.welcome-page__hero-header-top-container
  display flex
  flex-flow row wrap
  justify-content center
  align-items center
  position relative

.welcome-page__hero-header-logo
  cursor pointer
  max-width 120px

.welcome-page__button-sign-in
  position absolute
  right 0

.welcome-page__hero-header-text
  align-self center
  margin-top 20px
  font-size 1.875rem
  font-weight 400
  line-height 140%
  font-style italic

.welcome-page__hero-tagline
  margin-top 180px
  margin-bottom 30px
  font-size 3.75rem
  font-weight 700
  line-height 116%

.welcome-page__hero-shop-button-container
  display flex
  flex-direction column
  justify-content flex-start

  +above(mobile-cutoff)
    flex-direction row

.welcome-page__hero-annotation
  font-size 0.75rem
  font-weight 500
  line-height 135%
  margin-top 60px

.welcome-page__content-container
  display flex
  flex-direction column
  align-items center
  width 80%
  max-width 1140px
  margin-top 80px
  margin-bottom 300px

.welcome_page__who-we-are
  margin-bottom 90px
  font-family $outfitFontFamily
  font-size 1.141rem
  font-weight 300
  line-height 135%
  text-align center

.welcome_page__who-we-are-goodeggs
  font-weight bold

.welcome-page__divider
  width 100%
  border 1px solid $white
  box-sizing border-box

.welcome-page__our-recipes-container
  display flex
  flex-direction column
  align-items center
  margin-top 50px
  margin-bottom 150px

.welcome-page__our-recipes-header
  margin-bottom 100px
  font-size 1.875rem
  font-weight 400
  line-height 140%
  font-style italic

.welcome-page__recipe-container
  display flex
  flex-direction row
  justify-content space-between
  align-items center

.welcome-page__recipe-description-container
  width 45%

.welcome-page__recipe-description-header
  font-size 2rem
  font-weight 700
  line-height 100%
  margin-bottom 16px
  overflow-wrap break-word

  +above(mobile-cutoff)
    font-size 2.75rem

.welcome-page__recipe-description-text
  line-height 120%

.welcome-page__recipe_image
  width 45%
  max-width 545px

.welcome-page__reviews-container
  width 100%
  margin-top 50px
  margin-bottom 125px

.welcome-page__reviews_header
  margin-bottom 125px
  font-size 1.875rem;
  font-weight 400;
  font-style italic
  line-height 140%
  text-align center

.welcome-page__reviews-carousel
  max-width 1140px

  .slick-prev
  .slick-next
    width 26px
    height 57px

.welcome-page__reviews-carousel-slide
  width 100%

.welcome-page__reviews-carousel-slide-text
  margin 0 10px 50px 10px
  font-size 2rem
  font-weight 300
  line-height 140%
  text-align center

.welcome-page__reviews-carousel-slide-logo
  max-width 272px
  margin 0 auto

.welcome-page__get-started-container
  display flex
  flex-direction column
  align-items center
  width 100%
  margin-top 85px


.welcome-page__get-started-header
  margin-bottom 40px
  font-size 3.75rem
  font-weight 700
  line-height 116%
  text-align center

.welcome-page__get-started-button-container
  display flex
  flex-direction column
  justify-content center

  +above(mobile-cutoff)
    flex-direction row
