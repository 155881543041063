.terms-page__column-nav
  float left
  width 200px
  +at-and-below(tablet-cutoff)
    float none

.terms-page__nav-list
  margin-top 50px
  list-style none
  padding 0
  +at-and-below(tablet-cutoff)
    margin-top 30px

.terms-page__nav-element
  margin-top: 10px
  form-1()

.terms-page__nav-element-current
  form-2()
