@import '../../components/social_sign_in/index.styl'

//set width for mobile and desktop
.sign-in-page
  padding 0 15px
  &.narrow-page
    margin auto
    padding 0 40px
    .sign-in-form
      max-width 300px
      margin auto

.sign-in-form__error
  color $error

.sign-in-page__errors
  margin-bottom 15px
  max-width: 320px;

.sign-in-page__button-area
  text-align center
  margin $gutter*2 0

  .btn
    width calc(50% - 5px)
    box-sizing border-box

    &.active
      background $laurel
      color $eggshell

    i
      font-size 15px
      line-height 15px
      margin-right 7px

    &.sign-up
      margin-right 10px

.sign-in-page__intro
  body-1()
  margin 20px 0

.sign-in-page__sign-out-first
  max-width 500px
  margin 0 auto
  padding 100px 20px 110px

.sign-in-page__sign-out-first-copy
  form-1()
  margin 4px 0

.sign-in-page__sign-out-first-link
  link()

.sign-in-page
  .social-sign-content
    margin-bottom 32px

//below is the the css for the text on the page
.sign-in-page
  h1
    font-size 32px
    line-height 38px
    font-weight 800
    display flex
    justify-content: center;
    font-family "Editor", Helvetica, Arial, sans-serif
    margin 36px auto
    +below(mobile-cutoff)
      font-size 24px
      line-height 30px
  .ge-separator
    text-align center
    width 20%
    margin auto
    margin-bottom 20px
    font-size 14px

  .ge-separator__content
    flex 0.6

    +below(tablet-cutoff)
      flex 1

.sign-in-page__text
  font-size 16px
  margin-bottom 16px
  text-align center
  &:first
    height: 40px
  a
    form-link()

p.sign-in-page__text
  margin-bottom 32px