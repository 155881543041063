.toggle-switch
  display none

.toggle-switch + label
  background-color $silt
  border-radius 14px
  color $oyster
  cursor pointer
  display inline-block
  height 25px
  line-height 25px
  text-align center
  width 50px
  vertical-align middle
  position relative
  user-select: none

.toggle-switch:checked + label
  background-color $avocado
  color $ricotta

.toggle-switch + label::before
  transition-property left
  transition(0.3s ease)
  background-color $ricotta
  border-radius 14px
  content ''
  display inline-block
  height 19px
  left 3px
  position absolute
  top 3px
  width 19px
  vertical-align middle

.toggle-switch:checked + label::before
  left 28px

.no-touch .toggle-switch + label:hover
  background-color #E3E3E3

.no-touch .toggle-switch:checked + label:hover
  background-color #CBE3B0

.toggle-switch:disabled
  background-color $silt

  + label
    cursor: default
