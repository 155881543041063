.ios-promo-page__body
  background-color #FFF
.ios-promo-page
  background-color #FFF
  text-align center
  +above(tablet-cutoff)
    background url('/img/nettle/app_promo/mobile_app_bg.jpg?auto=format') no-repeat
    background-size cover
    background-position center center
    min-height 600px
    width 100%
    padding 85px 0 0 0

.ios-promo-grid
  margin 0 auto
  width 320px
  +above(tablet-cutoff)
    width 600px

.ios-promo-grid__left,.ios-promo-grid__right
  +above(tablet-cutoff)
    width 50%
    display inline-block

.ios-promo-grid__left
  vertical-align top
  text-align center
  padding-top 30px
  h3
    body-4()
    font-weight bold
    font-size 24px;
    margin-top 0
    margin-bottom 23px
    +above(tablet-cutoff)
      font-weight bold
      font-size 32px;

  +above(tablet-cutoff)
    padding-top 120px

.ios-promo-grid__sms
  +below(tablet-cutoff)
    display none

.ios-promo-grid__sms-success
  margin-bottom 30px
  a
    color $sage

.ios-promo-grid__sms form
  margin-bottom 10px

.ios-promo-grid__sms__input
  width 160px

.ios-promo-grid__sms__send-button
  margin-left: 10px

.ios-promo-grid__sms__submit-hidden
  display none

.ios-promo-grid__sms__subtext
  body-1()
  color $oyster

.ios-promo-grid__sms__sub-subtext
  body-1()
  font-size 11px
  color $oyster
  margin-bottom 30px

.ios-promo-grid__sms__input-error
  color $tomato
  caption-1()

.ios-promo-grid__sms-success
  body-1()
  color $oyster

.ios-promo-grid__left__app-store
  +below(tablet-cutoff)
    margin-top 23px

.ios-promo-grid__left__app-store.desktop
  +below(tablet-cutoff - 1)
    display none

.ios-promo-grid__left__app-store.mobile
  +above(tablet-cutoff)
    display none

.ios-promo-grid__left__download-button
  +above(tablet-cutoff)
    display none

img.ios-promo-grid__left__app-store__image
  width 152px

.ios-promo-grid__right
  display: inline-flex
  align-items: center
  justify-content: center

  +above(tablet-cutoff)
    text-align left
    margin-left: 10%
    width: 40%

img.ios-promo-grid__right__iphone6-mock
  margin 0 auto
  width 310px
  +below(tablet-cutoff)
    margin-bottom 100px
    margin-top 10px

.download-the-app-container.mobile
  +above(tablet-cutoff)
    display none

.ios-promo-grid__sms
  .js-input-mobile-number
    text-align: center
    border: 1px solid $black
    font-family: 'Graphik'
    padding: 14px 10px
    width: calc(100% - 32px)
    border-radius: 25px
    font-size: 16px

  button.js-send-sms-button
    width: calc(100% - 17px)
    background: $black
    font-family: 'Gothik', sans-serif
    text-transform: capitalize
    padding: 8.5px 0
    font-size: 15px
    border-radius: 25px
    margin: 10px 0 5px

.download-the-app-container
  background: white
  position: sticky
  padding: 12px 0
  bottom: 0

  a > div
    background: $black
    font-weight: normal
    font-family: 'Graphik'
    text-transform: capitalize
    font-size: 16px
    width: calc(100% - 32px)
    padding: 6px 0

.mobile-number-form-sms
  display: flex;
  flex-direction: column;
  width: 100%;
