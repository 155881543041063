@import '../jujube/styleguide/nettle/colors.styl'
@import '../jujube/styleguide/font_families.styl'

$desktopFooterHeight = 400px
$mobileFooterHeight = 1120px

$desktopMinimalFooterHeight = 54px + 284px + 30px // footer + b-corp seal + padding
$mobileMinimalFooterHeight = 1099px

html
  font-size 12px
  line-height 18px
  min-height 100%
  position relative

  body
    margin-bottom $mobileFooterHeight
    +above(tablet-cutoff)
      margin-bottom $desktopFooterHeight

body
  margin 0
  font-family $bodyFontFamily
  background-color white
  color $black

body.no-margin
  margin 0

html.with-fixed-minimal-footer
  position relative

  +below(tablet-cutoff)
    min-height 100%

  body
    margin-bottom $mobileMinimalFooterHeight
    +above(tablet-cutoff)
      margin-bottom $desktopMinimalFooterHeight

h1, h2, h3, h4, h5, h6
  font-weight normal

b
  font-weight bold

a:link, a:visited, a:active
  text-decoration none

a:link, a:visited
  color inherit

a:hover, a:focus
  color $dice
  text-decoration underline

a:active
  color $laurel

i:hover, span.icon
  text-decoration none

input, input:focus, textarea, textarea:focus
  outline none

.page-boundary
  max-width desktop-cutoff
  margin 0 auto

// Disable spinner arrows for chrome and firefox number fields
input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
