layout-row()
  display -ms-flexbox
  display flex

layout-cell-expanding()
  display inline-block
  -ms-flex 1 1
  flex-grow 1
  -ms-flex 1 1 100%

layout-cell()
  display inline-block
  -ms-flex 1 1

.layout-row
  layout-row()

.layout-cell
  layout-cell()

.layout-cell--expanding
  layout-cell-expanding()
