.survey-sticky-overlay
  width 100%
  max-width 352px
  display flex
  position fixed
  top 130px
  right 0px
  margin-right 24px
  z-index 9999

  +above(full-hd-cutoff)
    margin 0
    left 70%
    transform translateX(-30%)

  +below(mobile-cutoff)
    max-width 352px + 32px
    margin 0
    padding 16px
    bottom 0
    top auto
    left 50%
    transform translateX(-50%)
    box-sizing border-box

.survey-sticky-overlay-container
  display flex
  width 100%
  border-radius 6px
  border 1px solid $oyster
  background $eggshell
  max-width 352px
  flex-direction column
  padding 16px
  justify-content center
  align-items center

.survey-sticky-overlay__image-header
  width 100%
  height 120px
  object-fit cover
  border-radius 2px
  background-blend-mode darken

.survey-sticky-overlay__title-container
  padding 16px 20px 0

.survey-sticky-overlay__title-container-label
  color #000
  text-align center
  text-transform: none
  font-family: $concretteFontFamily
  font-size 20px
  font-style normal
  font-weight 600

.survey-sticky-overlay__discount-container
  padding 16px 8px 0

.survey-sticky-overlay__discount-container-label
  color #306043
  text-align center
  text-transform: none
  font-family $groteskFontFamily
  font-size 16px
  font-style normal
  font-weight 400

.survey-sticky-overlay__button-container
  display flex
  width 100%
  flex-direction row
  gap 16px
  align-items center
  justify-content space-between
  height 34px
  padding-top 24px
  div
    display flex
    width 100%
    height 34px
    align-items center
    justify-content center
    flex-grow 1
    flex-basis 0
  
  a
    width 100%
    height 34px
    display flex
    align-items center
    justify-content center


.survey-sticky-overlay__button-container .dismiss-button
  width 100%
  height 34px
  display flex
  align-items center
  justify-content center
  color #306043
  font-family: $groteskFontFamily;
  font-size 16px
  font-style normal
  font-weight 400

.survey-sticky-overlay__button-container .take-quiz-button
  width 100%
  height 34px
  display flex
  align-items center
  justify-content center
  color #fff
  font-family $groteskFontFamily
  font-size 14px
  font-style normal
  font-weight 400
