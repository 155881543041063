.account-page__link
  link()

.account-page__alert
  margin-bottom 20px

.account-page__section
  padding 30px
  border-bottom 1px solid $silt

.account-page__section:last-child
  border-bottom none

.account-page__section-title
  margin-top 0
  form-2()

.account-page__section-body
  form-1()

.account-page__button
  skinny-secondary-button()
  padding-left 15px
  padding-right 15px
  float right

.account-page__section-note
  caption-1()
  color $oyster
  margin-bottom 20px
  emph
    font-weight bold
    margin-right 4px

.account-page__error
  margin-bottom 10px
  a
    link()
