.search-as-you-type
  padding 3rem 0
  background-color white
  position absolute
  width calc(100% - 6rem)
  z-index 1
  @media screen and (min-width: 1023px)
    margin-top 2rem
    border-radius 8px
  @media screen and (max-width: 1022px)
    width 100%
    padding 1rem 0
    position relative
    min-height calc(100vh - 120px)
    box-sizing border-box
  .search-as-you-type__item
    font-size 1.334rem
    font-family 'Graphik'
    color $black
    height 40px
    padding 0 3rem
    display flex
    align-items center
    gap 16px
    &.active
      background-color $pearl
    @media screen and (max-width: 1022px)
      padding 0 2rem
    &:hover
      background-color: lighten($pearl, 30%)
      cursor pointer
    em
      font-family 'Graphik Semibold'
      font-style normal