.confirm-address-modal__controls
  padding 3.75em
  padding-bottom 5em
  max-width 34.375em // arbitrary - to constrain the 100% width errors alert
  +below(500px)
    padding 2.5em
    padding-bottom 6em

.confirm-address-modal__title
  default-title()
  display-1()
  padding-bottom 1.25em

.confirm-address-modal__body
  form-1()
  padding-bottom 2.5em

.confirm-address-modal__button-row
  float right

.confirm-address-modal__confirm-button
  link()
  cursor pointer
  margin-right 2.5em
  color $tomato
  display inline-block
  form-1()

  &:hover
    color $tomato

  +below(500px)
    padding-bottom 0.625em

.confirm-address-modal__cancel-link
  link()
  cursor pointer
  color $oyster
  display inline-block
  form-1()

  &:hover
    color $oyster
