.split-price
  letter-spacing -0.5px
  font-size 20px
  font-weight 500

.split-price__cents
  margin-left 1px
  letter-spacing 0.9px
  position relative
  bottom 8px
  font-size 10px
  font-family $semiboldFontFamily

.split-price--large
  letter-spacing -0.5px
  font-size 48px
  line-height 48px

.split-price--large__cents
  margin-left 1px
  letter-spacing 0.9px
  position relative
  bottom 19px
  font-size 22px
  font-family $semiboldFontFamily

.highlight
  color $error
