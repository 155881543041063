.popular-items
  margin-top 16px
  display grid
  row-gap 24px
  column-gap 8px
  grid-template-columns 1fr 1fr 1fr
  grid-template-rows: 1fr 1fr
  @media screen and (max-width: 1022px)
    display flex
    flex-direction column
          

.popular-item
  @media screen and (max-width: 1022px)
    display flex
    align-items center
    gap 16px
  .popular-item__image
    border-radius 8px
    max-height 121px
    @media screen and (max-width: 1022px)
      max-height 80px

  .popular-item__name
    font-size 1.334rem
    font-family 'Graphik'
    max-width 192px
    font-weight 400
    color $black


  .popular-item__producer-name
    font-size: 1rem
    font-weight: 300
    font-family 'Graphik'
    color #979797

.popular-item__skeleton
  & .popular-item__skeleton__image
    border-radius 8px
  @media screen and (min-width: 1023px)
    max-width 196px
  @media screen and (max-width: 1022px)
    display flex
    align-items center
    gap 16px
    .popular-item__skeleton__info
      display flex
      flex-direction column
      width 200px
    & .popular-item__skeleton__image
      width 196px 
