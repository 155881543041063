.alcohol_terms__link
  color $laurel

.alcohol_terms__terms-header
  text-align center

.alcohol_terms__note
  font-style italic

.alcohol_terms__underlined
  text-decoration underline
