.personalized-home-page__module.personalized-home-page__module-featured-categories
  inverted-personalized-home-page-module()

.featured-categories-module__categories
  +below(mobile-cutoff)
    padding 0 8px

.featured-categories-module__category-tile
  box-shadow(0 2px 8px 0 rgba(0, 0, 0, 0.1))
  border-radius 8px
  display inline-block
  box-sizing border-box
  width grid-item-width(5, 20px)
  margin 0 20px 20px 0
  cursor pointer

  &:hover
    transition box-shadow .1s
    box-shadow(0 4px 20px 0 rgba(0,0,0,0.2))

  &:nth-child(5n+5)
    margin-right 0

  +below(tablet-cutoff)
    width grid-item-width(2, 8px)
    margin-right 8px
    margin-bottom 8px

    &:nth-child(5n+5)
      margin-right 8px

    &:nth-child(2n)
      margin-right 0

.featured-categories-module__category-tile__image
  display block
  width 100%

.featured-categories-module__category-tile__title
  body-3()
  height 50px
  text-align center
  line-height 50px !important /* competing with responsive line height */
  background-color white
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  padding 0 10px
