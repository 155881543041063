@import './components/header/index.styl'
@import './components/mobile_menu/index.styl'
@import '~react-toastify/dist/ReactToastify.css'

.Toastify__toast--default
  background-color $eggshell
  color $black

.Toastify__progress-bar--default
  background $eggshell

.Toastify__toast 
  border-left: 4px solid $avocado;

.Toastify__close-button {
  align-self: center;
}

.modal-content__shopping-notification
  padding-left 32px
  padding-right 32px