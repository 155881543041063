display-3()
  font-family $largeHeadingsFontFamily
  font-size 5.063rem
  line-height 1.185em
  letter-spacing -0.4px
  text-transform none
  -webkit-font-smoothing antialiased
  +below(tablet-cutoff)
    font-size 4rem
    line-height 1.25em
  +below(mobile-cutoff)
    font-size 3.417rem
    line-height 1.171em

display-2()
  font-family $largeHeadingsFontFamily
  font-size 3.375rem
  line-height 1.185em
  letter-spacing -0.2px
  text-transform none
  -webkit-font-smoothing antialiased
  +below(tablet-cutoff)
    font-size 2.833rem
    line-height 1.058em
  +below(mobile-cutoff)
    font-size 2.25rem
    line-height 1.333em

form-1()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 1.25rem
  line-height 1.25em
  letter-spacing 0.2px
  text-transform none

form-2()
  -webkit-font-smoothing antialiased
  font-family $semiboldFontFamily
  font-size 1.25rem
  line-height 1.25em
  letter-spacing 0.2px

caption-1()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 1.083rem
  line-height 1.333em
  letter-spacing 0.2px
  text-transform none

minitext-1()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 0.9167rem
  line-height 1.042em
  letter-spacing 0.25px
  text-transform uppercase

display-1()
  font-family $largeHeadingsFontFamily
  font-size 2.25rem
  line-height 1.1em
  letter-spacing 0
  text-transform none
  -webkit-font-smoothing antialiased
  +below(tablet-cutoff)
    font-size 2rem
    line-height 1.1em
  +below(mobile-cutoff)
    font-size 1.667rem
    line-height 1.2em

title-5()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 2.25rem
  line-height 1.1em
  letter-spacing 0.3px
  +below(tablet-cutoff)
    font-size 2rem
    line-height 1.1em
  +below(mobile-cutoff)
    font-size 1.667rem
    line-height 1.2em

title-5-secondary()
  title-5()
  font-family $semiboldFontFamily

title-4()
  font-family $largeHeadingsFontFamily
  font-size 1.5rem
  line-height 1.33em
  letter-spacing 0.2px
  text-transform none
  -webkit-font-smoothing antialiased
  +below(mobile-cutoff)
    font-size 1.333rem
    line-height 1.125em

title-3()
  font-family $semiboldFontFamily
  font-size 1.5rem
  line-height 1.33em
  letter-spacing 0
  text-transform none
  -webkit-font-smoothing antialiased
  +below(mobile-cutoff)
    font-size 1.333rem
    line-height 1.125em

title-2()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 1.5rem
  line-height 1.33em
  letter-spacing 0
  text-transform none
  +below(mobile-cutoff)
    font-size 1.333rem
    line-height 1.125em

body-4()
  -webkit-font-smoothing antialiased
  font-family Georgia
  font-size 22px
  line-height 34px
  letter-spacing 0
  text-transform none

body-3()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 1.5rem
  line-height 1.33em
  letter-spacing 0
  text-transform none
  +below(mobile-cutoff)
    font-size 1.333rem
    line-height 1.125em

body-2()
  -webkit-font-smoothing antialiased
  font-family $semiboldFontFamily
  font-size 1.083rem
  line-height 1.24em
  letter-spacing 0.2px
  text-transform none

body-1()
  -webkit-font-smoothing antialiased
  font-family $bodyFontFamily
  font-size 1.083rem
  line-height 1.85em
  letter-spacing 0.2px
  text-transform none

body-1-paragraph()
  -webkit-font-smoothing antialiased
  line-height 1.45em

title-1()
  font-family $largeHeadingsFontFamily
  font-size 1.25rem
  line-height 1.85em
  letter-spacing 0.4px
  text-transform none
  -webkit-font-smoothing antialiased

label-1()
  font-family $semiboldFontFamily
  font-size 0.9167rem
  line-height 1.2em
  letter-spacing .5px
  text-transform uppercase
  -webkit-font-smoothing antialiased
  +below(mobile-cutoff)
    font-size 0.833rem
    line-height 1.333em

label-2()
  font-family $semiboldFontFamily
  font-size 1.083rem
  line-height 1.85em
  letter-spacing 0.5px
  text-transform uppercase
  -webkit-font-smoothing antialiased

/* Style guide beginning 4-16-2019 */
body-copy-small()
  -webkit-font-smoothing antialiased
  font-size 13px
  line-height 20px
  letter-spacing 0px
  font-family $bodyFontFamily

body-copy-small-semibold()
  -webkit-font-smoothing antialiased
  font-size 13px
  line-height 20px
  letter-spacing 0px
  font-family $semiboldFontFamily

body-recipe()
  body-3()
  font-family $recipeFontFamily
  font-size 1.333rem

display-recipe()
  display-1()
  font-family $recipeFontFamily
  font-weight 700

display-outfit()
  display-2()
  font-size 3.75rem
  font-family $outfitFontFamily
  font-weight 800
  color #000000

title-outfit()
  title-2()
  font-size 1.875rem
  font-family $outfitFontFamily
  font-weight 700
  color #000000
  +below(mobile-cutoff)
    font-size 1.55rem

body-outfit-3()
  font-family $outfitFontFamily
  font-weight 300
  color #000000
  font-size 1.563rem
  +below(mobile-cutoff)
    font-size 1.55rem

body-outfit-2()
  body-2()
  font-family $outfitFontFamily
  font-size 1.333rem
  color #000000
  font-weight 300

body-outfit-copy-small-semibold()
  font-family $outfitFontFamily
  font-weight 400
  font-size 12px
  line-height 14.4px
  letter-spacing 0
  color #000000

display-essonness()
  display-3()
  font-family $essonnessDisplayFontFamily
  font-size 7.5rem
  font-weight 700
  +below(mobile-cutoff)
    font-size 7rem

headline-essonness()
  display-2()
  font-size 6rem
  font-family $essonnessHeadlineFontFamily
  font-weight 700
  line-height 1
  +below(mobile-cutoff)
    font-size 5.7rem

title-essonness-1()
  title-2()
  font-size 2.5rem
  font-family $essonnessFontFamily
  font-weight normal
  color $black
  line-height 140%
  +below(mobile-cutoff)
    font-size 2.5rem

title-essonness-2()
  title-5()
  font-size 3.5rem
  font-family $essonnessFontFamily
  +below(mobile-cutoff)
    font-size 3.5rem

body-essonness()
  font-family $essonnessDisplayFontFamily
  font-weight 400
  color $black
  font-size 2.1rem
  +below(mobile-cutoff)
    font-size 2rem

default-title()
  font-size 1rem
  margin-bottom 16px
  font-weight 600

// ================================  IMPORTANT!!! ================================
// Please do not use these font family definitions directly anymore. Instead,
// please use the font mixins defined above. If you have a situation where one of
// the styles above do not make sense, please talk to design first about whether a
// new styleneeds to be defined or if we're making an unusual expection.
$largeHeadingsFontFamily = Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif // For headings 16px and up
$smallHeadingsFontFamily = Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif // For headings 15px and below
$semiboldFontFamily = Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif
$bodyFontFamily = Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif
$recipeFontFamily = "Futura", Helvetica, Arial, sans-serif
$outfitFontFamily = "Outfit", Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif
$essonnessFontFamily = "EssonnesText", Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif
$essonnessDisplayFontFamily = "EssonnesDisplay", "Outfit", Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif
$essonnessHeadlineFontFamily = "EssonnesHeadline", "Outfit", Graphik Semibold, "Helvetica Neue", Helvetica, Arial, sans-serif
$concretteFontFamily = "Concrette", Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif
$groteskFontFamily = "Grotesk", Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif
