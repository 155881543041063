$narrowPageWidth = 500px
$minNarrowPageWidth  = 320px
$narrowPageSidebarWidth = 320px
narrow-sidebar-one-column-cutoff = $minNarrowPageWidth + $narrowPageSidebarWidth + 60px - 1px
full-sized-narrow-page-cutoff = $narrowPageWidth + $narrowPageSidebarWidth + 60px

.narrow-page
  max-width $narrowPageWidth
  margin 0 auto
  margin-top $gutter

  +above(mobile-cutoff)
    margin-top $gutter*2

.narrow-sidebar-page
  max-width $narrowPageWidth + $narrowPageSidebarWidth + 20px
  margin 20px auto

  +between(narrow-sidebar-one-column-cutoff, full-sized-narrow-page-cutoff)
    width 100%
    padding-left 20px
    padding-right 20px
    box-sizing border-box

  +below(narrow-sidebar-one-column-cutoff)
    max-width $narrowPageWidth

  +below($narrowPageWidth + 30px)
    margin-left 15px
    margin-right 15px

.narrow-sidebar-page__body
  +above(narrow-sidebar-one-column-cutoff)
    display table-cell

.narrow-sidebar-page__sidebar
  +above(narrow-sidebar-one-column-cutoff)
    display table-cell
    vertical-align top
    width $narrowPageSidebarWidth
    padding-left 20px

  +below(narrow-sidebar-one-column-cutoff)
    margin-top 20px

.narrow-sidebar-page__body, .narrow-sidebar-page__sidebar
  +between($narrowPageWidth + 40px, narrow-sidebar-one-column-cutoff)
    width $narrowPageWidth
    margin 0 auto
