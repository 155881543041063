.single-order-page__add-to-order-modal
  padding 40px 100px 35px
  text-align center
  max-width 460px
  box-sizing border-box
  +below(500px)
    padding 40px 15px 35px

.single-order-page__add-to-order-button
  position absolute
  top 25%
  right 0
  margin 0
  +below(tablet-cutoff)
    display none

.single-order-page__add-to-order-button-mobile
  +above(tablet-cutoff)
    display none

.single-order-page__modal-shop-button
  button()
