link()
  color $dice
  cursor pointer

  &:link, &:visited
    color $laurel

  &:hover
    color $dice

dark-link()
  color $black
  cursor pointer
  font-weight bold

  &:link, &:visited
    color $black

  &:hover
    color $black
    text-decoration underline

form-link()
  dark-link()
  text-decoration underline
  font-weight bold
  font-size 16px
