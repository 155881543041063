.product-availability-statuses
  white-space nowrap
  padding 0
  margin-left 0
  line-height 0px

.product-availability__multiple-producer-note
  +above(tablet-cutoff)
    font-size 14px

.product-availability-status
  display inline-block
  font-weight normal
  line-height 1.3em
  margin-right 15px

  &:last-child
    margin-right 0

  .indicator
    display block
    margin 2px auto 4px auto

  .status, .day-of-week, .month-day
    display block
    text-align center
