+above(mobile-cutoff)
  .checkout-buttons
    clear right
    text-align right

    .button, .button:link, .button:visited
      margin-right -3px

  .checkout-buttons--can-add-to-order
    .basket-view__security-promise
      margin-top 0

  .checkout-buttons__continue-shopping
    display block

.checkout-buttons__continue-shopping
  height 16px   /* For some reason this container adds padding around the
                   text, this is required to get the bottom to line up
                   with the secure checkout link */

  &, &.error
    margin-top 16px

  a
    color $sage
    &:hover
      color $laurel

  &.error
    display inline-block

.checkout-buttons__order-minimum
  body-2()
  display inline-block
  margin-bottom -8px

.checkout-buttons__button-container
  float right
  +below(mobile-cutoff)
    float none
    text-align center

.checkout-buttons__button
  margin-bottom: 10px
  display inline-block
  min-width 204px
  text-align center
  white-space nowrap
  text-transform uppercase

  +below(500px)
    width 100%

  &.button
    margin 0

  &.checkout__link
    display inline-block

    padding 5px 0       /* So fat fingered folks have a slightly
                           bigger tap target */

    position relative
    top 11px           /* So the bottom of the text lines up with
                           the bottom of the container */
.checkout-buttons__to-place-subscription
  display block
  text-align right
  font-size 13px
  line-height 5px

.checkout-buttons__bold
  font-weight bold
  
.checkout-buttons__new-order
  border-radius 40px
  margin-top 10px
  &.button-is-disabled
    color $oyster
    border none
  &:not(.button-is-disabled)
    background transparent
    color $laurel
    border 1px solid $laurel
    &:hover
      background-color $laurel
      color white
      cursor pointer
  
  