.personalized-home-page
  position relative
  padding-top 36px

.personalized-home-page__loading
  min-height 600px

  .loading-overlay__background
    z-index $topNavZIndex - 2 // just below interests modal

  .loading-overlay__spinner
    top 180px

.personalized-home-page__modules
  margin-top 45px
  +below(mobile-cutoff)
    margin-top 25px

.personalized-home-page__module
  padding 0 20px

.personalized-home-page__module-container
  box-sizing border-box
  margin 0 auto
  max-width $personalizedHomePageModuleWidth

  h2
    title-2()
    margin-bottom 18px
    font-size 24px

    +below(mobile-cutoff)
      margin-left $homepageMobileMargin


.personalized-home-page__header
  margin-bottom -55px
  +below(mobile-cutoff)
    margin-left 8px
    padding-bottom 0

  h1
    display inline
    display-2()
    color $black
    margin-right 20px
    +below(mobile-cutoff)
      display block

.personalized-home-page__change-interests
  link()
  display inline
  font-size 1.08rem
  +below(mobile-cutoff)
    display block
    margin-top 8px

  .icon
    margin-right 4px
    margin-top -3px
