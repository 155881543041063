.single-order-page__delivery-time
  position relative
  background-color $eggshell


.single-order-page__delivery-time-select
  display inline-block
  // use negative margin to shrink effective line-height to
  // prevent reflows when we toggle the dropdown
  margin -10px 0 -10px 6px

.single-order-page__delivery-time-block
  display inline-block
  &:not(:first-child)
    margin-left 30px

.single-order-page__delivery-time-label
  label-1()
  color $oyster
  margin-bottom 10px

  &.select-offset
    margin-left 5px

.single-order-page__delivery-time-error
  margin 30px 0 20px

