
.basket__dropdown-selector
  position relative
  user-select none
  z-index 1

  +below(mobile-cutoff)
    position initial

  &:focus
    outline none

.basket__dropdown-selector__header
  background-color $ricotta
  color $black
  cursor pointer
  body-2()
  border 1px solid $silt
  border-radius 4px
  box-sizing border-box
  display inline-block
  margin 0
  padding 8px 12px 9px 12px

.basket__dropdown-selector__header-text
  +below(350px)
    max-width 108px
    display inline-block
    white-space nowrap
    overflow hidden
    text-overflow ellipsis

  &.is-empty
    color $tangerine

.basket__dropdown-selector__arrow
  float right
  font-size 19px
  margin-top -2px
  padding 0 0 0 15px

  +below(mobile-cutoff)
    padding-left 5px

.basket__dropdown-selector__list
  background-color $ricotta
  border-radius 0 4px 4px 4px
  box-shadow 0 3px 6px 0 rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.1)
  box-sizing border-box
  margin 0
  padding 0
  position absolute
  top 33px
  left 1px
  white-space nowrap

  +below(mobile-cutoff)
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    z-index 99

.basket__dropdown-selector__item
  position relative
  border-bottom 1px solid $silt
  cursor pointer
  display block
  list-style none
  padding 10px 45px 10px 12px
  +below(mobile-cutoff)
    padding-right 12px

  &.is-selectable:hover
    background $pearl

  &:not(.is-selectable)
    cursor default

  &.is-selected
    cursor default

.basket__dropdown-selector__item:nth-child(2)
  border-radius 0px 4px 0 0

.basket__dropdown-selector__item:last-child
  border-bottom 0px
  border-radius 0 0 4px 4px

.basket__dropdown-selector__item-icon
  position absolute
  right 10px
  top 0
  line-height 50px
  font-size 16px

  &.icon-circle-checkmark
    color $avocado

  &.icon-sidebar-blank-circle
    color $silt

.basket__dropdown-selector__mobile-title
  +above(mobile-cutoff)
    display none

.basket__dropdown-selector__mobile-title-text
  display inline-block

.basket__dropdown-selector__mobile-title-close
  float right
  line-height 34px
