.terms-page__content
  web-wide-content-column()
  padding-bottom 70px

.terms-page__column-content
  margin-top: 50px
  max-width: 600px
  body-1()
  display inline-block
  h3
    display-1()
    margin-top: 0
  h4
    body-2()
    margin: 20px 0 0
  p
    margin: 5px 0 10px
