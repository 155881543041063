button()
  label-2()
  display inline-block
  box-sizing border-box
  margin 7px 0 7px 0 /* 7px + 7px + 34px = 48px (multiple of 12px baseline) */
  padding 0 30px
  line-height 31px

  background $sage
  border 1px solid transparent
  border-radius(35px)

  color $ricotta
  cursor pointer
  text-decoration none
  -webkit-appearance none

  &:link, &:visited
    color $ricotta
    background $sage
    outline none
    cursor pointer
    text-decoration none

  &:focus, &:hover
    color $ricotta
    background lighten($sage, 40%)
    outline none
    text-decoration none

  &:active
    background-color $laurel
    text-decoration none

  &:disabled
    background $silt
    box-shadow none
    cursor default
    text-decoration none

flat-button()
  button()
  border 0
  border-radius 0
  text-align center

secondary-button()
  button()
  color $sage
  border-radius 40px
  background transparent
  box-shadow none
  border 1px solid lighten($sage, 20%)

  &:not(.disabled):hover
    background lighten($sage, 20%)
    color $ricotta

  &:not(.disabled):active
    background $laurel
    border-color $laurel

  &[disabled], &.disabled
    color lighten($oyster, 20%)
    border-color lighten($oyster, 20%)

.button
  button()

@supports (-webkit-appearance:none) // hack to target weird vertical alignment on webkit browsers
  .button
    padding-bottom 2px

// STATES

.button-is-disabled, .button.button-is-disabled
  background $silt
  box-shadow none
  cursor default
  text-decoration none

.button-is-toggled-on
  color $eggshell

.button.button-is-secondary
  secondary-button()

.button
  animated-saving-button()

// SUBCLASSES
.button--full-width
  display block
  width 100% /* Not happy with this, but not sure how else to get it to act like block-level element */
  margin-left 0
  margin-right 0
  padding 0

.link-button
  label-2()
  padding 0 30px

.button--banner-embedded
  height 22px
  line-height 20px
  background-color rgba(255, 255, 255, 0.1)
  border 1px solid $eggshell
  label-1()
  padding 0 27px 2px 27px
  +below(mobile-cutoff)
    padding 0 17px 2px 27px

  .icon
    vertical-align 0

text-button()
  label-1()
  color $laurel
  text-decoration none
  text-transform uppercase
  font-weight bold
  padding $gutter 0
  margin-top -15px

a.text-button
  text-button()

skinny-button()
  label-1()
  display inline-block
  box-sizing border-box
  height 26px
  padding 0 30px
  line-height 24px

  background $sage
  border 1px solid transparent
  border-radius(35px)

  color $ricotta
  cursor pointer
  text-decoration none
  -webkit-appearance none

  +below(mobile-cutoff)
    line-height 24px

  outline none
  .no-touch
    &:focus:not(.saving), &:hover:not(.saving)
      background $sage
      text-decoration none
      color $ricotta

  &:hover:not(.saving)
    background-color $sage
    text-decoration none
    color $ricotta

  &:focus:not(.saving)
    background-color $laurel
    border-color $laurel
    text-decoration none
    color $ricotta

  &.disabled, &:disabled
    pointer-events: none
    background $silt
    box-shadow none
    cursor default
    text-decoration none
    border none
    color $ricotta

skinny-secondary-button()
  skinny-button()
  color $sage
  border-color $sage
  background transparent

  &:link, &:visited
    color $sage

  &.is-warning
    color $tangerine
    border-color $tangerine

    &:hover:not(.saving), &:focus:not(.saving)
      background-color $tangerine
      border-color $tangerine

rigid-button()
  background $sage
  display inline-block
  border-radius 3px
  font-size 1.5rem
  color $eggshell
  border 0
  padding 16px 20px
  cursor pointer
  &:hover
    color $ricotta
    background lighten($sage, 40%)
    outline none
    text-decoration none
  +below(mobile-cutoff)
    display block

refresh-button()
  rigid-button()
  background-color $brownsoil
  padding 0px 4rem
  margin 0 auto
  body-1()
  border-radius 26px
  height 46px
  font-size 16px
  font-weight 300
  line-height 2.5
  letter-spacing 0.05px
  &:hover, &:active
    background-color lighten($brownsoil, 20%)

refresh-button-outline()
  refresh-button()
  background-color white
  border 1px $brownsoil solid
  color $black
  line-height 3
  &:hover, &:active
    border-color #9C9795
    color #9C9795
    background-color white
  &:disabled
    border-color #c4c4c4
    color #c4c4c4
  &.selected
    background-color $brownsoil
    color $eggshell

button-outline-redesign()
  body-1()
  border-radius 50px
  height 40px
  font-size 16px
  font-weight 400
  line-height 16px
  letter-spacing 0.05px
  padding: 0 16px
  background-color white
  border 1px $brownsoil solid
  color $black
  display block
  cursor pointer
  &:hover
    color $ricotta
    background lighten($sage, 40%)
    outline none
    text-decoration none
  +below(mobile-cutoff)
    display block
  &:hover, &:active
    border-color #9C9795
    color #9C9795
    background-color white
  &:disabled
    border-color #c4c4c4
    color #c4c4c4
  &.selected
    background-color $brownsoil
    color $eggshell

rigid-secondary-button()
  background transparent
  display inline-block
  border-radius 3px
  border 1px solid $sage
  font-size 1.5rem
  color $laurel
  padding 16px 20px
  cursor pointer
  &:link, &:visited
    color $laurel
  &:hover
    color $sage
    outline none
    text-decoration none

// STATES
.button--banner-embedded:focus, .button--banner-embedded:hover, .button--banner-embedded-is-hovered
  background-color rgba(255, 255, 255, 0.2)
  text-decoration none
  color $ricotta
